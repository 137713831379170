import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import dayjs from 'dayjs';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  Date: dayjs.Dayjs;
  Map: any;
  Time: dayjs.Dayjs;
  Upload: any;
};

/** 住所 */
export type Address = {
  __typename?: 'Address';
  /** 住所ID */
  addressId: Scalars['ID'];
  /** 住所ラベル */
  addressLabel: Scalars['String'];
  /** 宛名 */
  addressee: Scalars['String'];
  /** 建物 */
  building: Scalars['String'];
  /** 国コード */
  countryCode: Scalars['String'];
  /** 作成日時 */
  createdAt: Scalars['Time'];
  /** 姓 */
  familyName: Scalars['String'];
  /** 姓(ふりがな) */
  familyNameRuby: Scalars['String'];
  /** 郵送先住所 */
  formatted: Scalars['String'];
  /** 名 */
  givenName: Scalars['String'];
  /** 名(ふりがな) */
  givenNameRuby: Scalars['String'];
  /** 削除ステータス */
  isDeleted: Scalars['Boolean'];
  /** 市区町村 */
  locality: Scalars['String'];
  /** 郵送先メールアドレス */
  mailingEmail: Scalars['String'];
  /** 郵送先電話番号 */
  mailingPhoneNumber: Scalars['String'];
  /** 会員ID */
  memberId?: Maybe<Scalars['String']>;
  /** ミドルネーム */
  middleName: Scalars['String'];
  /** 郵便番号 */
  postalCode: Scalars['String'];
  /** 県コード */
  prefectureCode: Scalars['String'];
  /** 地域(ex: 東京都) */
  region: Scalars['String'];
  /** 住所詳細 */
  streetAddress: Scalars['String'];
};

/** 告知 */
export type Announcement = {
  __typename?: 'Announcement';
  /** 内容 */
  body: Scalars['String'];
  /** 件名 */
  subject: Scalars['String'];
};

export type ApplealCampaignInfo = {
  __typename?: 'ApplealCampaignInfo';
  /** キャンペーンID */
  campaignID: Scalars['String'];
  /** 終了日時 */
  endTime: Scalars['Time'];
  /** エントリー済み */
  isEntried: Scalars['Boolean'];
};

/** 銀行 */
export type Bank = {
  __typename?: 'Bank';
  /** 支店 */
  branches?: Maybe<Array<Maybe<BankBranch>>>;
  /** 銀行番号（統一金融機関コード 4桁） */
  id: Scalars['ID'];
  /** 銀行名 */
  name: Scalars['String'];
  /** 銀行名 フリガナ */
  nameRuby: Scalars['String'];
};

/** 銀行口座アカウント */
export type BankAccount = {
  __typename?: 'BankAccount';
  /** 名義 */
  accountHolder?: Maybe<Scalars['String']>;
  /** 名義 フリガナ */
  accountHolderRuby?: Maybe<Scalars['String']>;
  /** 口座番号 */
  accountNumber?: Maybe<Scalars['String']>;
  /** 口座種別（普通、当座、貯蓄） */
  accountType?: Maybe<BankAccountType>;
  /** 銀行支店番号 */
  bankBranchId: Scalars['ID'];
  /** 銀行支店名 */
  bankBranchName: Scalars['String'];
  /** 銀行番号（統一金融機関コード 4桁） */
  bankId?: Maybe<Scalars['String']>;
  /** 銀行名 */
  bankName?: Maybe<Scalars['String']>;
  /** 銀行口座アカウントID */
  id: Scalars['ID'];
  /** D100 ID */
  memberId?: Maybe<Scalars['String']>;
};

/** 口座種別 */
export enum BankAccountType {
  /** 当座預金 */
  Current = 'current',
  /** 普通預金 */
  Ordinary = 'ordinary',
  /** 貯蓄預金 */
  Savings = 'savings',
  /** 不明 */
  Unknown = 'unknown',
}

/** 銀行支店 */
export type BankBranch = {
  __typename?: 'BankBranch';
  /** 銀行番号（統一金融機関コード 4桁） */
  bankId?: Maybe<Scalars['String']>;
  /** 銀行名 */
  bankName: Scalars['String'];
  /** 銀行名 フリガナ */
  bankNameRuby: Scalars['String'];
  /** 支店番号 */
  id: Scalars['ID'];
  /** 支店名 */
  name: Scalars['String'];
  /** 銀行支店名 フリガナ */
  nameRuby: Scalars['String'];
};

/** 銀行振込精算 */
export type BankPayout = {
  __typename?: 'BankPayout';
  /** 完了日時 */
  completed?: Maybe<Scalars['Time']>;
  /** 申込日時 */
  created: Scalars['Time'];
  /** 銀行振込精算ID */
  id: Scalars['ID'];
  /** 精算リクエスト */
  requests?: Maybe<Array<Maybe<BankPayoutRequest>>>;
  /** 銀行振込精算 経理処理ステータス */
  status: BankPayoutStatus;
  /** 更新日時 */
  updated: Scalars['Time'];
  /** バージョン */
  version: Scalars['Int'];
};

/** 精算リクエスト */
export type BankPayoutRequest = {
  __typename?: 'BankPayoutRequest';
  /** 振り込み金額 */
  amount: Scalars['Int'];
  /** PFの銀行口座情報 */
  bankAccount?: Maybe<BankAccount>;
  /**
   * PFの銀行口座ID(deprecated)
   * @deprecated fix id spelling
   */
  bankAccountID: Scalars['String'];
  /** PFの銀行口座ID */
  bankAccountId: Scalars['String'];
  /** 銀行振込精算ID */
  bankPayoutId: Scalars['String'];
  /** 申込日時 */
  created: Scalars['Time'];
  /** 手数料 */
  fee: Scalars['Int'];
  /** 銀行支払いリクエストID */
  id: Scalars['ID'];
  /** 振込ステータス */
  status: BankPayoutRequestStatus;
  /** 更新日時 */
  updated: Scalars['Time'];
  /** ユーザーID */
  userId: Scalars['String'];
  /** ステートメントID */
  walletStatementId: Scalars['String'];
};

/** 銀行振込精算手続きステータス */
export enum BankPayoutRequestStatus {
  /** 振込完了 */
  Completed = 'completed',
  /** 振込失敗 */
  Failed = 'failed',
  /** 振込拒否(不正チェックでNG判定) */
  Rejected = 'rejected',
  /** 振込処理待ち */
  Waiting = 'waiting',
}

export type BankPayoutRequestStatusInput = {
  bankPayoutRequestId: Scalars['ID'];
  status: BankPayoutRequestStatus;
};

/** 銀行振込精算 経理処理ステータス */
export enum BankPayoutStatus {
  /** 完了 */
  Completed = 'completed',
  /** 処理中 */
  InProgress = 'inProgress',
}

export enum BetResultRangeType {
  /** 年間 */
  Annually = 'annually',
  /** 月間 */
  Monthly = 'monthly',
  /** 通算 */
  Total = 'total',
}

export enum BetStatus {
  /** 全返還 */
  AllReturn = 'allReturn',
  /** 同着オーバー */
  DeadHeatOver = 'deadHeatOver',
  /** 同着レース数オーバー */
  DeadHeatRaceCountOver = 'deadHeatRaceCountOver',
  /** 確定 */
  Decision = 'decision',
  /** 予告 */
  Notice = 'notice',
  /** 特払い */
  SpecialPayment = 'specialPayment',
  /** 未登録 */
  Unregistered = 'unregistered',
}

export enum BetType {
  /** 2枠単 */
  BracketPerfecta = 'bracketPerfecta',
  /** 2枠複 */
  BracketQuinella = 'bracketQuinella',
  /** 2車単 */
  Perfecta = 'perfecta',
  /** 2車複 */
  Quinella = 'quinella',
  /** ワイド */
  QuinellaPlace = 'quinellaPlace',
  /** 3連単 */
  Trifecta = 'trifecta',
  /** 3連複 */
  Trio = 'trio',
}

export type Cc3dAuth = {
  __typename?: 'Cc3dAuth';
  Updated: Scalars['Time'];
  /** 決済額 */
  amount: Scalars['Int'];
  created: Scalars['Time'];
  /** 認証結果ステータス */
  eci: Scalars['String'];
  /** マーチャント切り替えフラグ */
  j: Scalars['String'];
  /** 3DセキュアログID */
  logId: Scalars['String'];
  /** トランザクション識別キー */
  md: Scalars['String'];
  /** 認証結果 */
  paRes?: Maybe<Scalars['String']>;
  /** ポイントチャージ時のPF決済ID */
  pfChargeId: Scalars['Int'];
  /** 認証結果検証ステータス */
  result: CreditCard3dVerifyStatus;
  /** ユーザID */
  userId: Scalars['String'];
};

export type ChargeToretaFromCredit = {
  __typename?: 'ChargeToretaFromCredit';
  amount: Scalars['Int'];
  completed?: Maybe<Scalars['Time']>;
  result: ChargeToretaFromCreditResult;
  userId: Scalars['String'];
};

export enum ChargeToretaFromCreditResult {
  /** 失敗 */
  Failure = 'failure',
  /** 成功 */
  Success = 'success',
}

/** Walletからtoreta+チャージ */
export type ChargeToretaFromWallet = {
  __typename?: 'ChargeToretaFromWallet';
  /** 金額 */
  amount: Scalars['Int'];
  /** 受付時刻 */
  requested?: Maybe<Scalars['Time']>;
  /** ステータス */
  status: ChargeToretaFromWalletStatus;
  /** ユーザーID（OpenID） */
  userId: Scalars['String'];
  /** ステートメントID */
  walletStatementID: Scalars['ID'];
};

export enum ChargeToretaFromWalletStatus {
  /** confirmed リクエスト受付済み */
  Confirmed = 'confirmed',
  /** failure リクエスト失敗 */
  Failure = 'failure',
  /** processing 処理中 */
  Processing = 'processing',
  /** waiting 未処理（登録直後の一時的な状態） */
  Waiting = 'waiting',
}

/** チャージバック, ミッドナイトキャンペーン進捗 */
export type ChargebackCampaignProgress = {
  __typename?: 'ChargebackCampaignProgress';
  /** 付与詳細 */
  details: Array<ChargebackCampaignProgressDetail>;
  /** ユーザーID */
  userId: Scalars['String'];
  /** 投票額 */
  votedAmount: Scalars['Int'];
};

/** チャージバック, ミッドナイトキャンペーン進捗付与日ごと詳細 */
export type ChargebackCampaignProgressDetail = {
  __typename?: 'ChargebackCampaignProgressDetail';
  /** 付与金額 */
  amount: Scalars['Int'];
  /** 付与日(1日 or 11日 or 21日) */
  grantDate: Scalars['Date'];
  /** 確定済みか否か */
  isFixed: Scalars['Boolean'];
};

/** 国コード判定 */
export type CheckCountry = {
  __typename?: 'CheckCountry';
  /** 国コード */
  code: Scalars['String'];
  /** OGPクローラ */
  isOgp: Scalars['Boolean'];
  /** 検索エンジンクローラ */
  isSearchEngine: Scalars['Boolean'];
  /** SNSクローラ */
  isSns: Scalars['Boolean'];
};

export type CreateUserInput = {
  inviter?: InputMaybe<Scalars['String']>;
  isMailSubscribe: Scalars['Boolean'];
  phoneNo: Scalars['String'];
};

/** クレジットカード情報 */
export type CreditCard = {
  __typename?: 'CreditCard';
  brandId: Scalars['Int'];
  brandName: Scalars['String'];
  denominationCode: Scalars['String'];
};

export type CreditCard3d2AuthResponse = {
  __typename?: 'CreditCard3d2AuthResponse';
  /** 認証ページURL */
  iframeUrl: Scalars['String'];
  /** マーチャントデータ */
  md: Scalars['String'];
  /** マーチャント */
  merchant: Scalars['String'];
  /** PaReqメッセージ */
  paReq?: Maybe<Scalars['String']>;
  /** 本人認証要求結果 */
  result: CreditCard3dAuthStatus;
  /** 3Dセキュア2.0利用フラグ */
  threeDSMethod: Scalars['Int'];
  /** 3Dセキュア取引ID */
  threeDSOrderID: Scalars['String'];
};

export type CreditCard3dAuthResponse = {
  __typename?: 'CreditCard3dAuthResponse';
  /** 認証サーバ(ACS: Access Control Server) URL */
  acsURL: Scalars['String'];
  /** 3DセキュアログID */
  cc3dLogId: Scalars['String'];
  /** トランザクション識別キー */
  md: Scalars['String'];
  /** 認証結果 */
  paReq: Scalars['String'];
  /** 本人認証要求結果 */
  result: CreditCard3dAuthStatus;
  /** 競輪側エントリポイント */
  termUrl: Scalars['String'];
};

export enum CreditCard3dAuthStatus {
  /** 許可 */
  Approve = 'approve',
  /** 拒否 */
  Reject = 'reject',
  /** 不明 */
  Unknown = 'unknown',
}

export enum CreditCard3dVerifyStatus {
  /** 異常終了 */
  Failure = 'failure',
  /** 正常終了 */
  Success = 'success',
  /** 不明 */
  Unknown = 'unknown',
}

export enum CreditCardMemberStatus {
  /** 有効 */
  Active = 'active',
  /** 休眠 */
  Dormant = 'dormant',
  /** 無効 */
  Inactive = 'inactive',
  /** 一部決済停止 */
  PartSuspend = 'partSuspend',
  /** 決済停止 */
  Suspend = 'suspend',
  /** 不明 */
  Unknown = 'unknown',
}

/** クレジットカードステータス */
export type CreditCardStatus = {
  __typename?: 'CreditCardStatus';
  memberStatus: CreditCardMemberStatus;
};

export type DailyOrderLimit = {
  __typename?: 'DailyOrderLimit';
  /** 投票限度額 */
  amount: Scalars['Int'];
  /** 更新日 */
  updated: Scalars['Time'];
  /** ユーザーID */
  userId: Scalars['String'];
};

export type DeleteEntryRacerNoteInput = {
  raceID: Scalars['String'];
  registrationNum: Scalars['Int'];
};

export type DeleteTransferResponse = {
  __typename?: 'DeleteTransferResponse';
  result: DeleteTransferStatus;
};

export enum DeleteTransferStatus {
  /** 異常終了 */
  Failure = 'failure',
  /** 処理中持ち */
  HasProcessing = 'hasProcessing',
  /** 正常終了 */
  Success = 'success',
  /** 不明 */
  Unknown = 'unknown',
}

export type EntryCampaignUser = {
  __typename?: 'EntryCampaignUser';
  /** 作成日時 */
  created: Scalars['Time'];
  /** エントリーキャンペーンID */
  entryCampaignId: Scalars['String'];
  /** 更新日時 */
  updated: Scalars['Time'];
  /** ユーザーID */
  userId: Scalars['String'];
};

export type EntryRacer = {
  __typename?: 'EntryRacer';
  /** 年齢 */
  age: Scalars['Int'];
  /** 着順 */
  arrivalOrder: Scalars['Int'];
  /** 車数 */
  bicycleNum: Scalars['Int'];
  /** 枠番 */
  bracketNum: Scalars['Int'];
  created: Scalars['Time'];
  /** 決まり手名称 */
  decidingFactor?: Maybe<Scalars['String']>;
  /** 過去4ヶ月成績情報 */
  entryRacerScore: EntryRacerScore;
  /** 個人上がりタイム */
  finalStagePlayerTime?: Maybe<Scalars['Float']>;
  /** 予想印 */
  forecastMark?: Maybe<ForecastMark>;
  /** ギア比 */
  gearRatio: Scalars['String'];
  /** 前ギア比 */
  gearRatioBefore?: Maybe<Scalars['String']>;
  /** 級名称 */
  gradeName: Scalars['String'];
  /** 卒業期 */
  graduated: Scalars['String'];
  /** 旧athlete_id */
  id: Scalars['String'];
  /** バック取り */
  isBackGet?: Maybe<Scalars['Boolean']>;
  /** ホーム取り(未使用) */
  isHomeGet?: Maybe<Scalars['Boolean']>;
  /** ジャン取り(未使用) */
  isJanGet?: Maybe<Scalars['Boolean']>;
  /** スタンディング取り */
  isStandingGet?: Maybe<Scalars['Boolean']>;
  /** 着差情報 */
  marginInfo?: Maybe<Scalars['String']>;
  /** 状況: 重勝投票で当該車番に投票された票数の割合 */
  multiracePercentageOfBets: Array<MultiracePercentageOfBets>;
  /** 選手名 */
  name: Scalars['String'];
  /** 個人状況名称 */
  playerStatus?: Maybe<Scalars['String']>;
  /** 都道府県 */
  prefecture: Scalars['String'];
  /** 都道府県(短縮) */
  prefectureShort: Scalars['String'];
  raceId: Scalars['String'];
  /** 脚質(旧leg_quality) */
  racingStyle: Scalars['String'];
  /** 選手登録番号 */
  registrationNum: Scalars['Int'];
  /** 発売状況 */
  releaseStatus: ReleaseStatus;
  /** 選手名(短縮) */
  shortName: Scalars['String'];
  /** 支持率: 7賭式で当該車番に投票された票数の割合 */
  singleRacePercentageOfBets: Scalars['Int'];
  /** 班名称 */
  unitName: Scalars['String'];
  /** 更新通番 */
  updateNotificationNum: Scalars['Int'];
  updated: Scalars['Time'];
};

/** 選手メモ */
export type EntryRacerNote = {
  __typename?: 'EntryRacerNote';
  /** 選手メモ内容 */
  body: Scalars['String'];
  created: Scalars['Time'];
  /** 開催日 */
  heldDate: Scalars['Date'];
  race: Race;
  /** レースID */
  raceId: Scalars['String'];
  /** レース番号 */
  raceNum: Scalars['Int'];
  /** 選手登録番号 */
  registrationNum: Scalars['Int'];
  updated: Scalars['Time'];
  /** ユーザID */
  userId: Scalars['String'];
  /** 場コード */
  velodromeCode: Scalars['Int'];
};

export type EntryRacerNoteCount = {
  __typename?: 'EntryRacerNoteCount';
  count: Scalars['Int'];
  raceID: Scalars['String'];
};

export type EntryRacerScore = {
  __typename?: 'EntryRacerScore';
  /** 平均競走得点 */
  averageRaceScore: Scalars['Float'];
  /** バック回数 */
  backCount: Scalars['Int'];
  /** 捲くり回数 */
  comeFromBehindCount: Scalars['Int'];
  created: Scalars['Time'];
  entryRacerId: Scalars['String'];
  entryRacerScoreDetails: Array<EntryRacerScoreDetail>;
  /** 過去3場所成績数 */
  last_3raceScoreInfoCount: Scalars['Int'];
  /** 差し回数 */
  lookerCount: Scalars['Int'];
  /** マーク回数 */
  markCount: Scalars['Int'];
  /** 逃げ回数 */
  pacesetterCount: Scalars['Int'];
  /** 賞金 */
  prize: Scalars['Int'];
  /** 総合順位 */
  rank: Scalars['Int'];
  /** 2位回数 */
  rank2Count: Scalars['Int'];
  /** 3位回数 */
  rank3Count: Scalars['Int'];
  /** スタンディング回数 */
  standingCount: Scalars['Int'];
  /** 連対率 */
  top2Ratio: Scalars['Float'];
  /** 3連対率 */
  top3Ratio: Scalars['Float'];
  /** 1位回数 */
  topCount: Scalars['Int'];
  /** 総勝利回数 */
  totalWinningCount: Scalars['Int'];
  /** 着外(1~3位以外)回数 */
  unplacedCount: Scalars['Int'];
  updated: Scalars['Time'];
  /** 勝率 */
  winningAverage: Scalars['Float'];
  /** 連勝回数 */
  winningStreakCount: Scalars['Int'];
};

/** 前3場所成績情報 */
export type EntryRacerScoreDetail = {
  __typename?: 'EntryRacerScoreDetail';
  created: Scalars['Time'];
  entryRacerId: Scalars['String'];
  /** レースグレード */
  grade: Grade;
  /** 開催初日 */
  heldFirstDate: Scalars['Date'];
  /** 成績 ([{"rank": 3, "raceNum": 1, "raceEventName": "予ガ", "personalStatus": "欠"}1...6]JSON形式) */
  scoreList: Scalars['String'];
  updated: Scalars['Time'];
  /** 競輪場コード */
  velodromeCode: Scalars['Int'];
};

export type EntryRacerScoreDetailOwn = {
  __typename?: 'EntryRacerScoreDetailOwn';
  created: Scalars['Time'];
  /** 級名称 */
  gradeName: Scalars['String'];
  /** 開催初日 */
  heldFirstDate: Scalars['Time'];
  /** 選手登録番号 */
  registrationNum: Scalars['Int'];
  /** スコアCSV1 */
  scoreCsv1: Scalars['String'];
  /** スコアCSV2 */
  scoreCsv2: Scalars['String'];
  /** スコアCSV3 */
  scoreCsv3: Scalars['String'];
  /** スコアCSV4 */
  scoreCsv4: Scalars['String'];
  /** スコアCSV5 */
  scoreCsv5: Scalars['String'];
  /** スコアCSV6 */
  scoreCsv6: Scalars['String'];
  /** スコアCSV7 */
  scoreCsv7: Scalars['String'];
  updated: Scalars['Time'];
  /** 競輪場コード */
  velodromeCode: Scalars['Int'];
};

export enum ErrorCode {
  InternalError = 'InternalError',
  NotFound = 'NotFound',
  NotLoggedIn = 'NotLoggedIn',
  Unauthorized = 'Unauthorized',
}

export type ExecuteCampaignDetail = {
  /** 投票条件リスト */
  buyConditions: Array<GrantInfo>;
  /** キャンペーンID */
  campaignID: Scalars['String'];
  /** キャンペーン名 */
  campaignName: Scalars['String'];
  /** 実行モード */
  executeMode: SfnExecuteMode;
  /** 付与ユーザー抽出予約実行日時 */
  executionDatetime: Scalars['Time'];
  /** 付与金額 */
  grantAmount: Scalars['Int'];
  /** メール送信実行予約日時 */
  grantToretaDatetime: Scalars['Time'];
  /** 的中条件リスト */
  hitConditions: Array<GrantInfo>;
  /** 集計方法 */
  tallyBetTypes: Array<TallyBetType>;
  /** 対象賭式 */
  targetBetTypes: Array<Scalars['String']>;
  /** 集計対象下限値 */
  targetLowerLimit: Scalars['Int'];
  /** 集計開始日時 */
  targetSinceDatetime: Scalars['Time'];
  /** 集計終了日時 */
  targetUntilDatetime: Scalars['Time'];
  /** 対象競輪場 */
  targetVelodromes: Array<Scalars['Int']>;
};

export type ExecuteCampaignResult = {
  __typename?: 'ExecuteCampaignResult';
  /** 実行結果 */
  result: Scalars['String'];
};

/** 予想印 */
export enum ForecastMark {
  /** 本命 */
  Excellent = 'excellent',
  /** 連下 */
  Fair = 'fair',
  /** 単穴 */
  Good = 'good',
  /** 無印 */
  None = 'none',
  /** 穴 */
  Poor = 'poor',
  /** 対抗 */
  VeryGood = 'veryGood',
}

/** 性別 */
export enum Gender {
  /** 女性 */
  Female = 'female',
  /** 男性 */
  Male = 'male',
  /** その他 */
  Other = 'other',
  /** 不明 */
  Unknown = 'unknown',
}

export type GetScoreDetailOwnsInputParam = {
  detailCnt: Scalars['Int'];
  heldDate: Scalars['String'];
  limitHeldDate: Scalars['String'];
  raceId: Scalars['String'];
  velodromeCode: Scalars['Int'];
};

/** GMOアカウント情報 */
export type GmoAccountInfo = {
  __typename?: 'GmoAccountInfo';
  /** 金融機関コード */
  bankCode: Scalars['String'];
  /** 金融機関名称 */
  bankName: Scalars['String'];
  /** GMOアカウントID */
  gmoAccountID: Scalars['ID'];
  /** ステータス */
  status: GmoAccountStatus;
  /** 更新日時 */
  updated: Scalars['Time'];
};

/** GMOアカウントステータス */
export enum GmoAccountStatus {
  /** 登録解除 */
  End = 'END',
  /** 登録失敗 */
  Fail = 'FAIL',
  /** 登録中 */
  InProgress = 'IN_PROGRESS',
  /** 登録完了 */
  Register = 'REGISTER',
}

/** GMO用連携データ */
export type GmoTransaction = {
  __typename?: 'GmoTransaction';
  /** 銀行名 */
  bankName: Scalars['String'];
  /** 開始URL */
  startUrl: Scalars['String'];
  /** トークン */
  token: Scalars['String'];
  /** トランザクションID */
  tranID: Scalars['String'];
};

export enum Grade {
  F1 = 'F1',
  F2 = 'F2',
  G1 = 'G1',
  G2 = 'G2',
  G3 = 'G3',
  /** グランプリ */
  Gp = 'GP',
  /** SSカップみのり */
  Ss = 'SS',
  /** ヤンググランプリ */
  Ygp = 'YGP',
  Unknown = 'unknown',
}

export type GrantInfo = {
  /** 的中レース数 */
  count: Scalars['Int'];
  /** 付与金額 */
  grantAmount: Scalars['Int'];
};

export type IssueMultiraceRandomTicketsInput = {
  betCount: Scalars['Int'];
  multiraceProgramId: Scalars['Int'];
};

export type ItemInput = {
  betType: BetType;
  count: Scalars['Int'];
  numbers: Array<Scalars['Int']>;
};

/** メール受け取り設定 */
export type MailReceiveOption = {
  __typename?: 'MailReceiveOption';
  /** 投票完了メール受取 */
  atVoteCompleted: Scalars['Boolean'];
  /** 投票レースの結果確定メール受取 */
  atVotedRaceResultConfirmed: Scalars['Boolean'];
  /** 投票レース発走お知らせメール受取 */
  atVotedRaceStarted: Scalars['Boolean'];
  /** ユーザID */
  userId: Scalars['String'];
};

/** 手動ミッション */
export enum ManualMissionId {
  /** 「条件クリア」にて獲得条件を達成する */
  AchieveConditionClear = 'achieve_condition_clear',
  /** 競輪に関するアンケートに答える */
  AnswerQuestionnaire = 'answer_questionnaire',
  /** DMM競輪のメールマガジンを登録する */
  RegisterMailMagazine = 'register_mail_magazine',
}

export type ModifyWalletBalanceInput = {
  amount: Scalars['Int'];
  userId: Scalars['String'];
};

export type ModifyWalletBalanceResult = {
  __typename?: 'ModifyWalletBalanceResult';
  /** Wallet残高調整失敗数 */
  failCount: Scalars['Int'];
  /** Wallet残高調整失敗ユーザID一覧 */
  failedUserIds: Array<Scalars['String']>;
  /** 総リクエストユーザ数 */
  requestCount: Scalars['Int'];
  /** Wallet残高調整成功数 */
  successCount: Scalars['Int'];
};

/** 状況・支持率 */
export type MultiracePercentageOfBets = {
  __typename?: 'MultiracePercentageOfBets';
  entryRacerId: Scalars['String'];
  /** 重勝種別 */
  multiraceType: MultiraceType;
  /** 状況：重勝投票で当該車番に投票された票数の割合(%) */
  percentageOfBets: Scalars['Int'];
};

export type MultiraceProgram = {
  __typename?: 'MultiraceProgram';
  /** 今回繰越金額 */
  carryoverPrice: Scalars['Int'];
  created: Scalars['Time'];
  /** ドカント開催回 */
  dokantoHeldNum: Scalars['Int'];
  /** ドカント場主催コード */
  dokantoPromoterCode: Scalars['Int'];
  /** 施行者コード */
  enforcerCode: Scalars['Int'];
  /** "グループコード */
  groupCode: Scalars['Int'];
  /** 開催日 */
  heldDate: Scalars['Time'];
  /** 開催初日 */
  heldFirstDate: Scalars['Time'];
  /** 的中票数 */
  hitBetCount: Scalars['Int'];
  /** multirace_programのid */
  id: Scalars['Int'];
  /** 中止フラグ */
  isCanceled: Scalars['Boolean'];
  /** 特払いするか否か(falseはキャリーオーバー) */
  isRefund: Scalars['Boolean'];
  /** 重勝払戻情報 */
  multiraceRefunds: Array<MultiraceRefund>;
  /** 重勝種別 */
  multiraceType: MultiraceType;
  /** 前回繰越金額 */
  previousCarryoverPrice: Scalars['Int'];
  /** 重勝名称 */
  programName: Scalars['String'];
  /** レース数 */
  raceCount: Scalars['Int'];
  /** race_program */
  raceProgram: RaceProgram;
  /** race_program_id */
  raceProgramId: Scalars['String'];
  /** 払戻総額 */
  refundAmount: Scalars['Int'];
  /** 発売票数 */
  releaseBetCount: Scalars['Int'];
  /** 発売予定時刻 */
  releaseDate: Scalars['Time'];
  /** 発売ステータス */
  releaseStatus: MultiraceReleaseStatus;
  /** 返還票数 */
  returnBetCount: Scalars['Int'];
  /** 発売締切時刻 */
  salesDeadline: Scalars['Time'];
  /** 山分けキャンペーン金額 */
  shareCampaignAmount: Scalars['Int'];
  /** 対象レース払戻情報 */
  singleRaceRefundsByRaceId: Array<SingleRaceRefundsByRaceId>;
  /** 対象レース的中票数 */
  targetRaceHitBetCount: Array<Scalars['Int']>;
  /** 対象レースナンバー */
  targetRaceNumbers: Array<Scalars['Int']>;
  /** 発売票数単位 */
  unitOfRelease: Scalars['Int'];
  updated: Scalars['Time'];
  /** 競輪場コード */
  velodromeCode: Scalars['Int'];
  /** VICサーバナンバー */
  vicServerNum: Scalars['Int'];
};

/** 重勝ランダム式 */
export type MultiraceRandomItemInput = {
  /** 口数 */
  betCount: Scalars['Int'];
  /** 残りのプレビュー可能回数 */
  remainingPreviewsCount: Scalars['Int'];
};

/** ランダム式の車券発行情報 */
export type MultiraceRandomTickets = {
  __typename?: 'MultiraceRandomTickets';
  /** 口数 */
  betCount: Scalars['Int'];
  /** 発行された車券の組み合わせ */
  items: Array<Array<Scalars['Int']>>;
  /** 残りのプレビュー可能回数 */
  remainingPreviewsCount: Scalars['Int'];
};

/** 重勝払戻 */
export type MultiraceRefund = {
  __typename?: 'MultiraceRefund';
  /** 金額 */
  amount: Scalars['Int'];
  /** 登録状況 */
  betStatus: BetStatus;
  created: Scalars['Time'];
  /** 的中数 */
  hitCount: Scalars['Int'];
  /** 的中番号 */
  hitNumbers: Array<Scalars['Int']>;
  /** 重勝開催ID */
  multiraceProgramId: Scalars['Int'];
  updated: Scalars['Time'];
};

/** 発売状況 */
export enum MultiraceReleaseStatus {
  /** 発売前 */
  BeforeRelease = 'beforeRelease',
  /** 発売締切 */
  EndSale = 'endSale',
  /** 発売なし */
  None = 'none',
  /** 発売中 */
  OnSale = 'onSale',
  /** 払戻確定 */
  RefundDecision = 'refundDecision',
}

/** 重勝選択式 */
export type MultiraceSelectItemInput = {
  count: Scalars['Int'];
  numbers: Array<Scalars['Int']>;
};

/** 重勝種別 */
export enum MultiraceType {
  /** チャリロト */
  Chariloto = 'chariloto',
  /** チャリロト3 */
  Chariloto3 = 'chariloto3',
  /** チャリロト5 */
  Chariloto5 = 'chariloto5',
  /** チャリロト・セレクト */
  CharilotoSelect = 'charilotoSelect',
  /** Dokanto!4two */
  Dokanto4two = 'dokanto4two',
  /** Dokanto!7 */
  Dokanto7 = 'dokanto7',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** クイックチャージ申し込み(3Dセキュア1.0経由) */
  chargeToretaFromCredit: CreditCard3dAuthResponse;
  /** クイックチャージ申し込み(3Dセキュア2.0経由) */
  chargeToretaFromCreditV2: CreditCard3d2AuthResponse;
  /** クイックチャージ申し込み(3Dセキュア2.9経由)+投票バリデーション */
  chargeToretaFromCreditV2WithValidateOrder: CreditCard3d2AuthResponse;
  /** クイックチャージ申し込み(3Dセキュア1.0経由)+投票バリデーション */
  chargeToretaFromCreditWithValidateOrder: CreditCard3dAuthResponse;
  /** Walletからtoreta+チャージ */
  chargeToretaFromWallet: ChargeToretaFromWallet;
  /** Walletからtoreta+チャージ(投票バリデーション) */
  chargeToretaFromWalletWithValidateOrder: ChargeToretaFromWallet;
  /** ワンタイムパス認証 */
  checkOneTimePass: OtpCheckResult;
  /** ユーザー作成 */
  createUser: User;
  /** 投票限度額解除設定削除 */
  deleteDailyOrderLimit: DailyOrderLimit;
  /** 選手メモ削除 */
  deleteEntryRacerNote: EntryRacerNote;
  /** 引き落とし用口座解除 */
  deleteTransferAccount: DeleteTransferResponse;
  /** キャンペーン実行 */
  executeCampaign: ExecuteCampaignResult;
  /** 重勝のプレビュー更新 */
  issueMultiraceRandomTickets: MultiraceRandomTickets;
  /** Wallet残高調整 */
  modifyWalletBalance: ModifyWalletBalanceResult;
  /** 重勝ランダム式投票 */
  orderMultiraceRandom: OrderMultirace;
  /** 重勝選択式投票 */
  orderMultiraceSelect: OrderMultirace;
  /** 7賭式投票 */
  orderSingleRace: OrderSingleRace;
  /** GMO口座登録準備 */
  preGmoAccount: GmoTransaction;
  /** 銀行精算 手続き開始 */
  proceedBankPayoutForAdmin: BankPayout;
  /** エントリーキャンペーン登録 */
  registerEntryCampaign: EntryCampaignUser;
  /** Payeasy決済申し込み */
  requestPayeasySettlement: PayeasyStatement;
  /** Paygent振込、手動精算申し込み */
  requestPayout: WalletStatement;
  /** Admin用Paygent振込 */
  requestPayoutForAdmin: WalletStatement;
  /** 振込申込 */
  requestTransfer: WalletStatement;
  /** 住所追加/更新 */
  saveAddress: Scalars['String'];
  /** 銀行口座更新 */
  saveBankAccount: BankAccount;
  /** 投票限度額更新 */
  saveDailyOrderLimit: DailyOrderLimit;
  /** 選手メモ登録 */
  saveEntryRacerNote: EntryRacerNote;
  /** ペイペイ口座更新 */
  savePaypayAccount: BankAccount;
  /** メール一斉送信 */
  sendBulkMail: SendMailResult;
  /** 実績の更新 */
  updateAchieved: UpdateAchievedResult;
  /** 銀行精算 振込結果ステータス変更 */
  updateBankPayoutResultForAdmin: BankPayout;
  /** 並び予想更新 */
  updateLineForecast: Race;
  /** メール受取設定更新 */
  updateMailReceiveOption: MailReceiveOption;
  /** 開催中止ステータス変更 */
  updateRaceProgramCancelStatus: RaceProgram;
  /** 開催販売停止ステータス変更 */
  updateRaceProgramSalesSuspendedStatus: RaceProgram;
  /** 山分けキャンペーン登録 */
  updateShareCampaignAmounts: Scalars['Boolean'];
  /** ユーザーステータス変更 */
  updateUserStatus: User;
};

export type MutationChargeToretaFromCreditArgs = {
  amount: Scalars['Int'];
};

export type MutationChargeToretaFromCreditV2Args = {
  amount: Scalars['Int'];
};

export type MutationChargeToretaFromCreditV2WithValidateOrderArgs = {
  amount: Scalars['Int'];
  orderAmount: Scalars['Int'];
};

export type MutationChargeToretaFromCreditWithValidateOrderArgs = {
  amount: Scalars['Int'];
  orderAmount: Scalars['Int'];
};

export type MutationChargeToretaFromWalletArgs = {
  amount: Scalars['Int'];
};

export type MutationChargeToretaFromWalletWithValidateOrderArgs = {
  amount: Scalars['Int'];
  orderAmount: Scalars['Int'];
};

export type MutationCheckOneTimePassArgs = {
  code: Scalars['String'];
  mode: OtpAddress;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteEntryRacerNoteArgs = {
  input: DeleteEntryRacerNoteInput;
};

export type MutationExecuteCampaignArgs = {
  input: ExecuteCampaignDetail;
};

export type MutationIssueMultiraceRandomTicketsArgs = {
  input: IssueMultiraceRandomTicketsInput;
};

export type MutationModifyWalletBalanceArgs = {
  inputs: Array<ModifyWalletBalanceInput>;
};

export type MutationOrderMultiraceRandomArgs = {
  input: OrderMultiraceRandomInput;
};

export type MutationOrderMultiraceSelectArgs = {
  input: OrderMultiraceSelectInput;
};

export type MutationOrderSingleRaceArgs = {
  input: OrderSingleRaceInput;
};

export type MutationPreGmoAccountArgs = {
  bankCode: Scalars['String'];
};

export type MutationProceedBankPayoutForAdminArgs = {
  timeRangeCondition: TimeRangeCondition;
};

export type MutationRegisterEntryCampaignArgs = {
  entryCampaignId: Scalars['String'];
};

export type MutationRequestPayeasySettlementArgs = {
  input: RequestPayeasySettlementInput;
};

export type MutationRequestPayoutArgs = {
  amount: Scalars['Int'];
};

export type MutationRequestPayoutForAdminArgs = {
  input: RequestPayoutForAdminInput;
};

export type MutationRequestTransferArgs = {
  amount: Scalars['Int'];
};

export type MutationSaveAddressArgs = {
  input: SaveAddressInput;
};

export type MutationSaveBankAccountArgs = {
  input: SaveBankAccountInput;
};

export type MutationSaveDailyOrderLimitArgs = {
  amount: Scalars['Int'];
};

export type MutationSaveEntryRacerNoteArgs = {
  input: SaveEntryRacerNoteInput;
};

export type MutationSavePaypayAccountArgs = {
  input: SaveBankAccountInput;
};

export type MutationSendBulkMailArgs = {
  personalizations: Array<SendMailPersonalization>;
  templateId: Scalars['String'];
};

export type MutationUpdateAchievedArgs = {
  input: UpdateAchievedInput;
};

export type MutationUpdateBankPayoutResultForAdminArgs = {
  input: UpdateBankPayoutResultForAdminInput;
};

export type MutationUpdateLineForecastArgs = {
  input: UpdateLineForecastInput;
};

export type MutationUpdateMailReceiveOptionArgs = {
  input: UpdateMailReceiveOptionInput;
};

export type MutationUpdateRaceProgramCancelStatusArgs = {
  input: UpdateRaceProgramCancelStatusInput;
};

export type MutationUpdateRaceProgramSalesSuspendedStatusArgs = {
  input: UpdateRaceProgramSalesSuspendedStatusInput;
};

export type MutationUpdateShareCampaignAmountsArgs = {
  inputs: Array<UpdateShareCampaignAmountsInput>;
};

export type MutationUpdateUserStatusArgs = {
  reason: UserDisabledReason;
  status: UserStatus;
  userId: Scalars['String'];
};

/** オッズ */
export type Odds = {
  __typename?: 'Odds';
  /** 作成日 */
  created?: Maybe<Scalars['Time']>;
  /** オッズ情報 */
  oddsInfo: Scalars['String'];
  /** レース番号 */
  raceId: Scalars['String'];
  /** 最終更新日時 */
  updated?: Maybe<Scalars['Time']>;
};

/** 高額配当取得日時 */
export type OrderDateLargeDividend = {
  __typename?: 'OrderDateLargeDividend';
  /** 払戻金額 */
  refundAmount: Scalars['Int'];
  /** 取得日時 */
  updated: Scalars['Time'];
};

export enum OrderDetailStatus {
  /** 同着オーバー */
  DeadHeatOver = 'deadHeatOver',
  /** 同着レース数オーバー */
  DeadHeatRaceCountOver = 'deadHeatRaceCountOver',
  /** 的中 */
  Hit = 'hit',
  /** 不的中 */
  Miss = 'miss',
  /** 返還 */
  Return = 'return',
  /** 特払い */
  SpecialPayment = 'specialPayment',
  /** 確定前 */
  Undecided = 'undecided',
}

export enum OrderFailureReason {
  /** 残高不足 */
  BalanceInsufficient = 'balanceInsufficient',
  /** チャリロト投票失敗(701:エラー) */
  Chariloto = 'chariloto',
  /** チャリロト投票失敗(2:トークン重複) */
  CharilotoToken = 'charilotoToken',
  /** チャリロト投票失敗(1:認証失敗) */
  CharilotoUnauthorized = 'charilotoUnauthorized',
  /** チャリロト投票失敗(result_codeなし:原因不明) */
  CharilotoUnknown = 'charilotoUnknown',
  /** クレジットカード決済失敗 */
  CreditChargeFailure = 'creditChargeFailure',
  /** 不正チャージ判定 */
  FraudCharge = 'fraudCharge',
  /** 失敗なし */
  None = 'none',
  /** 失敗理由不明 */
  Unknown = 'unknown',
}

/** 重勝式投票 */
export type OrderMultirace = {
  __typename?: 'OrderMultirace';
  /** 電子マネー問い合わせID(消費予約キャンセル) */
  cancelEmoneyInquiryId?: Maybe<Scalars['String']>;
  /** 電子マネートランザクションID(消費予約キャンセル) */
  cancelEmoneyTransactionId?: Maybe<Scalars['String']>;
  created: Scalars['Time'];
  details: Array<OrderMultiraceDetail>;
  /** 電子マネー問い合わせID */
  emoneyInquiryId?: Maybe<Scalars['String']>;
  /** 電子マネートランザクションID */
  emoneyTransactionId?: Maybe<Scalars['String']>;
  /** 失敗理由 */
  failureReason: OrderFailureReason;
  /** 重勝式注文ID */
  id: Scalars['String'];
  multiraceProgram: MultiraceProgram;
  /** 重勝開催ID */
  multiraceProgramId: Scalars['Int'];
  refundStatus: OrderRefundStatus;
  /** 返還金額 */
  returnAmount: Scalars['Int'];
  /** 注文ステータス */
  status: OrderStatus;
  /** 合計金額 */
  totalAmount: Scalars['Int'];
  /** 合計払戻金 */
  totalRefund: Scalars['Int'];
  /** チャリロト注文ID(サードパーティー注文ID) */
  tpOrderId?: Maybe<Scalars['Int']>;
  updated: Scalars['Time'];
  /** ユーザーID */
  userId: Scalars['String'];
};

export type OrderMultiraceDetail = {
  __typename?: 'OrderMultiraceDetail';
  /** 購入金額 */
  amount: Scalars['Int'];
  /** 口数 */
  count: Scalars['Int'];
  created: Scalars['Time'];
  /** 組 */
  numbers: Array<Scalars['Int']>;
  /** 重勝式注文ID */
  orderMultiraceId: Scalars['String'];
  /** 払戻金 */
  refund: Scalars['Int'];
  status: OrderDetailStatus;
  updated: Scalars['Time'];
};

export type OrderMultiraceRandomInput = {
  items: Array<MultiraceRandomItemInput>;
  /** 重勝レースID */
  multiraceProgramId: Scalars['Int'];
};

export type OrderMultiraceSelectInput = {
  items: Array<MultiraceSelectItemInput>;
  /** 重勝レースID */
  multiraceProgramId: Scalars['Int'];
};

export type OrderMultiracesResult = {
  __typename?: 'OrderMultiracesResult';
  /** 総件数 */
  count: Scalars['Int'];
  items: Array<OrderMultirace>;
};

export enum OrderRefundStatus {
  /** 払戻計算済み */
  Calculated = 'calculated',
  /** 完了 */
  Completed = 'completed',
  /** 払戻前 */
  None = 'none',
  /** 払戻済み */
  Refunded = 'refunded',
  /** 消費確定済み */
  Settled = 'settled',
}

/** 7賭式投票 */
export type OrderSingleRace = {
  __typename?: 'OrderSingleRace';
  /** 電子マネー問い合わせID(消費予約キャンセル) */
  cancelEmoneyInquiryId?: Maybe<Scalars['String']>;
  /** 電子マネートランザクションID(消費予約キャンセル) */
  cancelEmoneyTransactionId?: Maybe<Scalars['String']>;
  created: Scalars['Time'];
  details: Array<OrderSingleRaceDetail>;
  /** 電子マネー問い合わせID */
  emoneyInquiryId?: Maybe<Scalars['String']>;
  /** 電子マネートランザクションID */
  emoneyTransactionId?: Maybe<Scalars['String']>;
  /** 失敗理由 */
  failureReason: OrderFailureReason;
  /** 7賭式注文ID */
  id: Scalars['String'];
  race: Race;
  /** レースID */
  raceId: Scalars['String'];
  raceProgram: RaceProgram;
  /** 払戻ステータス */
  refundStatus: OrderRefundStatus;
  /** 返還金額 */
  returnAmount: Scalars['Int'];
  /** 注文ステータス */
  status: OrderStatus;
  /** 合計金額 */
  totalAmount: Scalars['Int'];
  /** 合計払戻金 */
  totalRefund: Scalars['Int'];
  /** チャリロト注文ID(サードパーティー注文ID) */
  tpOrderId?: Maybe<Scalars['Int']>;
  updated: Scalars['Time'];
  /** ユーザーID */
  userId: Scalars['String'];
};

export type OrderSingleRaceDetail = {
  __typename?: 'OrderSingleRaceDetail';
  /** 購入金額 */
  amount: Scalars['Int'];
  /** 賭式 */
  betType: BetType;
  /** 口数 */
  count: Scalars['Int'];
  created: Scalars['Time'];
  /** 組 */
  numbers: Array<Scalars['Int']>;
  /** 7賭式注文ID */
  orderSingleRaceId: Scalars['String'];
  /** 払戻金 */
  refund: Scalars['Int'];
  /** 確定前 */
  status: OrderDetailStatus;
  updated: Scalars['Time'];
};

/** 7賭式 */
export type OrderSingleRaceInput = {
  items: Array<ItemInput>;
  raceId: Scalars['String'];
};

export type OrderSingleRacesResult = {
  __typename?: 'OrderSingleRacesResult';
  /** 収支 */
  balance: Scalars['Int'];
  /** 総件数 */
  count: Scalars['Int'];
  /** 的中率 */
  hitRate: Scalars['Float'];
  /** 的中レース数 */
  hittedRaceCount: Scalars['Int'];
  items: Array<OrderSingleRace>;
  /** 最高払戻単価 */
  maxRefund: Scalars['Int'];
  /** 最高払戻額 */
  maxTotalRefund: Scalars['Int'];
  /** 投票日数 */
  orderedDaysCount: Scalars['Int'];
  /** 投票レース数 */
  orderedRaceCount: Scalars['Int'];
  /** 万車券的中回数 */
  over10000YenRefundCount: Scalars['Int'];
  /** 回収率 */
  retrieveRate: Scalars['Float'];
  /** 投票総額 */
  totalAmount: Scalars['Int'];
  /** 払戻総額 */
  totalRefundAmount: Scalars['Int'];
};

export enum OrderStatus {
  /** 受付 */
  Confirmed = 'confirmed',
  /** 失敗 */
  Failure = 'failure',
  /** 保留 */
  Pending = 'pending',
  /** 成功 */
  Success = 'success',
}

/** ワンタイムパス宛先 */
export enum OtpAddress {
  /** メール */
  Mail = 'mail',
  /** SMS */
  Sms = 'sms',
}

export type OtpCheckResult = {
  __typename?: 'OtpCheckResult';
  /** 認証結果 */
  isPermission: Scalars['Boolean'];
};

/** ワンタイムパス発行コード */
export enum OtpIssueCode {
  /** エラー */
  Error = 'error',
  /** 電話番号取得失敗 */
  GetFailureTelNumber = 'getFailureTelNumber',
  /** 発行済 */
  Issued = 'issued',
  /** 規定値超過 */
  LimitOrver = 'limitOrver',
  /** 電話番号変換失敗 */
  NotSupportTelNumber = 'notSupportTelNumber',
  /** 成功 */
  Success = 'success',
  /** 成功（規定値） */
  SuccessLimit = 'successLimit',
}

export type OtpIssueResult = {
  __typename?: 'OtpIssueResult';
  /** 有効期限 */
  expiryPeriod: Scalars['Time'];
  /** 発行日時 */
  issuedTime: Scalars['Time'];
  /** OTP発行コード */
  otpIssueCode: OtpIssueCode;
};

/** pay-easy用銀行 */
export type PayeasyBank = {
  __typename?: 'PayeasyBank';
  /** 銀行番号（統一金融機関コード 4桁） */
  id: Scalars['ID'];
  /** 主要銀行フラグ */
  major?: Maybe<Scalars['Boolean']>;
  /** 銀行名 */
  name: Scalars['String'];
  /** 銀行名 フリガナ */
  nameRuby: Scalars['String'];
};

/** ペイジーステートメント */
export type PayeasyStatement = {
  __typename?: 'PayeasyStatement';
  /** 決済機関コード */
  bankCode: Scalars['String'];
  /** 決済機関リンク(ネットバンキングのみ) */
  bankURL?: Maybe<Scalars['String']>;
  /** キャンセル日時 [旧]: cancel_date */
  canceled: Scalars['Time'];
  /** 申込日時 [旧]: request_date */
  created: Scalars['Time'];
  /** お客様番号 */
  customerCode: Scalars['String'];
  /** 支払期限日時 [旧]: paydate */
  dueDate: Scalars['Time'];
  /** 引き継ぎ情報(ネットバンキングのみ) */
  encryptInfo?: Maybe<Scalars['String']>;
  /** 支払内容確認画面アクセス用の暗号化文字列 */
  encryptedAccessCode?: Maybe<Scalars['String']>;
  /** 引き継ぎ情報のパターン番号(ネットバンキングのみ) */
  patternCode?: Maybe<Scalars['String']>;
  /** 収納機関番号 */
  receivePaymentCode?: Maybe<Scalars['String']>;
  /** 決済方法(ネットバンキング or ATM) */
  settlementType: TransferDeviceType;
  /** 請求番号 */
  transactionNum: Scalars['Int'];
  /** 更新日時 [旧]: stamp(timestamp) */
  updated: Scalars['Time'];
  /**
   * ステートメントID(deprecated)
   * @deprecated fix id spelling
   */
  walletStatementID: Scalars['ID'];
  /** ステートメントID */
  walletStatementId: Scalars['ID'];
};

export type PaygentStatement = {
  __typename?: 'PaygentStatement';
  /**
   * ユーザー口座ID(deprecated)
   * @deprecated fix id spelling
   */
  bankAccountID: Scalars['String'];
  /** ユーザー口座ID */
  bankAccountId: Scalars['String'];
  /** 申込日時 */
  created: Scalars['Time'];
  /** 正当性チェック用ハッシュ */
  hash: Scalars['String'];
  /** 受付番号 */
  receiveNumber?: Maybe<Scalars['String']>;
  /** 振込金額 */
  requestAmount: Scalars['Int'];
  /** 振込番号 */
  requestNumber: Scalars['String'];
  /** 振込予定日時 */
  reserveTransferDate?: Maybe<Scalars['Time']>;
  /** 受付結果コード */
  resultCode?: Maybe<Scalars['String']>;
  /** 振込実行日時 */
  transferDate?: Maybe<Scalars['Time']>;
  /** 振込処理結果コード */
  transferResultCode?: Maybe<Scalars['String']>;
  /** 振込ステータス */
  transferStatus?: Maybe<Scalars['String']>;
  /** 更新日時 */
  updated: Scalars['Time'];
  /**
   * ステートメントID(deprecated)
   * @deprecated fix id spelling
   */
  walletStatementID: Scalars['ID'];
  /** ステートメントID */
  walletStatementId: Scalars['ID'];
};

/** 個人情報 */
export type PersonalInfo = {
  __typename?: 'PersonalInfo';
  birthDate?: Maybe<Scalars['Date']>;
  dmmAccountStatus?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstnameRuby?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  isBirthDateLocked?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastnameRuby?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type ProcessingCount = {
  __typename?: 'ProcessingCount';
  count: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /** 告知取得 */
  announcement?: Maybe<Announcement>;
  /** 全銀データ+支店取得 */
  bankBranches: Array<Maybe<BankBranch>>;
  /** 管理画面用 銀行精算取得 */
  bankPayoutForAdmin: BankPayout;
  /** 管理画面用 銀行精算申請取得 */
  bankPayoutRequestForAdmin: BankPayoutRequest;
  /** 管理画面用 銀行精算申請複数取得 */
  bankPayoutRequestsForAdmin: Array<BankPayoutRequest>;
  /** 管理画面用 銀行精算複数取得 */
  bankPayoutsForAdmin: Array<BankPayout>;
  /** 全銀データ複数取得 */
  banks: Array<Maybe<Bank>>;
  /** コールバックURL */
  callbackUrl: Scalars['String'];
  /** 3Dセキュア認証結果取得 */
  cc3DAuth: Cc3dAuth;
  /** チャージバックキャンペーン進捗状況取得 */
  chargebackCampaignProgress: ChargebackCampaignProgress;
  /** 国コード取得 */
  countryCode: CheckCountry;
  /** クレジットカードステータス取得 */
  creditCardStatus: CreditCardStatus;
  /** エントリー中のキャンペーン一覧 */
  enteringCampaignIDs: Array<Scalars['String']>;
  /** 選手メモカウント取得 */
  entryRacerNoteCounts: Array<EntryRacerNoteCount>;
  /** 選手メモ取得 */
  entryRacerNotes: Array<EntryRacerNote>;
  /** 自前のレース成績取得処理 */
  entryRacerScoreDetailOwns: Array<EntryRacerScoreDetailOwn>;
  /** 自前のレース成績取得処理 */
  entryRacerScoreDetailOwnsCondition: Array<EntryRacerScoreDetailOwn>;
  /** 参加選手複数取得 */
  entryRacers: Array<EntryRacer>;
  /** GMO用銀行複数取得 */
  gmoBanks: Array<PayeasyBank>;
  /** エントリーキャンペーン取得 */
  isEnteredEntryCampaign: Scalars['Boolean'];
  /** 振込先登録可否 */
  isTransferRegistration: TransferRegistResult;
  /** ワンタイムパス発行 */
  issueOneTimePass: OtpIssueResult;
  /** メルマガ購読判定取得 */
  mailSubscribed: Scalars['Boolean'];
  /** 重勝取得 */
  multiraceProgram: MultiraceProgram;
  /** 重勝複数取得 */
  multiracePrograms: Array<Maybe<MultiraceProgram>>;
  /** 重勝式払戻取得 */
  multiraceRefunds: Array<MultiraceRefund>;
  /** オッズ */
  odds: Odds;
  /** レース情報(日付&場コード&レース番号)からオッズ取得 */
  oddsByCondition: Odds;
  /** 高額配当金取得日時 */
  orderDateLargeDividend: OrderDateLargeDividend;
  /** 重勝式投票取得 */
  orderMultirace: OrderMultirace;
  /** 管理画面用 重勝式投票取得 */
  orderMultiraceForAdmin: OrderMultirace;
  /** 重勝式投票複数取得 */
  orderMultiraces: OrderMultiracesResult;
  /** 管理画面用 重勝式投票複数取得 */
  orderMultiracesForAdmin: OrderMultiracesResult;
  /** 7賭式投票取得 */
  orderSingleRace: OrderSingleRace;
  /** 管理画面用 7賭式投票取得 */
  orderSingleRaceForAdmin: OrderSingleRace;
  /** 7賭式投票複数取得 */
  orderSingleRaces: Array<OrderSingleRace>;
  /** レース別7賭式投票複数取得 */
  orderSingleRacesByRaceId: Array<OrderSingleRace>;
  /** 管理画面用 7賭式投票複数取得 */
  orderSingleRacesForAdmin: Array<OrderSingleRace>;
  /** pay-easy用銀行複数取得 */
  payeasyBanks: Array<PayeasyBank>;
  /** レース取得 */
  race: Race;
  /** 日付&場コード&レース番号からレース取得 */
  raceByCondition: Race;
  /** 競争番組取得 */
  raceProgram: RaceProgram;
  /** 日付&場コードから競争番組取得 */
  raceProgramByCondition: RaceProgram;
  /** 競争番組複数取得 */
  racePrograms: Array<RaceProgram>;
  /** 同一会員チェック */
  sameHashUsers: Array<User>;
  /** 投票成績 */
  singleRaceBetResult: SingleRaceBetResult;
  /** 開催時間別投票成績 */
  singleRaceBetResultByBetType: Array<SingleRaceBetResultByBetType>;
  /** グレード別投票成績 */
  singleRaceBetResultByGrade: Array<SingleRaceBetResultByGrade>;
  /** 競輪場別投票成績 */
  singleRaceBetResultByVelodrome: Array<SingleRaceBetResultByVelodrome>;
  /** 競輪場別投票成績 */
  singleRaceBetResultByVelodromeV2: Array<SingleRaceBetResultByVelodrome>;
  /** 7賭式払戻取得 */
  singleRaceRefunds: Array<SingleRaceRefund>;
  /** 処理中の振込処理件数取得 */
  transferStatementProcessingCount: ProcessingCount;
  /** 管理画面用 振込・入金履歴 */
  transferStatementsForAdmin: Array<Maybe<TransferStatement>>;
  /** ユーザー取得 */
  user: User;
  /** 管理画面用 ユーザー取得 */
  userForAdmin?: Maybe<User>;
  /** 処理中件数取得 */
  walletStatementProcessingCount: ProcessingCount;
  /** 入金・出金履歴 */
  walletStatements: Array<Maybe<WalletStatement>>;
  /** 管理画面用 入金・出金履歴 */
  walletStatementsForAdmin: Array<Maybe<WalletStatement>>;
};

export type QueryBankBranchesArgs = {
  id: Scalars['ID'];
};

export type QueryBankPayoutForAdminArgs = {
  id: Scalars['ID'];
};

export type QueryBankPayoutRequestForAdminArgs = {
  id: Scalars['ID'];
};

export type QueryBankPayoutRequestsForAdminArgs = {
  input: BankPayoutRequestsForAdminInput;
};

export type QueryBankPayoutsForAdminArgs = {
  input: BankPayoutsInput;
};

export type QueryBanksArgs = {
  major: Scalars['Boolean'];
};

export type QueryCallbackUrlArgs = {
  url: Scalars['String'];
};

export type QueryCc3DAuthArgs = {
  md: Scalars['String'];
};

export type QueryEntryRacerNoteCountsArgs = {
  heldDate: Scalars['Date'];
};

export type QueryEntryRacerNotesArgs = {
  raceId: Scalars['String'];
};

export type QueryEntryRacerScoreDetailOwnsArgs = {
  detailCnt: Scalars['Int'];
  heldDate: Scalars['String'];
  raceId: Scalars['String'];
};

export type QueryEntryRacerScoreDetailOwnsConditionArgs = {
  input: GetScoreDetailOwnsInputParam;
};

export type QueryEntryRacersArgs = {
  raceId: Scalars['String'];
};

export type QueryIsEnteredEntryCampaignArgs = {
  entryCampaignId: Scalars['String'];
};

export type QueryIssueOneTimePassArgs = {
  mode: OtpAddress;
};

export type QueryMultiraceProgramArgs = {
  input: MultiraceProgramInput;
};

export type QueryMultiraceProgramsArgs = {
  input: MultiraceProgramsInput;
};

export type QueryMultiraceRefundsArgs = {
  multiraceProgramId: Scalars['Int'];
};

export type QueryOddsArgs = {
  raceId: Scalars['String'];
};

export type QueryOddsByConditionArgs = {
  input: RaceInput;
};

export type QueryOrderDateLargeDividendArgs = {
  period: Scalars['Int'];
};

export type QueryOrderMultiraceArgs = {
  orderMultiraceId: Scalars['String'];
};

export type QueryOrderMultiraceForAdminArgs = {
  orderMultiraceId: Scalars['String'];
};

export type QueryOrderMultiracesArgs = {
  input: OrdersInput;
};

export type QueryOrderMultiracesForAdminArgs = {
  input: OrdersInput;
  userId: Scalars['String'];
};

export type QueryOrderSingleRaceArgs = {
  orderSingleRaceId: Scalars['String'];
};

export type QueryOrderSingleRaceForAdminArgs = {
  orderSingleRaceId: Scalars['String'];
};

export type QueryOrderSingleRacesArgs = {
  input: OrdersInput;
};

export type QueryOrderSingleRacesByRaceIdArgs = {
  raceId: Scalars['String'];
};

export type QueryOrderSingleRacesForAdminArgs = {
  input: OrdersInput;
  userId: Scalars['String'];
};

export type QueryPayeasyBanksArgs = {
  input: BankInput;
};

export type QueryRaceArgs = {
  id: Scalars['String'];
};

export type QueryRaceByConditionArgs = {
  input: RaceInput;
};

export type QueryRaceProgramArgs = {
  id: Scalars['String'];
};

export type QueryRaceProgramByConditionArgs = {
  input: RaceProgramInput;
};

export type QueryRaceProgramsArgs = {
  input: RaceProgramsInput;
};

export type QuerySameHashUsersArgs = {
  userId: Scalars['String'];
};

export type QuerySingleRaceBetResultArgs = {
  input: BetResultsInput;
};

export type QuerySingleRaceBetResultByBetTypeArgs = {
  input: BetResultsInputByBetType;
};

export type QuerySingleRaceBetResultByGradeArgs = {
  input: BetResultsInputByBetType;
};

export type QuerySingleRaceBetResultByVelodromeArgs = {
  targetYear: Scalars['Int'];
};

export type QuerySingleRaceBetResultByVelodromeV2Args = {
  input: BetResultsInputByBetType;
};

export type QuerySingleRaceRefundsArgs = {
  raceId: Scalars['String'];
};

export type QueryTransferStatementsForAdminArgs = {
  input: WalletStatementsInput;
  userId: Scalars['String'];
};

export type QueryUserForAdminArgs = {
  searchWord: Scalars['String'];
};

export type QueryWalletStatementsArgs = {
  input: WalletStatementsInput;
};

export type QueryWalletStatementsForAdminArgs = {
  input: WalletStatementsInput;
  userId: Scalars['String'];
};

export type Race = {
  __typename?: 'Race';
  /** 最終前ラップタイム */
  beforeFinalLapTime: Scalars['Int'];
  /** 車立数 */
  bicycleCount: Scalars['Int'];
  created: Scalars['Time'];
  /** バンク距離 */
  distance: Scalars['Int'];
  /** 参加選手情報数 */
  entryRacerInfoCount: Scalars['Int'];
  /** 選手情報 */
  entryRacers: Array<EntryRacer>;
  /** 参加選手情報有無 */
  existsEntryRacerInfo: Scalars['Boolean'];
  /** 競輪スポニチに選手コメントがあるか */
  existsEntryRacersComment: Scalars['Boolean'];
  /** 最終ラップタイム */
  finalLapTime: Scalars['Int'];
  /** レース上がりタイム */
  finalStageRaceTime: Scalars['Int'];
  id: Scalars['String'];
  /** レース中止フラグ */
  isCanceled: Scalars['Boolean'];
  /** 並び更新フラグ */
  isLineForecastModified: Scalars['Boolean'];
  /** 周回数 */
  lapCount: Scalars['Int'];
  /** 先導員級班名称 */
  leaderClassName: Scalars['String'];
  /** 先導員名称 */
  leaderName: Scalars['String'];
  /** 並び予想 */
  lineForecast?: Maybe<Array<Array<Maybe<Scalars['Int']>>>>;
  /** オッズ情報 */
  odds?: Maybe<Odds>;
  /** ネット投票締切予定時刻 */
  orderDeadline: Scalars['Time'];
  /** 級名称 */
  raceClassName: Scalars['String'];
  /** レース登録状況 */
  raceEntryStatus: RaceEntryStatus;
  /** 競争種目名 */
  raceEventName: Scalars['String'];
  /** レース番号(1~12R) */
  raceNum: Scalars['Int'];
  raceProgramId: Scalars['String'];
  /** レース中止理由コード */
  raceSuspendReasonCode: Scalars['Int'];
  /** 7賭式払戻情報 */
  singleRaceRefunds: Array<SingleRaceRefund>;
  /** 発売情報 */
  singleRaceReleaseInfo: SingleRaceReleaseInfo;
  /** 発走予定時刻 */
  startTime: Scalars['Time'];
  updated: Scalars['Time'];
};

export enum RaceEntryStatus {
  /** 確定 */
  Decision = 'decision',
  /** 開催中止 */
  StopHeld = 'stopHeld',
  /** レース中止 */
  StopRace = 'stopRace',
  /** 未登録 */
  Unregistered = 'unregistered',
}

export type RaceProgram = {
  __typename?: 'RaceProgram';
  created: Scalars['Time'];
  /** 日次 */
  dailyNum: Scalars['Int'];
  /** レースグレード(F2, G3など) */
  gradeName: Scalars['String'];
  /** 開催日 */
  heldDate: Scalars['Date'];
  /** 開催初日 */
  heldFirstDate: Scalars['Date'];
  /** 開催回 */
  heldNum: Scalars['Int'];
  /** 開催節 */
  heldPart: Scalars['Int'];
  /** 旧program_id */
  id: Scalars['String'];
  /** 開催中止フラグ */
  isCanceled: Scalars['Boolean'];
  /** ガールズケイリンフラグ */
  isGirls: Scalars['Boolean'];
  /** 最終日フラグ */
  isLastDay: Scalars['Boolean'];
  /** 販売停止フラグ */
  isSalesSuspended: Scalars['Boolean'];
  multiracePrograms: Array<MultiraceProgram>;
  /** プログラム名 */
  programName: Scalars['String'];
  /** プログラム名(サブ) */
  programSubName: Scalars['String'];
  /** 主催者コード */
  promoterCode: Scalars['Int'];
  /** 予定レース数 */
  raceCount: Scalars['Int'];
  races: Array<Race>;
  /** 開催時間枠区分 */
  timeFrame: TimeFrame;
  updated: Scalars['Time'];
  /** 競輪場コード */
  velodromeCode: Scalars['Int'];
};

/** 発売状況 */
export enum ReleaseStatus {
  /** 発売後取消 */
  CancelAfterReleased = 'cancelAfterReleased',
  /** 発売前取消 */
  CancelBeforeReleased = 'cancelBeforeReleased',
  /** 発売なし */
  Nothing = 'nothing',
  /** 発売あり */
  Released = 'released',
}

export type RequestPayeasySettlementInput = {
  amount: Scalars['Int'];
  bankCode: Scalars['String'];
  deviceType: TransferDeviceType;
};

export type RequestPayoutForAdminInput = {
  amount: Scalars['Int'];
  userId: Scalars['String'];
};

/** ロール(権限情報) */
export enum Role {
  /** 管理画面: 経理部 */
  AdminAccounting = 'ADMIN_ACCOUNTING',
  /** 管理画面: チャリロト管理者 */
  AdminChariloto = 'ADMIN_CHARILOTO',
  /** 管理画面: 一般ユーザー */
  AdminMember = 'ADMIN_MEMBER',
  /** 管理画面: 記者 */
  AdminReporter = 'ADMIN_REPORTER',
  /** 管理画面: カスタマサポート部 */
  AdminSupport = 'ADMIN_SUPPORT',
  /** DMMユーザ */
  User = 'USER',
}

export type SaveAddressInput = {
  /** 建物 */
  building: Scalars['String'];
  /** 国コード */
  countryCode: Scalars['String'];
  /** 市区町村 */
  locality: Scalars['String'];
  /** 郵便番号 */
  postalCode: Scalars['String'];
  /** 県コード */
  prefectureCode: Scalars['String'];
  /** 地域(ex: 東京都) */
  region: Scalars['String'];
  /** 住所詳細 */
  streetAddress: Scalars['String'];
};

export type SaveBankAccountInput = {
  accountNumber: Scalars['String'];
  accountType: BankAccountType;
  bankBranchID: Scalars['String'];
  bankID: Scalars['String'];
};

export type SaveEntryRacerNoteInput = {
  body: Scalars['String'];
  raceID: Scalars['String'];
  registrationNum: Scalars['Int'];
};

export type SendMailPersonalization = {
  args: Scalars['Map'];
  userId: Scalars['String'];
};

export type SendMailResult = {
  __typename?: 'SendMailResult';
  /** 送信失敗数 */
  failCount: Scalars['Int'];
  /** 送信失敗ユーザID一覧 */
  failedUserIds: Array<Scalars['String']>;
  /** 総リクエストユーザ数 */
  requestCount: Scalars['Int'];
  /** 送信成功数（完全退会者省く） */
  successCount: Scalars['Int'];
};

/** SFN実行モード */
export enum SfnExecuteMode {
  /** 超得キャンペーン */
  Choutoku = 'choutoku',
  /** ユーザ掘り起こし */
  Digupuser = 'digupuser',
  /** 激得キャンペーン */
  Gekitoku = 'gekitoku',
  /** ナミ乗りキャンペーン */
  Naminori = 'naminori',
  /** 3連単的中ミッション */
  Trifecta = 'trifecta',
}

/** 投票成績(通算) */
export type SingleRaceBetResult = {
  __typename?: 'SingleRaceBetResult';
  /** 収支 */
  balance: Scalars['Int'];
  /** 総件数 */
  count: Scalars['Int'];
  /** 的中数 */
  hitCount: Scalars['Int'];
  /** 的中率 */
  hitRate: Scalars['Float'];
  /** 的中レース数 */
  hittedRaceCount: Scalars['Int'];
  /** 年/月/日別成績 */
  items: Array<SingleRaceBetResultItem>;
  /** 最高的中オッズ */
  maxHitOdds: Scalars['Float'];
  /**
   * 最高払戻単価
   * @deprecated Because maxHitOdds are now used instead
   */
  maxRefund: Scalars['Int'];
  /** 最高払戻額 */
  maxTotalRefund: Scalars['Int'];
  /** 投票日数 */
  orderedDaysCount: Scalars['Int'];
  /** 投票レース数 */
  orderedRaceCount: Scalars['Int'];
  /** 万車券的中回数 */
  over10000YenRefundCount: Scalars['Int'];
  /** 対象種別 */
  rangeType: BetResultRangeType;
  /** 回収率 */
  retrieveRate: Scalars['Float'];
  /** 投票総額 */
  totalAmount: Scalars['Int'];
  /** 払戻総額 */
  totalRefundAmount: Scalars['Int'];
};

/** 開催時間別収支 */
export type SingleRaceBetResultByBetType = {
  __typename?: 'SingleRaceBetResultByBetType';
  /** 回収率 */
  retrieveRate: Scalars['Float'];
  /** 開催時間枠区分 */
  timeFrame: TimeFrame;
  /** 投票総額 */
  totalAmount: Scalars['Int'];
  /** 払戻総額 */
  totalRefundAmount: Scalars['Int'];
};

/** レースグレード別収支 */
export type SingleRaceBetResultByGrade = {
  __typename?: 'SingleRaceBetResultByGrade';
  /** レースグレード */
  gradeName: Scalars['String'];
  /** 回収率 */
  retrieveRate: Scalars['Float'];
  /** 投票総額 */
  totalAmount: Scalars['Int'];
  /** 払戻総額 */
  totalRefundAmount: Scalars['Int'];
};

/** 競輪場別収支 */
export type SingleRaceBetResultByVelodrome = {
  __typename?: 'SingleRaceBetResultByVelodrome';
  /** 購入レース番号CSV */
  buyRaceNums: Scalars['String'];
  /** 的中レース番号CSV */
  hitRaceNums: Scalars['String'];
  /** 回収率 */
  retrieveRate: Scalars['Float'];
  /** 投票総額 */
  totalAmount: Scalars['Int'];
  /** 払戻総額 */
  totalRefundAmount: Scalars['Int'];
  /** 競輪場コード */
  velodromeCode: Scalars['Int'];
};

/** 投票成績 */
export type SingleRaceBetResultItem = {
  __typename?: 'SingleRaceBetResultItem';
  /** 収支 */
  balance: Scalars['Int'];
  /** 総件数 */
  count: Scalars['Int'];
  /** 的中数 */
  hitCount: Scalars['Int'];
  /** 的中率 */
  hitRate: Scalars['Float'];
  /** 的中レース数 */
  hittedRaceCount: Scalars['Int'];
  /** 最高的中オッズ */
  maxHitOdds: Scalars['Float'];
  /**
   * 最高払戻単価
   * @deprecated Because maxHitOdds are now used instead
   */
  maxRefund: Scalars['Int'];
  /** 最高払戻額 */
  maxTotalRefund: Scalars['Int'];
  /** 投票日数 */
  orderedDaysCount: Scalars['Int'];
  /** 投票レース数 */
  orderedRaceCount: Scalars['Int'];
  /** 万車券的中回数 */
  over10000YenRefundCount: Scalars['Int'];
  /** 回収率 */
  retrieveRate: Scalars['Float'];
  /** 対象期間 */
  targetDate: Scalars['Date'];
  /** 投票総額 */
  totalAmount: Scalars['Int'];
  /** 払戻総額 */
  totalRefundAmount: Scalars['Int'];
};

/** 7賭式払戻 */
export type SingleRaceRefund = {
  __typename?: 'SingleRaceRefund';
  /** 登録状況 */
  betStatus: BetStatus;
  /** 賭式 */
  betType: BetType;
  created: Scalars['Time'];
  /** 的中番号1 */
  hitNum1?: Maybe<Scalars['Int']>;
  /** 的中番号2 */
  hitNum2?: Maybe<Scalars['Int']>;
  /** 的中番号3 */
  hitNum3?: Maybe<Scalars['Int']>;
  /** 複勝着順 */
  hitPlaceRank?: Maybe<Scalars['Int']>;
  /** レースID */
  raceId: Scalars['String'];
  /** 払戻単価 */
  refund: Scalars['Int'];
  updated: Scalars['Time'];
};

/** レース別7賭式払戻 */
export type SingleRaceRefundsByRaceId = {
  __typename?: 'SingleRaceRefundsByRaceId';
  raceId: Scalars['String'];
  singleRaceRefunds: Array<SingleRaceRefund>;
};

/** 7賭式発売情報 */
export type SingleRaceReleaseInfo = {
  __typename?: 'SingleRaceReleaseInfo';
  /** 二枠単発売フラグ */
  bracketPerfectaReleaseStatus: ReleaseStatus;
  /** 二枠複発売フラグ */
  bracketQuinellaReleaseStatus: ReleaseStatus;
  created: Scalars['Time'];
  /** 二車単発売フラグ */
  perfectaReleaseStatus: ReleaseStatus;
  /** ワイド発売フラグ */
  quinellaPlaceReleaseStatus: ReleaseStatus;
  /** 二車複発売フラグ */
  quinellaReleaseStatus: ReleaseStatus;
  raceID: Scalars['String'];
  /** 三連単発売フラグ */
  trifectaReleaseStatus: ReleaseStatus;
  /** 三連複発売フラグ */
  trioReleaseStatus: ReleaseStatus;
  updated: Scalars['Time'];
};

export type TallyBetType = {
  /** 付与上限金額 */
  AssignmentLimit: Scalars['String'];
  /** 付与割合 */
  AssignmentRatio: Scalars['String'];
  /** チャージ方法 */
  ChargeMethod: Scalars['String'];
};

export enum Target {
  /** 銀行口座（精算） */
  Bank = 'bank',
  /** GMO */
  Gmo = 'gmo',
  /** 手動残高調整  */
  Manual = 'manual',
  /** Payeasy（銀行口座・ATM）（入金） */
  Payeasy = 'payeasy',
  /** ペイペイ */
  Paypay = 'paypay',
  /** 払戻金（入金） */
  Prize = 'prize',
  /** toreta+（精算） */
  Toreta = 'toreta',
}

export enum TimeFrame {
  /** ミッドナイト */
  Midnight = 'midnight',
  /** モーニング */
  Morning = 'morning',
  /** ナイター */
  Nighter = 'nighter',
  /** 通常 */
  Normal = 'normal',
  Unknown = 'unknown',
}

export type Toreta = {
  __typename?: 'Toreta';
  balance: Scalars['Int'];
  limitedBalance: Scalars['Int'];
};

export enum TransferDeviceType {
  /** ATM振込 */
  Atm = 'atm',
  /** PC(インターネットバンキング) */
  Pc = 'pc',
}

export enum TransferDeviceTypeCode {
  /** ATM振込 */
  P01 = 'P01',
  /** PC(インターネットバンキング) */
  P02 = 'P02',
}

/** クレジットカード情報 */
export type TransferRegistResult = {
  __typename?: 'TransferRegistResult';
  hasPersonalHash: Scalars['Boolean'];
  hasSameHashUser: Scalars['Boolean'];
};

/** 引き落としステートメント */
export type TransferStatement = {
  __typename?: 'TransferStatement';
  /** 金額 */
  amount: Scalars['Int'];
  /** 銀行名 */
  bankName: Scalars['String'];
  /** 作成日時 */
  created: Scalars['Time'];
  /** ステートメントID */
  id: Scalars['String'];
  /** 処理ステータス */
  status: WalletStatementStatus;
  /** ターゲット */
  target: Target;
  /** 種別 */
  type: WalletStatementType;
  /** 更新日時 */
  updated: Scalars['Time'];
  /** ユーザーID */
  userId: Scalars['ID'];
};

export type UpdateAchievedInput = {
  /** 対象メンバー */
  targetMembers: Array<Scalars['String']>;
  /** 更新対象のミッション */
  targetMission: ManualMissionId;
};

export type UpdateAchievedResult = {
  __typename?: 'UpdateAchievedResult';
  /** 送信失敗ユーザID一覧 */
  failedUserIds: Array<Scalars['String']>;
  /** 実行結果 */
  result: Scalars['String'];
  /** 更新件数 */
  updateCount: Scalars['Int'];
};

export type UpdateBankPayoutResultForAdminInput = {
  /** 手動精算ID */
  bankPayoutId: Scalars['ID'];
  /** 手動精算リクエストステータス */
  bankPayoutRequestStatuses: Array<BankPayoutRequestStatusInput>;
};

export type UpdateLineForecastInput = {
  /** 予想印 */
  forecastMarks?: InputMaybe<Array<ForecastMark>>;
  /** 並び予想 */
  lineForecast?: InputMaybe<Array<Array<InputMaybe<Scalars['Int']>>>>;
  /** レースID */
  raceId: Scalars['String'];
};

export type UpdateMailReceiveOptionInput = {
  /** 投票完了メール受取 */
  atVoteCompleted: Scalars['Boolean'];
  /** 投票レースの結果確定メール受取 */
  atVotedRaceResultConfirmed: Scalars['Boolean'];
  /** 投票レース発走お知らせメール受取 */
  atVotedRaceStarted: Scalars['Boolean'];
};

export type UpdateRaceProgramCancelStatusInput = {
  /** 中止フラグ */
  isCanceled: Scalars['Boolean'];
  /** 開催ID */
  raceProgramId: Scalars['String'];
};

export type UpdateRaceProgramSalesSuspendedStatusInput = {
  /** 販売停止フラグ */
  isSalesSuspended: Scalars['Boolean'];
  /** 開催ID */
  raceProgramId: Scalars['String'];
};

export type UpdateShareCampaignAmountsInput = {
  /** 重勝レースID */
  multiraceProgramId: Scalars['Int'];
  /** 山分けキャンペーン金額 */
  shareCampaignAmount: Scalars['Int'];
};

/** ユーザー */
export type User = {
  __typename?: 'User';
  /** 住所 */
  address?: Maybe<Address>;
  /** 銀行口座アカウント */
  bankAccount?: Maybe<BankAccount>;
  /** チャージバック訴求情報 */
  chargebackAppleal?: Maybe<ApplealCampaignInfo>;
  /** チャリロト会員ID */
  charilotoUserId?: Maybe<Scalars['String']>;
  /** クレジットカード情報 */
  creditCard?: Maybe<CreditCard>;
  /** 投票限度額設定 */
  dailyOrderLimit?: Maybe<DailyOrderLimit>;
  /** 無効理由 */
  disabledReason?: Maybe<UserDisabledReason>;
  /** GMO会員ID */
  gmoAccountID?: Maybe<Scalars['String']>;
  /** GMOアカウント情報 */
  gmoAccountInfo?: Maybe<GmoAccountInfo>;
  /** 招待者ID */
  inviter?: Maybe<Scalars['String']>;
  /** メール受取設定 */
  mailReceiveOption?: Maybe<MailReceiveOption>;
  /** D100 ID */
  memberId?: Maybe<Scalars['String']>;
  /** ペイペイ口座ID */
  paypayAccountID?: Maybe<Scalars['String']>;
  /** ペイペイ口座設定日 */
  paypayAccountSetting?: Maybe<Scalars['Time']>;
  /** 個人情報 */
  personalInfo: PersonalInfo;
  /** 会員ステータス */
  status?: Maybe<UserStatus>;
  /** ユーザーID（OpenID） */
  userId: Scalars['ID'];
  /** 本人確認ステータス */
  verificationStatus?: Maybe<VerificationStatus>;
  /** 本人確認元 */
  verificator?: Maybe<Verificator>;
  /** 本人確認完了時間 */
  verified?: Maybe<Scalars['Time']>;
  /** ウォレット */
  wallet?: Maybe<Wallet>;
};

/** 無効理由 */
export enum UserDisabledReason {
  /** ギャンブル依存症 */
  GamblingAddiction = 'gamblingAddiction',
  /** その他 */
  Other = 'other',
  /** 不正利用 */
  Unauthorized = 'unauthorized',
}

/** 会員ステータス */
export enum UserStatus {
  /** 無効会員 */
  Disabled = 'disabled',
  /** 非会員 */
  Guest = 'guest',
  /** 本会員 */
  Regular = 'regular',
  /** 仮会員 */
  Temporary = 'temporary',
}

/** オッズ有効フラグ */
export enum ValidFlag {
  /** 無効 */
  Invalid = 'invalid',
  /** 有効 */
  Valid = 'valid',
  /** 有効だが欠車 */
  ValidButEmptyBicycle = 'validButEmptyBicycle',
}

/** 本人確認ステータス */
export enum VerificationStatus {
  /** 年齢制限（20歳未満） */
  AgeRestriction = 'ageRestriction',
  /** 承認済み */
  Approved = 'approved',
  /** 申請待ち */
  Waiting = 'waiting',
}

/** 本人確認元 */
export enum Verificator {
  /** DMM本人確認 */
  Dmm = 'dmm',
  /** PayPay銀行 */
  Paypay = 'paypay',
  Unknown = 'unknown',
}

/** ウォレット */
export type Wallet = {
  __typename?: 'Wallet';
  /** 残高(入金+当選金) */
  balance: Scalars['Int'];
  /** 作成日時 */
  created: Scalars['Time'];
  /** 疑惑回数 */
  doubtCount: Scalars['Int'];
  /** 汎用コード */
  genericCodeWs: Scalars['Int'];
  /** 最終出金日 */
  latestWithdrawBank?: Maybe<Scalars['Time']>;
  /** 保留中残高(1000万以上の配当 + 住所未入力の際に利用) */
  pendingBalance: Scalars['Int'];
  /** toreta+残高 */
  toreta: Toreta;
  /** ペイペイ残高 */
  transferBalance: Scalars['Int'];
  /** 更新日時 */
  updated: Scalars['Time'];
  /** ユーザーID */
  userId: Scalars['ID'];
};

/** ウォレットステートメント */
export type WalletStatement = {
  __typename?: 'WalletStatement';
  /** 金額 */
  amount: Scalars['Int'];
  /** ブラウザ */
  browser?: Maybe<Scalars['String']>;
  /** 作成日時 */
  created: Scalars['Time'];
  /** デバイス */
  device?: Maybe<Scalars['String']>;
  /**
   * emoney問合せID(deprecated)
   * @deprecated fix id spelling
   */
  emoneyInquiryID: Scalars['String'];
  /** emoney問合せID(後で削除) */
  emoneyInquiryId: Scalars['String'];
  /**
   * emoney取引ID(deprecated)
   * @deprecated fix id spelling
   */
  emoneyTransactionID?: Maybe<Scalars['String']>;
  /** emoney取引ID(後で削除) */
  emoneyTransactionId?: Maybe<Scalars['String']>;
  /** 手数料 */
  fee: Scalars['Int'];
  /** ステートメントID */
  id: Scalars['String'];
  /**
   * くじ投票ID(deprecated)
   * @deprecated fix id spelling
   */
  lotteryAcceptID?: Maybe<Scalars['String']>;
  /** くじ投票ID */
  lotteryAcceptId?: Maybe<Scalars['String']>;
  /** 決済取引ID */
  settlementTransactionId: Scalars['String'];
  /** 処理ステータス: waiting:処理待ち,confirmed;受付ずみ,completed:完了,failure:処理失敗 [旧]: bal_log_status */
  status: WalletStatementStatus;
  /** ターゲット: toreta:toretaから入金またはtoretaへ出金, bank:銀行から入金, payeasy:Payeasyから入金, prize:払戻金から入金 */
  target: Target;
  /** 種別: deposit:入金, withdraw:出金 */
  type: WalletStatementType;
  /** 更新日時 */
  updated: Scalars['Time'];
  /**
   * ユーザーID(deprecated)
   * @deprecated fix id spelling
   */
  userID: Scalars['ID'];
  /** ユーザーID */
  userId: Scalars['ID'];
};

/** 処理ステータス */
export enum WalletStatementStatus {
  /** 完了 */
  Completed = 'completed',
  /** 受付済み */
  Confirmed = 'confirmed',
  /** 処理失敗 */
  Failure = 'failure',
  /** 処理失敗(ロールバック前) */
  FailureBeforeRollback = 'failureBeforeRollback',
  /** 保留（問い合わせが必要な状態） */
  Pending = 'pending',
  /** 保留（タイムアウトで一度失敗した状態） */
  PendingTimeout = 'pendingTimeout',
  /** 処理中 */
  Processing = 'processing',
  /** 処理待ち（登録直後の一時的な状態） */
  Waiting = 'waiting',
}

export enum WalletStatementType {
  /** 入金 */
  Deposit = 'deposit',
  /** 振込 */
  Transfer = 'transfer',
  /** 精算 */
  Withdraw = 'withdraw',
}

export type BankInput = {
  deviceType: TransferDeviceType;
  major: Scalars['Boolean'];
};

export type BankPayoutRequestsForAdminInput = {
  isEnabledAntiFraud: Scalars['Boolean'];
  onlyUnprocessed: Scalars['Boolean'];
  status?: InputMaybe<BankPayoutRequestStatus>;
  timeRangeCondition?: InputMaybe<TimeRangeCondition>;
  userId?: InputMaybe<Scalars['String']>;
};

export type BankPayoutsInput = {
  pagingCondition?: InputMaybe<PagingCondition>;
  timeRangeCondition?: InputMaybe<TimeRangeCondition>;
};

export type BetResultsInput = {
  /** 成績取得範囲 */
  rangeType: BetResultRangeType;
  /** 開始日時 */
  since: Scalars['Time'];
  /** 終了日時 */
  until: Scalars['Time'];
};

export type BetResultsInputByBetType = {
  /** 賭け式リスト */
  betTypeList?: InputMaybe<Array<InputMaybe<BetType>>>;
  /** 開始日時 */
  since: Scalars['Time'];
  /** 終了日時 */
  until: Scalars['Time'];
  /** 競輪場コードリスト */
  velodromes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type DateRangeCondition = {
  /** 開始日 */
  since?: InputMaybe<Scalars['Date']>;
  /** 終了日 */
  until?: InputMaybe<Scalars['Date']>;
};

export type MultiraceProgramInput = {
  heldDate: Scalars['Date'];
  multiraceType: MultiraceType;
  velodromeCode: Scalars['Int'];
};

export type MultiraceProgramsInput = {
  hasShareCampaign?: InputMaybe<Scalars['Boolean']>;
  heldDate?: InputMaybe<Scalars['Date']>;
  heldFirstDate?: InputMaybe<Scalars['Date']>;
  timeRangeCondition?: InputMaybe<TimeRangeCondition>;
  velodromeCode?: InputMaybe<Scalars['Int']>;
};

export type OrdersInput = {
  isOnlyHit?: InputMaybe<Scalars['Boolean']>;
  pagingCondition?: InputMaybe<PagingCondition>;
  raceId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrderStatus>;
  timeRangeCondition?: InputMaybe<TimeRangeCondition>;
  velodromeCode?: InputMaybe<Scalars['Int']>;
};

export type PagingCondition = {
  /** 開始位置 */
  begin?: InputMaybe<Scalars['Int']>;
  /** 件数上限(デフォルト10) */
  limit?: InputMaybe<Scalars['Int']>;
};

export type RaceInput = {
  heldDate?: InputMaybe<Scalars['Date']>;
  raceNum: Scalars['Int'];
  velodromeCode: Scalars['Int'];
};

export type RaceProgramInput = {
  heldDate?: InputMaybe<Scalars['Date']>;
  velodromeCode: Scalars['Int'];
};

export type RaceProgramsInput = {
  heldDate?: InputMaybe<Scalars['Date']>;
  heldFirstDate?: InputMaybe<Scalars['Date']>;
  timeRangeCondition?: InputMaybe<TimeRangeCondition>;
  velodromeCode?: InputMaybe<Scalars['Int']>;
};

export type TimeRangeCondition = {
  /** 開始日時 */
  since?: InputMaybe<Scalars['Time']>;
  /** 終了日時 */
  until?: InputMaybe<Scalars['Time']>;
};

export type WalletStatementsInput = {
  pagingCondition?: InputMaybe<PagingCondition>;
  timeRangeCondition?: InputMaybe<TimeRangeCondition>;
};

export type CreateUserMutationVariables = Exact<{
  phoneNo: Scalars['String'];
  isMailSubscribe: Scalars['Boolean'];
  inviter?: InputMaybe<Scalars['String']>;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'User';
    userId: string;
    charilotoUserId?: string | null;
    verificationStatus?: VerificationStatus | null;
    verificator?: Verificator | null;
    verified?: dayjs.Dayjs | null;
    status?: UserStatus | null;
    personalInfo: {
      __typename?: 'PersonalInfo';
      lastName?: string | null;
      firstName?: string | null;
      lastnameRuby?: string | null;
      firstnameRuby?: string | null;
      birthDate?: dayjs.Dayjs | null;
    };
  };
};

export type OrderSingleRaceMutationVariables = Exact<{
  raceId: Scalars['String'];
  items: Array<ItemInput> | ItemInput;
}>;

export type OrderSingleRaceMutation = {
  __typename?: 'Mutation';
  orderSingleRace: {
    __typename?: 'OrderSingleRace';
    id: string;
    userId: string;
    raceId: string;
    tpOrderId?: number | null;
    status: OrderStatus;
    failureReason: OrderFailureReason;
    totalAmount: number;
    emoneyTransactionId?: string | null;
    details: Array<{
      __typename?: 'OrderSingleRaceDetail';
      orderSingleRaceId: string;
      betType: BetType;
      numbers: Array<number>;
      count: number;
      amount: number;
    }>;
  };
};

export type OrderMultiraceRandomMutationVariables = Exact<{
  input: OrderMultiraceRandomInput;
}>;

export type OrderMultiraceRandomMutation = {
  __typename?: 'Mutation';
  orderMultiraceRandom: {
    __typename?: 'OrderMultirace';
    id: string;
    totalAmount: number;
    status: OrderStatus;
    emoneyTransactionId?: string | null;
    details: Array<{
      __typename?: 'OrderMultiraceDetail';
      orderMultiraceId: string;
      numbers: Array<number>;
      count: number;
      amount: number;
    }>;
  };
};

export type OrderMultiraceSelectMutationVariables = Exact<{
  input: OrderMultiraceSelectInput;
}>;

export type OrderMultiraceSelectMutation = {
  __typename?: 'Mutation';
  orderMultiraceSelect: {
    __typename?: 'OrderMultirace';
    id: string;
    totalAmount: number;
    status: OrderStatus;
    emoneyTransactionId?: string | null;
    details: Array<{
      __typename?: 'OrderMultiraceDetail';
      orderMultiraceId: string;
      numbers: Array<number>;
      count: number;
      amount: number;
    }>;
  };
};

export type IssueMultiraceRandomTicketsMutationVariables = Exact<{
  multiraceProgramId: Scalars['Int'];
  betCount: Scalars['Int'];
}>;

export type IssueMultiraceRandomTicketsMutation = {
  __typename?: 'Mutation';
  issueMultiraceRandomTickets: {
    __typename?: 'MultiraceRandomTickets';
    remainingPreviewsCount: number;
    betCount: number;
    items: Array<Array<number>>;
  };
};

export type RequestPayeasySettlementMutationVariables = Exact<{
  deviceType: TransferDeviceType;
  bankCode: Scalars['String'];
  amount: Scalars['Int'];
}>;

export type RequestPayeasySettlementMutation = {
  __typename?: 'Mutation';
  requestPayeasySettlement: {
    __typename?: 'PayeasyStatement';
    walletStatementId: string;
    settlementType: TransferDeviceType;
    bankCode: string;
    bankURL?: string | null;
    receivePaymentCode?: string | null;
    patternCode?: string | null;
    encryptInfo?: string | null;
    encryptedAccessCode?: string | null;
    customerCode: string;
    dueDate: dayjs.Dayjs;
    created: dayjs.Dayjs;
  };
};

export type SaveBankAccountMutationVariables = Exact<{
  bankID: Scalars['String'];
  bankBranchID: Scalars['String'];
  accountType: BankAccountType;
  accountNumber: Scalars['String'];
}>;

export type SaveBankAccountMutation = {
  __typename?: 'Mutation';
  saveBankAccount: {
    __typename?: 'BankAccount';
    id: string;
    accountHolder?: string | null;
    accountHolderRuby?: string | null;
    bankId?: string | null;
    bankName?: string | null;
    bankBranchId: string;
    bankBranchName: string;
    accountType?: BankAccountType | null;
    accountNumber?: string | null;
  };
};

export type ChargeToretaFromCreditMutationVariables = Exact<{
  amount: Scalars['Int'];
}>;

export type ChargeToretaFromCreditMutation = {
  __typename?: 'Mutation';
  chargeToretaFromCredit: {
    __typename?: 'CreditCard3dAuthResponse';
    result: CreditCard3dAuthStatus;
    acsURL: string;
    paReq: string;
    md: string;
    termUrl: string;
    cc3dLogId: string;
  };
};

export type ChargeToretaFromCreditWithValidateOrderMutationVariables = Exact<{
  amount: Scalars['Int'];
  orderAmount: Scalars['Int'];
}>;

export type ChargeToretaFromCreditWithValidateOrderMutation = {
  __typename?: 'Mutation';
  chargeToretaFromCreditWithValidateOrder: {
    __typename?: 'CreditCard3dAuthResponse';
    result: CreditCard3dAuthStatus;
    acsURL: string;
    paReq: string;
    md: string;
    termUrl: string;
    cc3dLogId: string;
  };
};

export type ChargeToretaFromCreditV2MutationVariables = Exact<{
  amount: Scalars['Int'];
}>;

export type ChargeToretaFromCreditV2Mutation = {
  __typename?: 'Mutation';
  chargeToretaFromCreditV2: {
    __typename?: 'CreditCard3d2AuthResponse';
    iframeUrl: string;
    md: string;
    paReq?: string | null;
    result: CreditCard3dAuthStatus;
    threeDSMethod: number;
    threeDSOrderID: string;
    merchant: string;
  };
};

export type ChargeToretaFromCreditV2WithValidateOrderMutationVariables = Exact<{
  amount: Scalars['Int'];
  orderAmount: Scalars['Int'];
}>;

export type ChargeToretaFromCreditV2WithValidateOrderMutation = {
  __typename?: 'Mutation';
  chargeToretaFromCreditV2WithValidateOrder: {
    __typename?: 'CreditCard3d2AuthResponse';
    iframeUrl: string;
    md: string;
    paReq?: string | null;
    result: CreditCard3dAuthStatus;
    threeDSMethod: number;
    threeDSOrderID: string;
    merchant: string;
  };
};

export type ChargeToretaFromWalletMutationVariables = Exact<{
  amount: Scalars['Int'];
}>;

export type ChargeToretaFromWalletMutation = {
  __typename?: 'Mutation';
  chargeToretaFromWallet: {
    __typename?: 'ChargeToretaFromWallet';
    status: ChargeToretaFromWalletStatus;
  };
};

export type ChargeToretaFromWalletWithValidateOrderMutationVariables = Exact<{
  amount: Scalars['Int'];
  orderAmount: Scalars['Int'];
}>;

export type ChargeToretaFromWalletWithValidateOrderMutation = {
  __typename?: 'Mutation';
  chargeToretaFromWalletWithValidateOrder: {
    __typename?: 'ChargeToretaFromWallet';
    status: ChargeToretaFromWalletStatus;
  };
};

export type RequestPayoutMutationVariables = Exact<{
  amount: Scalars['Int'];
}>;

export type RequestPayoutMutation = {
  __typename?: 'Mutation';
  requestPayout: {
    __typename?: 'WalletStatement';
    status: WalletStatementStatus;
  };
};

export type SaveEntryRacerNoteMutationVariables = Exact<{
  raceID: Scalars['String'];
  registrationNum: Scalars['Int'];
  body: Scalars['String'];
}>;

export type SaveEntryRacerNoteMutation = {
  __typename?: 'Mutation';
  saveEntryRacerNote: {
    __typename?: 'EntryRacerNote';
    registrationNum: number;
    raceId: string;
    userId: string;
    velodromeCode: number;
    raceNum: number;
    heldDate: dayjs.Dayjs;
    body: string;
    race: { __typename?: 'Race'; raceClassName: string; raceEventName: string };
  };
};

export type DeleteEntryRacerNoteMutationVariables = Exact<{
  raceID: Scalars['String'];
  registrationNum: Scalars['Int'];
}>;

export type DeleteEntryRacerNoteMutation = {
  __typename?: 'Mutation';
  deleteEntryRacerNote: {
    __typename?: 'EntryRacerNote';
    registrationNum: number;
    raceId: string;
    userId: string;
    velodromeCode: number;
    raceNum: number;
    heldDate: dayjs.Dayjs;
    body: string;
  };
};

export type UpdateMailReceiveOptionMutationVariables = Exact<{
  input: UpdateMailReceiveOptionInput;
}>;

export type UpdateMailReceiveOptionMutation = {
  __typename?: 'Mutation';
  updateMailReceiveOption: {
    __typename?: 'MailReceiveOption';
    atVoteCompleted: boolean;
    atVotedRaceStarted: boolean;
    atVotedRaceResultConfirmed: boolean;
  };
};

export type RegisterEntryCampaignMutationVariables = Exact<{
  entryCampaignId: Scalars['String'];
}>;

export type RegisterEntryCampaignMutation = {
  __typename?: 'Mutation';
  registerEntryCampaign: {
    __typename?: 'EntryCampaignUser';
    entryCampaignId: string;
  };
};

export type SaveAddressMutationVariables = Exact<{
  input: SaveAddressInput;
}>;

export type SaveAddressMutation = {
  __typename?: 'Mutation';
  saveAddress: string;
};

export type SaveDailyOrderLimitMutationVariables = Exact<{
  amount: Scalars['Int'];
}>;

export type SaveDailyOrderLimitMutation = {
  __typename?: 'Mutation';
  saveDailyOrderLimit: {
    __typename?: 'DailyOrderLimit';
    userId: string;
    amount: number;
    updated: dayjs.Dayjs;
  };
};

export type DeleteDailyOrderLimitMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteDailyOrderLimitMutation = {
  __typename?: 'Mutation';
  deleteDailyOrderLimit: { __typename?: 'DailyOrderLimit'; userId: string };
};

export type CheckOneTimePassMutationVariables = Exact<{
  inputMode: OtpAddress;
  inputCode: Scalars['String'];
}>;

export type CheckOneTimePassMutation = {
  __typename?: 'Mutation';
  checkOneTimePass: { __typename?: 'OtpCheckResult'; isPermission: boolean };
};

export type SavePaypayAccountMutationVariables = Exact<{
  bankID: Scalars['String'];
  bankBranchID: Scalars['String'];
  accountType: BankAccountType;
  accountNumber: Scalars['String'];
}>;

export type SavePaypayAccountMutation = {
  __typename?: 'Mutation';
  savePaypayAccount: {
    __typename?: 'BankAccount';
    id: string;
    accountHolder?: string | null;
    accountHolderRuby?: string | null;
    bankId?: string | null;
    bankName?: string | null;
    bankBranchId: string;
    bankBranchName: string;
    accountType?: BankAccountType | null;
    accountNumber?: string | null;
  };
};

export type DeleteTransferAccountMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteTransferAccountMutation = {
  __typename?: 'Mutation';
  deleteTransferAccount: {
    __typename?: 'DeleteTransferResponse';
    result: DeleteTransferStatus;
  };
};

export type RequestTransferMutationVariables = Exact<{
  amount: Scalars['Int'];
}>;

export type RequestTransferMutation = {
  __typename?: 'Mutation';
  requestTransfer: {
    __typename?: 'WalletStatement';
    status: WalletStatementStatus;
  };
};

export type PreGmoAccountMutationVariables = Exact<{
  bankCode: Scalars['String'];
}>;

export type PreGmoAccountMutation = {
  __typename?: 'Mutation';
  preGmoAccount: {
    __typename?: 'GmoTransaction';
    tranID: string;
    token: string;
    startUrl: string;
    bankName: string;
  };
};

export type SingleRaceReleaseInfoFragment = {
  __typename?: 'SingleRaceReleaseInfo';
  bracketQuinellaReleaseStatus: ReleaseStatus;
  bracketPerfectaReleaseStatus: ReleaseStatus;
  quinellaReleaseStatus: ReleaseStatus;
  perfectaReleaseStatus: ReleaseStatus;
  quinellaPlaceReleaseStatus: ReleaseStatus;
  trioReleaseStatus: ReleaseStatus;
  trifectaReleaseStatus: ReleaseStatus;
};

export type SingleRaceRefundFieldFragment = {
  __typename?: 'SingleRaceRefund';
  betType: BetType;
  betStatus: BetStatus;
  hitNum1?: number | null;
  hitNum2?: number | null;
  hitNum3?: number | null;
  refund: number;
  hitPlaceRank?: number | null;
};

export type EntryRacerScoreDetailFragment = {
  __typename?: 'EntryRacerScoreDetail';
  velodromeCode: number;
  heldFirstDate: dayjs.Dayjs;
  grade: Grade;
  scoreList: string;
};

export type EntryRacerScoreFieldFragment = {
  __typename?: 'EntryRacerScore';
  prize: number;
  rank: number;
  last_3raceScoreInfoCount: number;
  averageRaceScore: number;
  winningAverage: number;
  top2Ratio: number;
  top3Ratio: number;
  pacesetterCount: number;
  lookerCount: number;
  comeFromBehindCount: number;
  markCount: number;
  standingCount: number;
  backCount: number;
  topCount: number;
  rank2Count: number;
  rank3Count: number;
  unplacedCount: number;
  totalWinningCount: number;
  winningStreakCount: number;
};

export type EntryRacerFieldFragment = {
  __typename?: 'EntryRacer';
  bicycleNum: number;
  bracketNum: number;
  registrationNum: number;
  name: string;
  shortName: string;
  age: number;
  gradeName: string;
  unitName: string;
  prefecture: string;
  prefectureShort: string;
  graduated: string;
  gearRatio: string;
  gearRatioBefore?: string | null;
  racingStyle: string;
  arrivalOrder: number;
  marginInfo?: string | null;
  finalStagePlayerTime?: number | null;
  decidingFactor?: string | null;
  isStandingGet?: boolean | null;
  isJanGet?: boolean | null;
  isHomeGet?: boolean | null;
  isBackGet?: boolean | null;
  playerStatus?: string | null;
  releaseStatus: ReleaseStatus;
  updateNotificationNum: number;
  forecastMark?: ForecastMark | null;
};

export type RaceFieldFragment = {
  __typename?: 'Race';
  id: string;
  raceProgramId: string;
  raceNum: number;
  bicycleCount: number;
  raceClassName: string;
  raceEventName: string;
  startTime: dayjs.Dayjs;
  orderDeadline: dayjs.Dayjs;
  raceSuspendReasonCode: number;
  finalStageRaceTime: number;
  distance: number;
  lapCount: number;
  finalLapTime: number;
  beforeFinalLapTime: number;
  existsEntryRacerInfo: boolean;
  entryRacerInfoCount: number;
  raceEntryStatus: RaceEntryStatus;
  isCanceled: boolean;
  lineForecast?: Array<Array<number | null>> | null;
};

export type RaceProgramFieldFragment = {
  __typename?: 'RaceProgram';
  id: string;
  velodromeCode: number;
  heldDate: dayjs.Dayjs;
  promoterCode: number;
  heldNum: number;
  heldPart: number;
  heldFirstDate: dayjs.Dayjs;
  programName: string;
  programSubName: string;
  gradeName: string;
  dailyNum: number;
  raceCount: number;
  timeFrame: TimeFrame;
  isGirls: boolean;
  isLastDay: boolean;
  isCanceled: boolean;
  isSalesSuspended: boolean;
};

export type MultiraceProgramFieldFragment = {
  __typename?: 'MultiraceProgram';
  id: number;
  raceProgramId: string;
  velodromeCode: number;
  enforcerCode: number;
  groupCode: number;
  heldFirstDate: dayjs.Dayjs;
  heldDate: dayjs.Dayjs;
  programName: string;
  dokantoHeldNum: number;
  dokantoPromoterCode: number;
  releaseDate: dayjs.Dayjs;
  salesDeadline: dayjs.Dayjs;
  raceCount: number;
  targetRaceNumbers: Array<number>;
  targetRaceHitBetCount: Array<number>;
  refundAmount: number;
  isRefund: boolean;
  multiraceType: MultiraceType;
  unitOfRelease: number;
  releaseBetCount: number;
  hitBetCount: number;
  returnBetCount: number;
  carryoverPrice: number;
  previousCarryoverPrice: number;
  shareCampaignAmount: number;
  isCanceled: boolean;
  releaseStatus: MultiraceReleaseStatus;
};

export type OrderSingleRaceFieldFragment = {
  __typename?: 'OrderSingleRace';
  id: string;
  raceId: string;
  status: OrderStatus;
  failureReason: OrderFailureReason;
  totalAmount: number;
  totalRefund: number;
  returnAmount: number;
  refundStatus: OrderRefundStatus;
  created: dayjs.Dayjs;
  tpOrderId?: number | null;
};

export type OrderSingleRaceDetailFieldFragment = {
  __typename?: 'OrderSingleRaceDetail';
  orderSingleRaceId: string;
  betType: BetType;
  numbers: Array<number>;
  count: number;
  amount: number;
  status: OrderDetailStatus;
  refund: number;
};

export type OrderMultiraceFieldFragment = {
  __typename?: 'OrderMultirace';
  id: string;
  multiraceProgramId: number;
  status: OrderStatus;
  failureReason: OrderFailureReason;
  totalAmount: number;
  totalRefund: number;
  returnAmount: number;
  refundStatus: OrderRefundStatus;
  created: dayjs.Dayjs;
  tpOrderId?: number | null;
};

export type OrderMultiraceDetailFieldFragment = {
  __typename?: 'OrderMultiraceDetail';
  orderMultiraceId: string;
  numbers: Array<number>;
  count: number;
  amount: number;
  status: OrderDetailStatus;
  refund: number;
};

export type OrderMultiracesResultFieldFragment = {
  __typename?: 'OrderMultiracesResult';
  count: number;
};

export type EntryRacerNoteFieldFragment = {
  __typename?: 'EntryRacerNote';
  registrationNum: number;
  raceId: string;
  userId: string;
  velodromeCode: number;
  raceNum: number;
  heldDate: dayjs.Dayjs;
  body: string;
};

export type EntryRacerScoreDetailOwnFieldFragment = {
  __typename?: 'EntryRacerScoreDetailOwn';
  registrationNum: number;
  heldFirstDate: dayjs.Dayjs;
  velodromeCode: number;
  gradeName: string;
  scoreCsv1: string;
  scoreCsv2: string;
  scoreCsv3: string;
  scoreCsv4: string;
  scoreCsv5: string;
  scoreCsv6: string;
  scoreCsv7: string;
};

export type EntryRacerNoteCountFieldFragment = {
  __typename?: 'EntryRacerNoteCount';
  raceID: string;
  count: number;
};

export type SingleRaceBetResultFieldFragment = {
  __typename?: 'SingleRaceBetResult';
  count: number;
  orderedDaysCount: number;
  orderedRaceCount: number;
  hittedRaceCount: number;
  hitRate: number;
  totalAmount: number;
  totalRefundAmount: number;
  balance: number;
  retrieveRate: number;
  maxTotalRefund: number;
  maxHitOdds: number;
  over10000YenRefundCount: number;
};

export type SingleRaceBetResultItemFieldFragment = {
  __typename?: 'SingleRaceBetResultItem';
  targetDate: dayjs.Dayjs;
  count: number;
  orderedDaysCount: number;
  orderedRaceCount: number;
  hittedRaceCount: number;
  hitRate: number;
  totalAmount: number;
  totalRefundAmount: number;
  balance: number;
  retrieveRate: number;
  maxTotalRefund: number;
  maxHitOdds: number;
  over10000YenRefundCount: number;
};

export type AddressFieldFragment = {
  __typename?: 'Address';
  addressId: string;
  addressLabel: string;
  countryCode: string;
  postalCode: string;
  region: string;
  prefectureCode: string;
  locality: string;
  streetAddress: string;
  building: string;
  addressee: string;
  givenName: string;
  familyName: string;
  givenNameRuby: string;
  familyNameRuby: string;
  middleName: string;
  formatted: string;
  mailingEmail: string;
  mailingPhoneNumber: string;
  isDeleted: boolean;
  createdAt: dayjs.Dayjs;
};

export type GetCallbackUrlQueryVariables = Exact<{
  callbackUrl: Scalars['String'];
}>;

export type GetCallbackUrlQuery = { __typename?: 'Query'; callbackUrl: string };

export type GetPaypayAccountIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetPaypayAccountIdQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    userId: string;
    paypayAccountID?: string | null;
    paypayAccountSetting?: dayjs.Dayjs | null;
  };
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    userId: string;
    charilotoUserId?: string | null;
    verificationStatus?: VerificationStatus | null;
    verificator?: Verificator | null;
    verified?: dayjs.Dayjs | null;
    status?: UserStatus | null;
    paypayAccountID?: string | null;
    paypayAccountSetting?: dayjs.Dayjs | null;
    dailyOrderLimit?: {
      __typename?: 'DailyOrderLimit';
      amount: number;
      updated: dayjs.Dayjs;
    } | null;
    address?: {
      __typename?: 'Address';
      addressId: string;
      addressLabel: string;
      countryCode: string;
      postalCode: string;
      region: string;
      prefectureCode: string;
      locality: string;
      streetAddress: string;
      building: string;
      addressee: string;
      givenName: string;
      familyName: string;
      givenNameRuby: string;
      familyNameRuby: string;
      middleName: string;
      formatted: string;
      mailingEmail: string;
      mailingPhoneNumber: string;
      isDeleted: boolean;
      createdAt: dayjs.Dayjs;
    } | null;
    wallet?: {
      __typename?: 'Wallet';
      balance: number;
      pendingBalance: number;
      genericCodeWs: number;
      toreta: {
        __typename?: 'Toreta';
        balance: number;
        limitedBalance: number;
      };
    } | null;
    mailReceiveOption?: {
      __typename?: 'MailReceiveOption';
      atVoteCompleted: boolean;
      atVotedRaceResultConfirmed: boolean;
      atVotedRaceStarted: boolean;
    } | null;
    gmoAccountInfo?: {
      __typename?: 'GmoAccountInfo';
      bankCode: string;
      bankName: string;
      status: GmoAccountStatus;
      updated: dayjs.Dayjs;
    } | null;
    chargebackAppleal?: {
      __typename?: 'ApplealCampaignInfo';
      campaignID: string;
      isEntried: boolean;
      endTime: dayjs.Dayjs;
    } | null;
  };
};

export type GetAddressQueryVariables = Exact<{ [key: string]: never }>;

export type GetAddressQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    userId: string;
    address?: {
      __typename?: 'Address';
      addressId: string;
      addressLabel: string;
      countryCode: string;
      postalCode: string;
      region: string;
      prefectureCode: string;
      locality: string;
      streetAddress: string;
      building: string;
      addressee: string;
      givenName: string;
      familyName: string;
      givenNameRuby: string;
      familyNameRuby: string;
      middleName: string;
      formatted: string;
      mailingEmail: string;
      mailingPhoneNumber: string;
      isDeleted: boolean;
      createdAt: dayjs.Dayjs;
    } | null;
  };
};

export type GetUserWalletQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserWalletQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    userId: string;
    wallet?: {
      __typename?: 'Wallet';
      balance: number;
      pendingBalance: number;
      updated: dayjs.Dayjs;
      toreta: {
        __typename?: 'Toreta';
        balance: number;
        limitedBalance: number;
      };
    } | null;
  };
};

export type GetUserPersonalInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserPersonalInfoQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    userId: string;
    personalInfo: {
      __typename?: 'PersonalInfo';
      lastName?: string | null;
      firstName?: string | null;
      lastnameRuby?: string | null;
      firstnameRuby?: string | null;
      birthDate?: dayjs.Dayjs | null;
      phoneNo?: string | null;
    };
  };
};

export type GetUserBankAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserBankAccountQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    userId: string;
    bankAccount?: {
      __typename?: 'BankAccount';
      id: string;
      accountHolder?: string | null;
      accountHolderRuby?: string | null;
      bankId?: string | null;
      bankName?: string | null;
      bankBranchId: string;
      bankBranchName: string;
      accountType?: BankAccountType | null;
      accountNumber?: string | null;
    } | null;
  };
};

export type GetRecentRaceProgramsQueryVariables = Exact<{
  prev: RaceProgramsInput;
  today: RaceProgramsInput;
  next: RaceProgramsInput;
}>;

export type GetRecentRaceProgramsQuery = {
  __typename?: 'Query';
  previousDay: Array<{
    __typename?: 'RaceProgram';
    id: string;
    velodromeCode: number;
    heldDate: dayjs.Dayjs;
    promoterCode: number;
    heldNum: number;
    heldPart: number;
    heldFirstDate: dayjs.Dayjs;
    programName: string;
    programSubName: string;
    gradeName: string;
    dailyNum: number;
    raceCount: number;
    timeFrame: TimeFrame;
    isGirls: boolean;
    isLastDay: boolean;
    isCanceled: boolean;
    isSalesSuspended: boolean;
    races: Array<{
      __typename?: 'Race';
      id: string;
      raceProgramId: string;
      raceNum: number;
      bicycleCount: number;
      raceClassName: string;
      raceEventName: string;
      startTime: dayjs.Dayjs;
      orderDeadline: dayjs.Dayjs;
      raceSuspendReasonCode: number;
      finalStageRaceTime: number;
      distance: number;
      lapCount: number;
      finalLapTime: number;
      beforeFinalLapTime: number;
      existsEntryRacerInfo: boolean;
      entryRacerInfoCount: number;
      raceEntryStatus: RaceEntryStatus;
      isCanceled: boolean;
      lineForecast?: Array<Array<number | null>> | null;
    }>;
  }>;
  today: Array<{
    __typename?: 'RaceProgram';
    id: string;
    velodromeCode: number;
    heldDate: dayjs.Dayjs;
    promoterCode: number;
    heldNum: number;
    heldPart: number;
    heldFirstDate: dayjs.Dayjs;
    programName: string;
    programSubName: string;
    gradeName: string;
    dailyNum: number;
    raceCount: number;
    timeFrame: TimeFrame;
    isGirls: boolean;
    isLastDay: boolean;
    isCanceled: boolean;
    isSalesSuspended: boolean;
    races: Array<{
      __typename?: 'Race';
      id: string;
      raceProgramId: string;
      raceNum: number;
      bicycleCount: number;
      raceClassName: string;
      raceEventName: string;
      startTime: dayjs.Dayjs;
      orderDeadline: dayjs.Dayjs;
      raceSuspendReasonCode: number;
      finalStageRaceTime: number;
      distance: number;
      lapCount: number;
      finalLapTime: number;
      beforeFinalLapTime: number;
      existsEntryRacerInfo: boolean;
      entryRacerInfoCount: number;
      raceEntryStatus: RaceEntryStatus;
      isCanceled: boolean;
      lineForecast?: Array<Array<number | null>> | null;
    }>;
  }>;
  nextDay: Array<{
    __typename?: 'RaceProgram';
    id: string;
    velodromeCode: number;
    heldDate: dayjs.Dayjs;
    promoterCode: number;
    heldNum: number;
    heldPart: number;
    heldFirstDate: dayjs.Dayjs;
    programName: string;
    programSubName: string;
    gradeName: string;
    dailyNum: number;
    raceCount: number;
    timeFrame: TimeFrame;
    isGirls: boolean;
    isLastDay: boolean;
    isCanceled: boolean;
    isSalesSuspended: boolean;
    races: Array<{
      __typename?: 'Race';
      id: string;
      raceProgramId: string;
      raceNum: number;
      bicycleCount: number;
      raceClassName: string;
      raceEventName: string;
      startTime: dayjs.Dayjs;
      orderDeadline: dayjs.Dayjs;
      raceSuspendReasonCode: number;
      finalStageRaceTime: number;
      distance: number;
      lapCount: number;
      finalLapTime: number;
      beforeFinalLapTime: number;
      existsEntryRacerInfo: boolean;
      entryRacerInfoCount: number;
      raceEntryStatus: RaceEntryStatus;
      isCanceled: boolean;
      lineForecast?: Array<Array<number | null>> | null;
    }>;
  }>;
};

export type GetRaceProgramsQueryVariables = Exact<{
  input: RaceProgramsInput;
}>;

export type GetRaceProgramsQuery = {
  __typename?: 'Query';
  racePrograms: Array<{
    __typename?: 'RaceProgram';
    id: string;
    velodromeCode: number;
    heldDate: dayjs.Dayjs;
    promoterCode: number;
    heldNum: number;
    heldPart: number;
    heldFirstDate: dayjs.Dayjs;
    programName: string;
    programSubName: string;
    gradeName: string;
    dailyNum: number;
    raceCount: number;
    timeFrame: TimeFrame;
    isGirls: boolean;
    isLastDay: boolean;
    isCanceled: boolean;
    isSalesSuspended: boolean;
    races: Array<{
      __typename?: 'Race';
      id: string;
      raceProgramId: string;
      raceNum: number;
      bicycleCount: number;
      raceClassName: string;
      raceEventName: string;
      startTime: dayjs.Dayjs;
      orderDeadline: dayjs.Dayjs;
      raceSuspendReasonCode: number;
      finalStageRaceTime: number;
      distance: number;
      lapCount: number;
      finalLapTime: number;
      beforeFinalLapTime: number;
      existsEntryRacerInfo: boolean;
      entryRacerInfoCount: number;
      raceEntryStatus: RaceEntryStatus;
      isCanceled: boolean;
      lineForecast?: Array<Array<number | null>> | null;
      singleRaceRefunds: Array<{
        __typename?: 'SingleRaceRefund';
        betType: BetType;
        betStatus: BetStatus;
        hitNum1?: number | null;
        hitNum2?: number | null;
        hitNum3?: number | null;
        refund: number;
        hitPlaceRank?: number | null;
      }>;
    }>;
  }>;
};

export type GetRaceProgramQueryVariables = Exact<{
  input: RaceProgramInput;
}>;

export type GetRaceProgramQuery = {
  __typename?: 'Query';
  raceProgram: {
    __typename?: 'RaceProgram';
    id: string;
    velodromeCode: number;
    heldDate: dayjs.Dayjs;
    promoterCode: number;
    heldNum: number;
    heldPart: number;
    heldFirstDate: dayjs.Dayjs;
    programName: string;
    programSubName: string;
    gradeName: string;
    dailyNum: number;
    raceCount: number;
    timeFrame: TimeFrame;
    isGirls: boolean;
    isLastDay: boolean;
    isCanceled: boolean;
    isSalesSuspended: boolean;
    races: Array<{
      __typename?: 'Race';
      id: string;
      raceProgramId: string;
      raceNum: number;
      bicycleCount: number;
      raceClassName: string;
      raceEventName: string;
      startTime: dayjs.Dayjs;
      orderDeadline: dayjs.Dayjs;
      raceSuspendReasonCode: number;
      finalStageRaceTime: number;
      distance: number;
      lapCount: number;
      finalLapTime: number;
      beforeFinalLapTime: number;
      existsEntryRacerInfo: boolean;
      entryRacerInfoCount: number;
      raceEntryStatus: RaceEntryStatus;
      isCanceled: boolean;
      lineForecast?: Array<Array<number | null>> | null;
      entryRacers: Array<{
        __typename?: 'EntryRacer';
        bicycleNum: number;
        bracketNum: number;
        registrationNum: number;
        name: string;
        shortName: string;
        age: number;
        gradeName: string;
        unitName: string;
        prefecture: string;
        prefectureShort: string;
        graduated: string;
        gearRatio: string;
        gearRatioBefore?: string | null;
        racingStyle: string;
        arrivalOrder: number;
        marginInfo?: string | null;
        finalStagePlayerTime?: number | null;
        decidingFactor?: string | null;
        isStandingGet?: boolean | null;
        isJanGet?: boolean | null;
        isHomeGet?: boolean | null;
        isBackGet?: boolean | null;
        playerStatus?: string | null;
        releaseStatus: ReleaseStatus;
        updateNotificationNum: number;
        forecastMark?: ForecastMark | null;
        entryRacerScore: {
          __typename?: 'EntryRacerScore';
          prize: number;
          rank: number;
          last_3raceScoreInfoCount: number;
          averageRaceScore: number;
          winningAverage: number;
          top2Ratio: number;
          top3Ratio: number;
          pacesetterCount: number;
          lookerCount: number;
          comeFromBehindCount: number;
          markCount: number;
          standingCount: number;
          backCount: number;
          topCount: number;
          rank2Count: number;
          rank3Count: number;
          unplacedCount: number;
          totalWinningCount: number;
          winningStreakCount: number;
          entryRacerScoreDetails: Array<{
            __typename?: 'EntryRacerScoreDetail';
            velodromeCode: number;
            heldFirstDate: dayjs.Dayjs;
            grade: Grade;
            scoreList: string;
          }>;
        };
      }>;
      singleRaceRefunds: Array<{
        __typename?: 'SingleRaceRefund';
        betType: BetType;
        betStatus: BetStatus;
        hitNum1?: number | null;
        hitNum2?: number | null;
        hitNum3?: number | null;
        refund: number;
        hitPlaceRank?: number | null;
      }>;
    }>;
  };
};

export type GetSingleraceResultQueryVariables = Exact<{
  input: RaceProgramInput;
}>;

export type GetSingleraceResultQuery = {
  __typename?: 'Query';
  raceProgram: {
    __typename?: 'RaceProgram';
    id: string;
    velodromeCode: number;
    heldDate: dayjs.Dayjs;
    promoterCode: number;
    heldNum: number;
    heldPart: number;
    heldFirstDate: dayjs.Dayjs;
    programName: string;
    programSubName: string;
    gradeName: string;
    dailyNum: number;
    raceCount: number;
    timeFrame: TimeFrame;
    isGirls: boolean;
    isLastDay: boolean;
    isCanceled: boolean;
    isSalesSuspended: boolean;
    races: Array<{
      __typename?: 'Race';
      id: string;
      raceProgramId: string;
      raceNum: number;
      bicycleCount: number;
      raceClassName: string;
      raceEventName: string;
      startTime: dayjs.Dayjs;
      orderDeadline: dayjs.Dayjs;
      raceSuspendReasonCode: number;
      finalStageRaceTime: number;
      distance: number;
      lapCount: number;
      finalLapTime: number;
      beforeFinalLapTime: number;
      existsEntryRacerInfo: boolean;
      entryRacerInfoCount: number;
      raceEntryStatus: RaceEntryStatus;
      isCanceled: boolean;
      lineForecast?: Array<Array<number | null>> | null;
      entryRacers: Array<{
        __typename?: 'EntryRacer';
        bicycleNum: number;
        bracketNum: number;
        registrationNum: number;
        name: string;
        shortName: string;
        age: number;
        gradeName: string;
        unitName: string;
        prefecture: string;
        prefectureShort: string;
        graduated: string;
        gearRatio: string;
        gearRatioBefore?: string | null;
        racingStyle: string;
        arrivalOrder: number;
        marginInfo?: string | null;
        finalStagePlayerTime?: number | null;
        decidingFactor?: string | null;
        isStandingGet?: boolean | null;
        isJanGet?: boolean | null;
        isHomeGet?: boolean | null;
        isBackGet?: boolean | null;
        playerStatus?: string | null;
        releaseStatus: ReleaseStatus;
        updateNotificationNum: number;
        forecastMark?: ForecastMark | null;
      }>;
      singleRaceRefunds: Array<{
        __typename?: 'SingleRaceRefund';
        betType: BetType;
        betStatus: BetStatus;
        hitNum1?: number | null;
        hitNum2?: number | null;
        hitNum3?: number | null;
        refund: number;
        hitPlaceRank?: number | null;
      }>;
      odds?: { __typename?: 'Odds'; oddsInfo: string } | null;
    }>;
  };
};

export type GetMultiraceResultQueryVariables = Exact<{
  raceProgramInput: RaceProgramInput;
  multiraceProgramsInput: MultiraceProgramsInput;
}>;

export type GetMultiraceResultQuery = {
  __typename?: 'Query';
  raceProgram: {
    __typename?: 'RaceProgram';
    id: string;
    velodromeCode: number;
    heldDate: dayjs.Dayjs;
    promoterCode: number;
    heldNum: number;
    heldPart: number;
    heldFirstDate: dayjs.Dayjs;
    programName: string;
    programSubName: string;
    gradeName: string;
    dailyNum: number;
    raceCount: number;
    timeFrame: TimeFrame;
    isGirls: boolean;
    isLastDay: boolean;
    isCanceled: boolean;
    isSalesSuspended: boolean;
  };
  multiracePrograms: Array<{
    __typename?: 'MultiraceProgram';
    id: number;
    raceProgramId: string;
    velodromeCode: number;
    enforcerCode: number;
    groupCode: number;
    heldFirstDate: dayjs.Dayjs;
    heldDate: dayjs.Dayjs;
    programName: string;
    dokantoHeldNum: number;
    dokantoPromoterCode: number;
    releaseDate: dayjs.Dayjs;
    salesDeadline: dayjs.Dayjs;
    raceCount: number;
    targetRaceNumbers: Array<number>;
    targetRaceHitBetCount: Array<number>;
    refundAmount: number;
    isRefund: boolean;
    multiraceType: MultiraceType;
    unitOfRelease: number;
    releaseBetCount: number;
    hitBetCount: number;
    returnBetCount: number;
    carryoverPrice: number;
    previousCarryoverPrice: number;
    shareCampaignAmount: number;
    isCanceled: boolean;
    releaseStatus: MultiraceReleaseStatus;
    multiraceRefunds: Array<{
      __typename?: 'MultiraceRefund';
      amount: number;
      betStatus: BetStatus;
      hitNumbers: Array<number>;
      hitCount: number;
    }>;
    singleRaceRefundsByRaceId: Array<{
      __typename?: 'SingleRaceRefundsByRaceId';
      raceId: string;
      singleRaceRefunds: Array<{
        __typename?: 'SingleRaceRefund';
        betType: BetType;
        betStatus: BetStatus;
        hitNum1?: number | null;
        hitNum2?: number | null;
        hitNum3?: number | null;
        refund: number;
        hitPlaceRank?: number | null;
      }>;
    }>;
    raceProgram: {
      __typename?: 'RaceProgram';
      races: Array<{
        __typename?: 'Race';
        id: string;
        raceNum: number;
        isCanceled: boolean;
        raceEntryStatus: RaceEntryStatus;
        entryRacers: Array<{
          __typename?: 'EntryRacer';
          id: string;
          bicycleNum: number;
          arrivalOrder: number;
          name: string;
          updateNotificationNum: number;
        }>;
      }>;
    };
  } | null>;
};

export type GetRaceQueryVariables = Exact<{
  raceInput: RaceInput;
  raceProgramInput: RaceProgramInput;
}>;

export type GetRaceQuery = {
  __typename?: 'Query';
  raceProgram: {
    __typename?: 'RaceProgram';
    id: string;
    velodromeCode: number;
    heldDate: dayjs.Dayjs;
    promoterCode: number;
    heldNum: number;
    heldPart: number;
    heldFirstDate: dayjs.Dayjs;
    programName: string;
    programSubName: string;
    gradeName: string;
    dailyNum: number;
    raceCount: number;
    timeFrame: TimeFrame;
    isGirls: boolean;
    isLastDay: boolean;
    isCanceled: boolean;
    isSalesSuspended: boolean;
    races: Array<{
      __typename?: 'Race';
      id: string;
      raceProgramId: string;
      raceNum: number;
      bicycleCount: number;
      raceClassName: string;
      raceEventName: string;
      startTime: dayjs.Dayjs;
      orderDeadline: dayjs.Dayjs;
      raceSuspendReasonCode: number;
      finalStageRaceTime: number;
      distance: number;
      lapCount: number;
      finalLapTime: number;
      beforeFinalLapTime: number;
      existsEntryRacerInfo: boolean;
      entryRacerInfoCount: number;
      raceEntryStatus: RaceEntryStatus;
      isCanceled: boolean;
      lineForecast?: Array<Array<number | null>> | null;
    }>;
  };
  race: {
    __typename?: 'Race';
    id: string;
    raceProgramId: string;
    raceNum: number;
    bicycleCount: number;
    raceClassName: string;
    raceEventName: string;
    startTime: dayjs.Dayjs;
    orderDeadline: dayjs.Dayjs;
    raceSuspendReasonCode: number;
    finalStageRaceTime: number;
    distance: number;
    lapCount: number;
    finalLapTime: number;
    beforeFinalLapTime: number;
    existsEntryRacerInfo: boolean;
    entryRacerInfoCount: number;
    raceEntryStatus: RaceEntryStatus;
    isCanceled: boolean;
    lineForecast?: Array<Array<number | null>> | null;
    singleRaceRefunds: Array<{
      __typename?: 'SingleRaceRefund';
      betType: BetType;
      betStatus: BetStatus;
      hitNum1?: number | null;
      hitNum2?: number | null;
      hitNum3?: number | null;
      refund: number;
      hitPlaceRank?: number | null;
    }>;
    singleRaceReleaseInfo: {
      __typename?: 'SingleRaceReleaseInfo';
      bracketQuinellaReleaseStatus: ReleaseStatus;
      bracketPerfectaReleaseStatus: ReleaseStatus;
      quinellaReleaseStatus: ReleaseStatus;
      perfectaReleaseStatus: ReleaseStatus;
      quinellaPlaceReleaseStatus: ReleaseStatus;
      trioReleaseStatus: ReleaseStatus;
      trifectaReleaseStatus: ReleaseStatus;
    };
    entryRacers: Array<{
      __typename?: 'EntryRacer';
      bicycleNum: number;
      bracketNum: number;
      registrationNum: number;
      name: string;
      shortName: string;
      age: number;
      gradeName: string;
      unitName: string;
      prefecture: string;
      prefectureShort: string;
      graduated: string;
      gearRatio: string;
      gearRatioBefore?: string | null;
      racingStyle: string;
      arrivalOrder: number;
      marginInfo?: string | null;
      finalStagePlayerTime?: number | null;
      decidingFactor?: string | null;
      isStandingGet?: boolean | null;
      isJanGet?: boolean | null;
      isHomeGet?: boolean | null;
      isBackGet?: boolean | null;
      playerStatus?: string | null;
      releaseStatus: ReleaseStatus;
      updateNotificationNum: number;
      forecastMark?: ForecastMark | null;
      entryRacerScore: {
        __typename?: 'EntryRacerScore';
        prize: number;
        rank: number;
        last_3raceScoreInfoCount: number;
        averageRaceScore: number;
        winningAverage: number;
        top2Ratio: number;
        top3Ratio: number;
        pacesetterCount: number;
        lookerCount: number;
        comeFromBehindCount: number;
        markCount: number;
        standingCount: number;
        backCount: number;
        topCount: number;
        rank2Count: number;
        rank3Count: number;
        unplacedCount: number;
        totalWinningCount: number;
        winningStreakCount: number;
        entryRacerScoreDetails: Array<{
          __typename?: 'EntryRacerScoreDetail';
          velodromeCode: number;
          heldFirstDate: dayjs.Dayjs;
          grade: Grade;
          scoreList: string;
        }>;
      };
    }>;
  };
};

export type GetOddsByConditionQueryVariables = Exact<{
  raceInput: RaceInput;
}>;

export type GetOddsByConditionQuery = {
  __typename?: 'Query';
  odds: { __typename?: 'Odds'; oddsInfo: string; updated?: dayjs.Dayjs | null };
};

export type GetPayeasyBanksQueryVariables = Exact<{
  input: BankInput;
}>;

export type GetPayeasyBanksQuery = {
  __typename?: 'Query';
  banks: Array<{
    __typename?: 'PayeasyBank';
    id: string;
    name: string;
    nameRuby: string;
  }>;
};

export type GetGmoBanksQueryVariables = Exact<{ [key: string]: never }>;

export type GetGmoBanksQuery = {
  __typename?: 'Query';
  banks: Array<{
    __typename?: 'PayeasyBank';
    id: string;
    name: string;
    nameRuby: string;
    major?: boolean | null;
  }>;
};

export type GetBanksQueryVariables = Exact<{
  major: Scalars['Boolean'];
}>;

export type GetBanksQuery = {
  __typename?: 'Query';
  banks: Array<{
    __typename?: 'Bank';
    id: string;
    name: string;
    nameRuby: string;
  } | null>;
};

export type GetMultiraceProgramQueryVariables = Exact<{
  multiraceProgramInput: MultiraceProgramInput;
}>;

export type GetMultiraceProgramQuery = {
  __typename?: 'Query';
  multiraceProgram: {
    __typename?: 'MultiraceProgram';
    id: number;
    raceProgramId: string;
    velodromeCode: number;
    enforcerCode: number;
    groupCode: number;
    heldFirstDate: dayjs.Dayjs;
    heldDate: dayjs.Dayjs;
    programName: string;
    dokantoHeldNum: number;
    dokantoPromoterCode: number;
    releaseDate: dayjs.Dayjs;
    salesDeadline: dayjs.Dayjs;
    raceCount: number;
    targetRaceNumbers: Array<number>;
    targetRaceHitBetCount: Array<number>;
    refundAmount: number;
    isRefund: boolean;
    multiraceType: MultiraceType;
    unitOfRelease: number;
    releaseBetCount: number;
    hitBetCount: number;
    returnBetCount: number;
    carryoverPrice: number;
    previousCarryoverPrice: number;
    shareCampaignAmount: number;
    isCanceled: boolean;
    releaseStatus: MultiraceReleaseStatus;
    raceProgram: {
      __typename?: 'RaceProgram';
      id: string;
      velodromeCode: number;
      heldDate: dayjs.Dayjs;
      promoterCode: number;
      heldNum: number;
      heldPart: number;
      heldFirstDate: dayjs.Dayjs;
      programName: string;
      programSubName: string;
      gradeName: string;
      dailyNum: number;
      raceCount: number;
      timeFrame: TimeFrame;
      isGirls: boolean;
      isLastDay: boolean;
      isCanceled: boolean;
      isSalesSuspended: boolean;
      races: Array<{
        __typename?: 'Race';
        id: string;
        raceProgramId: string;
        raceNum: number;
        bicycleCount: number;
        raceClassName: string;
        raceEventName: string;
        startTime: dayjs.Dayjs;
        orderDeadline: dayjs.Dayjs;
        raceSuspendReasonCode: number;
        finalStageRaceTime: number;
        distance: number;
        lapCount: number;
        finalLapTime: number;
        beforeFinalLapTime: number;
        existsEntryRacerInfo: boolean;
        entryRacerInfoCount: number;
        raceEntryStatus: RaceEntryStatus;
        isCanceled: boolean;
        lineForecast?: Array<Array<number | null>> | null;
        entryRacers: Array<{
          __typename?: 'EntryRacer';
          singleRacePercentageOfBets: number;
          bicycleNum: number;
          bracketNum: number;
          registrationNum: number;
          name: string;
          shortName: string;
          age: number;
          gradeName: string;
          unitName: string;
          prefecture: string;
          prefectureShort: string;
          graduated: string;
          gearRatio: string;
          gearRatioBefore?: string | null;
          racingStyle: string;
          arrivalOrder: number;
          marginInfo?: string | null;
          finalStagePlayerTime?: number | null;
          decidingFactor?: string | null;
          isStandingGet?: boolean | null;
          isJanGet?: boolean | null;
          isHomeGet?: boolean | null;
          isBackGet?: boolean | null;
          playerStatus?: string | null;
          releaseStatus: ReleaseStatus;
          updateNotificationNum: number;
          forecastMark?: ForecastMark | null;
          multiracePercentageOfBets: Array<{
            __typename?: 'MultiracePercentageOfBets';
            multiraceType: MultiraceType;
            percentageOfBets: number;
          }>;
          entryRacerScore: {
            __typename?: 'EntryRacerScore';
            prize: number;
            rank: number;
            last_3raceScoreInfoCount: number;
            averageRaceScore: number;
            winningAverage: number;
            top2Ratio: number;
            top3Ratio: number;
            pacesetterCount: number;
            lookerCount: number;
            comeFromBehindCount: number;
            markCount: number;
            standingCount: number;
            backCount: number;
            topCount: number;
            rank2Count: number;
            rank3Count: number;
            unplacedCount: number;
            totalWinningCount: number;
            winningStreakCount: number;
            entryRacerScoreDetails: Array<{
              __typename?: 'EntryRacerScoreDetail';
              velodromeCode: number;
              heldFirstDate: dayjs.Dayjs;
              grade: Grade;
              scoreList: string;
            }>;
          };
        }>;
      }>;
    };
  };
};

export type GetCurrentMultiraceProgramsQueryVariables = Exact<{
  input: MultiraceProgramsInput;
}>;

export type GetCurrentMultiraceProgramsQuery = {
  __typename?: 'Query';
  multiracePrograms: Array<{
    __typename?: 'MultiraceProgram';
    id: number;
    raceProgramId: string;
    velodromeCode: number;
    enforcerCode: number;
    groupCode: number;
    heldFirstDate: dayjs.Dayjs;
    heldDate: dayjs.Dayjs;
    programName: string;
    dokantoHeldNum: number;
    dokantoPromoterCode: number;
    releaseDate: dayjs.Dayjs;
    salesDeadline: dayjs.Dayjs;
    raceCount: number;
    targetRaceNumbers: Array<number>;
    targetRaceHitBetCount: Array<number>;
    refundAmount: number;
    isRefund: boolean;
    multiraceType: MultiraceType;
    unitOfRelease: number;
    releaseBetCount: number;
    hitBetCount: number;
    returnBetCount: number;
    carryoverPrice: number;
    previousCarryoverPrice: number;
    shareCampaignAmount: number;
    isCanceled: boolean;
    releaseStatus: MultiraceReleaseStatus;
    raceProgram: {
      __typename?: 'RaceProgram';
      isCanceled: boolean;
      isSalesSuspended: boolean;
      races: Array<{
        __typename?: 'Race';
        isCanceled: boolean;
        raceEntryStatus: RaceEntryStatus;
      }>;
    };
  } | null>;
};

export type GetMultiraceProgramsQueryVariables = Exact<{
  input: MultiraceProgramsInput;
}>;

export type GetMultiraceProgramsQuery = {
  __typename?: 'Query';
  multiracePrograms: Array<{
    __typename?: 'MultiraceProgram';
    id: number;
    raceProgramId: string;
    velodromeCode: number;
    enforcerCode: number;
    groupCode: number;
    heldFirstDate: dayjs.Dayjs;
    heldDate: dayjs.Dayjs;
    programName: string;
    dokantoHeldNum: number;
    dokantoPromoterCode: number;
    releaseDate: dayjs.Dayjs;
    salesDeadline: dayjs.Dayjs;
    raceCount: number;
    targetRaceNumbers: Array<number>;
    targetRaceHitBetCount: Array<number>;
    refundAmount: number;
    isRefund: boolean;
    multiraceType: MultiraceType;
    unitOfRelease: number;
    releaseBetCount: number;
    hitBetCount: number;
    returnBetCount: number;
    carryoverPrice: number;
    previousCarryoverPrice: number;
    shareCampaignAmount: number;
    isCanceled: boolean;
    releaseStatus: MultiraceReleaseStatus;
    raceProgram: {
      __typename?: 'RaceProgram';
      gradeName: string;
      timeFrame: TimeFrame;
      isGirls: boolean;
      velodromeCode: number;
      dailyNum: number;
      isLastDay: boolean;
      heldDate: dayjs.Dayjs;
      isCanceled: boolean;
      isSalesSuspended: boolean;
      races: Array<{
        __typename?: 'Race';
        isCanceled: boolean;
        raceEntryStatus: RaceEntryStatus;
      }>;
    };
  } | null>;
};

export type GetOrderSingleRacesQueryVariables = Exact<{
  input: OrdersInput;
}>;

export type GetOrderSingleRacesQuery = {
  __typename?: 'Query';
  orders: Array<{
    __typename?: 'OrderSingleRace';
    id: string;
    raceId: string;
    status: OrderStatus;
    failureReason: OrderFailureReason;
    totalAmount: number;
    totalRefund: number;
    returnAmount: number;
    refundStatus: OrderRefundStatus;
    created: dayjs.Dayjs;
    tpOrderId?: number | null;
    details: Array<{
      __typename?: 'OrderSingleRaceDetail';
      orderSingleRaceId: string;
      betType: BetType;
      numbers: Array<number>;
      count: number;
      amount: number;
      status: OrderDetailStatus;
      refund: number;
    }>;
    race: {
      __typename?: 'Race';
      raceNum: number;
      startTime: dayjs.Dayjs;
      isCanceled: boolean;
      odds?: { __typename?: 'Odds'; oddsInfo: string } | null;
      singleRaceRefunds: Array<{
        __typename?: 'SingleRaceRefund';
        betType: BetType;
        betStatus: BetStatus;
        hitNum1?: number | null;
        hitNum2?: number | null;
        hitNum3?: number | null;
        refund: number;
        hitPlaceRank?: number | null;
      }>;
    };
    raceProgram: {
      __typename?: 'RaceProgram';
      velodromeCode: number;
      heldDate: dayjs.Dayjs;
      isCanceled: boolean;
    };
  }>;
};

export type GetOrderSingleRaceQueryVariables = Exact<{
  orderSingleRaceId: Scalars['String'];
}>;

export type GetOrderSingleRaceQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'OrderSingleRace';
    id: string;
    raceId: string;
    status: OrderStatus;
    failureReason: OrderFailureReason;
    totalAmount: number;
    totalRefund: number;
    returnAmount: number;
    refundStatus: OrderRefundStatus;
    created: dayjs.Dayjs;
    tpOrderId?: number | null;
    details: Array<{
      __typename?: 'OrderSingleRaceDetail';
      orderSingleRaceId: string;
      betType: BetType;
      numbers: Array<number>;
      count: number;
      amount: number;
      status: OrderDetailStatus;
      refund: number;
    }>;
    race: {
      __typename?: 'Race';
      raceNum: number;
      isCanceled: boolean;
      odds?: { __typename?: 'Odds'; oddsInfo: string } | null;
      entryRacers: Array<{
        __typename?: 'EntryRacer';
        id: string;
        bicycleNum: number;
        arrivalOrder: number;
        name: string;
      }>;
      singleRaceRefunds: Array<{
        __typename?: 'SingleRaceRefund';
        betType: BetType;
        betStatus: BetStatus;
        hitNum1?: number | null;
        hitNum2?: number | null;
        hitNum3?: number | null;
        refund: number;
        hitPlaceRank?: number | null;
      }>;
    };
    raceProgram: {
      __typename?: 'RaceProgram';
      velodromeCode: number;
      heldDate: dayjs.Dayjs;
      gradeName: string;
      timeFrame: TimeFrame;
      isGirls: boolean;
      isCanceled: boolean;
    };
  };
};

export type GetOrderMultiracesQueryVariables = Exact<{
  input: OrdersInput;
}>;

export type GetOrderMultiracesQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'OrderMultiracesResult';
    count: number;
    items: Array<{
      __typename?: 'OrderMultirace';
      id: string;
      multiraceProgramId: number;
      status: OrderStatus;
      failureReason: OrderFailureReason;
      totalAmount: number;
      totalRefund: number;
      returnAmount: number;
      refundStatus: OrderRefundStatus;
      created: dayjs.Dayjs;
      tpOrderId?: number | null;
      details: Array<{
        __typename?: 'OrderMultiraceDetail';
        orderMultiraceId: string;
        numbers: Array<number>;
        count: number;
        amount: number;
        status: OrderDetailStatus;
        refund: number;
      }>;
      multiraceProgram: {
        __typename?: 'MultiraceProgram';
        velodromeCode: number;
        heldDate: dayjs.Dayjs;
        programName: string;
        salesDeadline: dayjs.Dayjs;
        multiraceType: MultiraceType;
        isCanceled: boolean;
        multiraceRefunds: Array<{
          __typename?: 'MultiraceRefund';
          amount: number;
          betStatus: BetStatus;
          hitNumbers: Array<number>;
          hitCount: number;
        }>;
      };
    }>;
  };
};

export type GetOrderMultiraceQueryVariables = Exact<{
  orderMultiraceId: Scalars['String'];
}>;

export type GetOrderMultiraceQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'OrderMultirace';
    id: string;
    multiraceProgramId: number;
    status: OrderStatus;
    failureReason: OrderFailureReason;
    totalAmount: number;
    totalRefund: number;
    returnAmount: number;
    refundStatus: OrderRefundStatus;
    created: dayjs.Dayjs;
    tpOrderId?: number | null;
    details: Array<{
      __typename?: 'OrderMultiraceDetail';
      orderMultiraceId: string;
      numbers: Array<number>;
      count: number;
      amount: number;
      status: OrderDetailStatus;
      refund: number;
    }>;
    multiraceProgram: {
      __typename?: 'MultiraceProgram';
      id: number;
      raceProgramId: string;
      velodromeCode: number;
      enforcerCode: number;
      groupCode: number;
      heldFirstDate: dayjs.Dayjs;
      heldDate: dayjs.Dayjs;
      programName: string;
      dokantoHeldNum: number;
      dokantoPromoterCode: number;
      releaseDate: dayjs.Dayjs;
      salesDeadline: dayjs.Dayjs;
      raceCount: number;
      targetRaceNumbers: Array<number>;
      targetRaceHitBetCount: Array<number>;
      refundAmount: number;
      isRefund: boolean;
      multiraceType: MultiraceType;
      unitOfRelease: number;
      releaseBetCount: number;
      hitBetCount: number;
      returnBetCount: number;
      carryoverPrice: number;
      previousCarryoverPrice: number;
      shareCampaignAmount: number;
      isCanceled: boolean;
      releaseStatus: MultiraceReleaseStatus;
      multiraceRefunds: Array<{
        __typename?: 'MultiraceRefund';
        amount: number;
        betStatus: BetStatus;
        hitNumbers: Array<number>;
        hitCount: number;
      }>;
      raceProgram: {
        __typename?: 'RaceProgram';
        gradeName: string;
        timeFrame: TimeFrame;
        isGirls: boolean;
        races: Array<{
          __typename?: 'Race';
          id: string;
          raceNum: number;
          isCanceled: boolean;
          raceEntryStatus: RaceEntryStatus;
          entryRacers: Array<{
            __typename?: 'EntryRacer';
            id: string;
            bicycleNum: number;
            arrivalOrder: number;
            name: string;
            updateNotificationNum: number;
          }>;
        }>;
      };
      singleRaceRefundsByRaceId: Array<{
        __typename?: 'SingleRaceRefundsByRaceId';
        raceId: string;
        singleRaceRefunds: Array<{
          __typename?: 'SingleRaceRefund';
          betType: BetType;
          betStatus: BetStatus;
          hitNum1?: number | null;
          hitNum2?: number | null;
          hitNum3?: number | null;
          refund: number;
          hitPlaceRank?: number | null;
        }>;
      }>;
    };
  };
};

export type GetCc3DAuthQueryVariables = Exact<{
  md: Scalars['String'];
}>;

export type GetCc3DAuthQuery = {
  __typename?: 'Query';
  cc3DAuth: {
    __typename?: 'Cc3dAuth';
    md: string;
    userId: string;
    pfChargeId: number;
    amount: number;
    j: string;
    logId: string;
    paRes?: string | null;
    eci: string;
    result: CreditCard3dVerifyStatus;
  };
};

export type GetBankBranchesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBankBranchesQuery = {
  __typename?: 'Query';
  bankBranches: Array<{
    __typename?: 'BankBranch';
    id: string;
    bankId?: string | null;
    bankName: string;
    bankNameRuby: string;
    name: string;
    nameRuby: string;
  } | null>;
};

export type GetEntryRacerScoreDetailOwnsQueryVariables = Exact<{
  raceId: Scalars['String'];
  heldDate: Scalars['String'];
  detailCnt: Scalars['Int'];
}>;

export type GetEntryRacerScoreDetailOwnsQuery = {
  __typename?: 'Query';
  entryRacerScoreDetailOwns: Array<{
    __typename?: 'EntryRacerScoreDetailOwn';
    registrationNum: number;
    heldFirstDate: dayjs.Dayjs;
    velodromeCode: number;
    gradeName: string;
    scoreCsv1: string;
    scoreCsv2: string;
    scoreCsv3: string;
    scoreCsv4: string;
    scoreCsv5: string;
    scoreCsv6: string;
    scoreCsv7: string;
  }>;
};

export type GetEntryRacerScoreDetailOwnsConditionQueryVariables = Exact<{
  input: GetScoreDetailOwnsInputParam;
}>;

export type GetEntryRacerScoreDetailOwnsConditionQuery = {
  __typename?: 'Query';
  entryRacerScoreDetailOwnsCondition: Array<{
    __typename?: 'EntryRacerScoreDetailOwn';
    registrationNum: number;
    heldFirstDate: dayjs.Dayjs;
    velodromeCode: number;
    gradeName: string;
    scoreCsv1: string;
    scoreCsv2: string;
    scoreCsv3: string;
    scoreCsv4: string;
    scoreCsv5: string;
    scoreCsv6: string;
    scoreCsv7: string;
  }>;
};

export type GetEntryRacerNotesQueryVariables = Exact<{
  raceId: Scalars['String'];
}>;

export type GetEntryRacerNotesQuery = {
  __typename?: 'Query';
  entryRacerNotes: Array<{
    __typename?: 'EntryRacerNote';
    registrationNum: number;
    raceId: string;
    userId: string;
    velodromeCode: number;
    raceNum: number;
    heldDate: dayjs.Dayjs;
    body: string;
    race: { __typename?: 'Race'; raceClassName: string; raceEventName: string };
  }>;
};

export type GetEntryRacerNoteCountsQueryVariables = Exact<{
  heldDate: Scalars['Date'];
}>;

export type GetEntryRacerNoteCountsQuery = {
  __typename?: 'Query';
  entryRacerNoteCounts: Array<{
    __typename?: 'EntryRacerNoteCount';
    raceID: string;
    count: number;
  }>;
};

export type GetSingleRaceBetResultQueryVariables = Exact<{
  input: BetResultsInput;
}>;

export type GetSingleRaceBetResultQuery = {
  __typename?: 'Query';
  singleRaceBetResult: {
    __typename?: 'SingleRaceBetResult';
    count: number;
    orderedDaysCount: number;
    orderedRaceCount: number;
    hittedRaceCount: number;
    hitRate: number;
    totalAmount: number;
    totalRefundAmount: number;
    balance: number;
    retrieveRate: number;
    maxTotalRefund: number;
    maxHitOdds: number;
    over10000YenRefundCount: number;
    items: Array<{
      __typename?: 'SingleRaceBetResultItem';
      targetDate: dayjs.Dayjs;
      count: number;
      orderedDaysCount: number;
      orderedRaceCount: number;
      hittedRaceCount: number;
      hitRate: number;
      totalAmount: number;
      totalRefundAmount: number;
      balance: number;
      retrieveRate: number;
      maxTotalRefund: number;
      maxHitOdds: number;
      over10000YenRefundCount: number;
    }>;
  };
};

export type GetWalletStatementsQueryVariables = Exact<{
  input: WalletStatementsInput;
}>;

export type GetWalletStatementsQuery = {
  __typename?: 'Query';
  walletStatements: Array<{
    __typename?: 'WalletStatement';
    id: string;
    userId: string;
    type: WalletStatementType;
    target: Target;
    amount: number;
    status: WalletStatementStatus;
    created: dayjs.Dayjs;
  } | null>;
};

export type GetWalletStatementProcessingCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetWalletStatementProcessingCountQuery = {
  __typename?: 'Query';
  processingCount: { __typename?: 'ProcessingCount'; count: number };
};

export type GetCalendarQueryVariables = Exact<{
  raceProgramsInput: RaceProgramsInput;
  multiraceProgramsInput: MultiraceProgramsInput;
}>;

export type GetCalendarQuery = {
  __typename?: 'Query';
  racePrograms: Array<{
    __typename?: 'RaceProgram';
    id: string;
    velodromeCode: number;
    heldDate: dayjs.Dayjs;
    promoterCode: number;
    heldNum: number;
    heldPart: number;
    heldFirstDate: dayjs.Dayjs;
    programName: string;
    programSubName: string;
    gradeName: string;
    dailyNum: number;
    raceCount: number;
    timeFrame: TimeFrame;
    isGirls: boolean;
    isLastDay: boolean;
    isCanceled: boolean;
    isSalesSuspended: boolean;
  }>;
  multiracePrograms: Array<{
    __typename?: 'MultiraceProgram';
    id: number;
    raceProgramId: string;
    velodromeCode: number;
    enforcerCode: number;
    groupCode: number;
    heldFirstDate: dayjs.Dayjs;
    heldDate: dayjs.Dayjs;
    programName: string;
    dokantoHeldNum: number;
    dokantoPromoterCode: number;
    releaseDate: dayjs.Dayjs;
    salesDeadline: dayjs.Dayjs;
    raceCount: number;
    targetRaceNumbers: Array<number>;
    targetRaceHitBetCount: Array<number>;
    refundAmount: number;
    isRefund: boolean;
    multiraceType: MultiraceType;
    unitOfRelease: number;
    releaseBetCount: number;
    hitBetCount: number;
    returnBetCount: number;
    carryoverPrice: number;
    previousCarryoverPrice: number;
    shareCampaignAmount: number;
    isCanceled: boolean;
    releaseStatus: MultiraceReleaseStatus;
    raceProgram: {
      __typename?: 'RaceProgram';
      gradeName: string;
      timeFrame: TimeFrame;
      isGirls: boolean;
      velodromeCode: number;
      dailyNum: number;
      isLastDay: boolean;
      heldDate: dayjs.Dayjs;
    };
  } | null>;
};

export type GetAnnouncementQueryVariables = Exact<{ [key: string]: never }>;

export type GetAnnouncementQuery = {
  __typename?: 'Query';
  announcement?: {
    __typename?: 'Announcement';
    subject: string;
    body: string;
  } | null;
};

export type GetIsEnteredEntryCampaignQueryVariables = Exact<{
  entryCampaignId: Scalars['String'];
}>;

export type GetIsEnteredEntryCampaignQuery = {
  __typename?: 'Query';
  isEnteredEntryCampaign: boolean;
};

export type GetChargebackCampaignProgressQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetChargebackCampaignProgressQuery = {
  __typename?: 'Query';
  chargebackCampaignProgress: {
    __typename?: 'ChargebackCampaignProgress';
    userId: string;
    votedAmount: number;
    details: Array<{
      __typename?: 'ChargebackCampaignProgressDetail';
      amount: number;
      grantDate: dayjs.Dayjs;
      isFixed: boolean;
    }>;
  };
};

export type GetEnteringCampaignIdsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEnteringCampaignIdsQuery = {
  __typename?: 'Query';
  enteringCampaignIDs: Array<string>;
};

export type GetSingleraceBetResultByVelodromeQueryVariables = Exact<{
  targetYear: Scalars['Int'];
}>;

export type GetSingleraceBetResultByVelodromeQuery = {
  __typename?: 'Query';
  singleRaceBetResultByVelodrome: Array<{
    __typename?: 'SingleRaceBetResultByVelodrome';
    velodromeCode: number;
    hitRaceNums: string;
    totalAmount: number;
    totalRefundAmount: number;
    retrieveRate: number;
  }>;
};

export type GetSingleRaceBetResultByBetTypeQueryVariables = Exact<{
  input: BetResultsInputByBetType;
}>;

export type GetSingleRaceBetResultByBetTypeQuery = {
  __typename?: 'Query';
  singleRaceBetResultByBetType: Array<{
    __typename?: 'SingleRaceBetResultByBetType';
    timeFrame: TimeFrame;
    totalAmount: number;
    totalRefundAmount: number;
    retrieveRate: number;
  }>;
};

export type GetSingleraceBetResultByVelodromeV2QueryVariables = Exact<{
  input: BetResultsInputByBetType;
}>;

export type GetSingleraceBetResultByVelodromeV2Query = {
  __typename?: 'Query';
  singleRaceBetResultByVelodromeV2: Array<{
    __typename?: 'SingleRaceBetResultByVelodrome';
    velodromeCode: number;
    hitRaceNums: string;
    buyRaceNums: string;
    totalAmount: number;
    totalRefundAmount: number;
    retrieveRate: number;
  }>;
};

export type GetSingleraceBetResultByGradeQueryVariables = Exact<{
  input: BetResultsInputByBetType;
}>;

export type GetSingleraceBetResultByGradeQuery = {
  __typename?: 'Query';
  singleRaceBetResultByGrade: Array<{
    __typename?: 'SingleRaceBetResultByGrade';
    gradeName: string;
    totalAmount: number;
    totalRefundAmount: number;
    retrieveRate: number;
  }>;
};

export type GetOrderDateLargeDividendQueryVariables = Exact<{
  period: Scalars['Int'];
}>;

export type GetOrderDateLargeDividendQuery = {
  __typename?: 'Query';
  orderDateLargeDividend: {
    __typename?: 'OrderDateLargeDividend';
    refundAmount: number;
    updated: dayjs.Dayjs;
  };
};

export type GetOrderSingleRacesByRaceIdQueryVariables = Exact<{
  raceId: Scalars['String'];
}>;

export type GetOrderSingleRacesByRaceIdQuery = {
  __typename?: 'Query';
  orderSingleRaces: Array<{
    __typename?: 'OrderSingleRace';
    id: string;
    raceId: string;
    status: OrderStatus;
    failureReason: OrderFailureReason;
    totalAmount: number;
    totalRefund: number;
    returnAmount: number;
    refundStatus: OrderRefundStatus;
    created: dayjs.Dayjs;
    tpOrderId?: number | null;
    details: Array<{
      __typename?: 'OrderSingleRaceDetail';
      orderSingleRaceId: string;
      betType: BetType;
      numbers: Array<number>;
      count: number;
      amount: number;
      status: OrderDetailStatus;
      refund: number;
    }>;
    race: {
      __typename?: 'Race';
      raceNum: number;
      isCanceled: boolean;
      odds?: { __typename?: 'Odds'; oddsInfo: string } | null;
      entryRacers: Array<{
        __typename?: 'EntryRacer';
        id: string;
        bicycleNum: number;
        arrivalOrder: number;
        name: string;
      }>;
      singleRaceRefunds: Array<{
        __typename?: 'SingleRaceRefund';
        betType: BetType;
        betStatus: BetStatus;
        hitNum1?: number | null;
        hitNum2?: number | null;
        hitNum3?: number | null;
        refund: number;
        hitPlaceRank?: number | null;
      }>;
    };
    raceProgram: {
      __typename?: 'RaceProgram';
      velodromeCode: number;
      heldDate: dayjs.Dayjs;
      gradeName: string;
      timeFrame: TimeFrame;
      isGirls: boolean;
      isCanceled: boolean;
    };
  }>;
};

export type GetCountryCodeQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountryCodeQuery = {
  __typename?: 'Query';
  countryCode: {
    __typename?: 'CheckCountry';
    isSearchEngine: boolean;
    isSns: boolean;
    isOgp: boolean;
    code: string;
  };
};

export type GetIssueOneTimePassQueryVariables = Exact<{
  mode: OtpAddress;
}>;

export type GetIssueOneTimePassQuery = {
  __typename?: 'Query';
  issueOneTimePass: {
    __typename?: 'OtpIssueResult';
    otpIssueCode: OtpIssueCode;
    issuedTime: dayjs.Dayjs;
    expiryPeriod: dayjs.Dayjs;
  };
};

export type GetTransferRegistrationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetTransferRegistrationQuery = {
  __typename?: 'Query';
  isTransferRegistration: {
    __typename?: 'TransferRegistResult';
    hasPersonalHash: boolean;
    hasSameHashUser: boolean;
  };
};

export type GetTransferStatementProcessingCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetTransferStatementProcessingCountQuery = {
  __typename?: 'Query';
  processingCount: { __typename?: 'ProcessingCount'; count: number };
};

export const SingleRaceReleaseInfoFragmentDoc = gql`
  fragment SingleRaceReleaseInfo on SingleRaceReleaseInfo {
    bracketQuinellaReleaseStatus
    bracketPerfectaReleaseStatus
    quinellaReleaseStatus
    perfectaReleaseStatus
    quinellaPlaceReleaseStatus
    trioReleaseStatus
    trifectaReleaseStatus
  }
`;
export const SingleRaceRefundFieldFragmentDoc = gql`
  fragment SingleRaceRefundField on SingleRaceRefund {
    betType
    betStatus
    hitNum1
    hitNum2
    hitNum3
    refund
    hitPlaceRank
  }
`;
export const EntryRacerScoreDetailFragmentDoc = gql`
  fragment EntryRacerScoreDetail on EntryRacerScoreDetail {
    velodromeCode
    heldFirstDate
    grade
    scoreList
  }
`;
export const EntryRacerScoreFieldFragmentDoc = gql`
  fragment EntryRacerScoreField on EntryRacerScore {
    prize
    rank
    last_3raceScoreInfoCount
    averageRaceScore
    winningAverage
    top2Ratio
    top3Ratio
    pacesetterCount
    lookerCount
    comeFromBehindCount
    markCount
    standingCount
    backCount
    topCount
    rank2Count
    rank3Count
    unplacedCount
    totalWinningCount
    winningStreakCount
  }
`;
export const EntryRacerFieldFragmentDoc = gql`
  fragment EntryRacerField on EntryRacer {
    bicycleNum
    bracketNum
    registrationNum
    name
    shortName
    age
    gradeName
    unitName
    prefecture
    prefectureShort
    graduated
    gearRatio
    gearRatioBefore
    racingStyle
    arrivalOrder
    marginInfo
    finalStagePlayerTime
    decidingFactor
    isStandingGet
    isJanGet
    isHomeGet
    isBackGet
    playerStatus
    releaseStatus
    updateNotificationNum
    forecastMark
  }
`;
export const RaceFieldFragmentDoc = gql`
  fragment RaceField on Race {
    id
    raceProgramId
    raceNum
    bicycleCount
    raceClassName
    raceEventName
    startTime
    orderDeadline
    raceSuspendReasonCode
    finalStageRaceTime
    distance
    lapCount
    finalLapTime
    beforeFinalLapTime
    existsEntryRacerInfo
    entryRacerInfoCount
    raceEntryStatus
    isCanceled
    lineForecast
  }
`;
export const RaceProgramFieldFragmentDoc = gql`
  fragment RaceProgramField on RaceProgram {
    id
    velodromeCode
    heldDate
    promoterCode
    heldNum
    heldPart
    heldFirstDate
    programName
    programSubName
    gradeName
    dailyNum
    raceCount
    timeFrame
    isGirls
    isLastDay
    isCanceled
    isSalesSuspended
  }
`;
export const MultiraceProgramFieldFragmentDoc = gql`
  fragment MultiraceProgramField on MultiraceProgram {
    id
    raceProgramId
    velodromeCode
    enforcerCode
    groupCode
    heldFirstDate
    heldDate
    programName
    dokantoHeldNum
    dokantoPromoterCode
    releaseDate
    salesDeadline
    raceCount
    targetRaceNumbers
    targetRaceHitBetCount
    refundAmount
    isRefund
    multiraceType
    unitOfRelease
    releaseBetCount
    hitBetCount
    returnBetCount
    carryoverPrice
    previousCarryoverPrice
    shareCampaignAmount
    isCanceled
    releaseStatus
  }
`;
export const OrderSingleRaceFieldFragmentDoc = gql`
  fragment OrderSingleRaceField on OrderSingleRace {
    id
    raceId
    status
    failureReason
    totalAmount
    totalRefund
    returnAmount
    refundStatus
    created
    tpOrderId
  }
`;
export const OrderSingleRaceDetailFieldFragmentDoc = gql`
  fragment OrderSingleRaceDetailField on OrderSingleRaceDetail {
    orderSingleRaceId
    betType
    numbers
    count
    amount
    status
    refund
  }
`;
export const OrderMultiraceFieldFragmentDoc = gql`
  fragment OrderMultiraceField on OrderMultirace {
    id
    multiraceProgramId
    status
    failureReason
    totalAmount
    totalRefund
    returnAmount
    refundStatus
    created
    tpOrderId
  }
`;
export const OrderMultiraceDetailFieldFragmentDoc = gql`
  fragment OrderMultiraceDetailField on OrderMultiraceDetail {
    orderMultiraceId
    numbers
    count
    amount
    status
    refund
  }
`;
export const OrderMultiracesResultFieldFragmentDoc = gql`
  fragment OrderMultiracesResultField on OrderMultiracesResult {
    count
  }
`;
export const EntryRacerNoteFieldFragmentDoc = gql`
  fragment EntryRacerNoteField on EntryRacerNote {
    registrationNum
    raceId
    userId
    velodromeCode
    raceNum
    heldDate
    body
  }
`;
export const EntryRacerScoreDetailOwnFieldFragmentDoc = gql`
  fragment EntryRacerScoreDetailOwnField on EntryRacerScoreDetailOwn {
    registrationNum
    heldFirstDate
    velodromeCode
    gradeName
    scoreCsv1
    scoreCsv2
    scoreCsv3
    scoreCsv4
    scoreCsv5
    scoreCsv6
    scoreCsv7
  }
`;
export const EntryRacerNoteCountFieldFragmentDoc = gql`
  fragment EntryRacerNoteCountField on EntryRacerNoteCount {
    raceID
    count
  }
`;
export const SingleRaceBetResultFieldFragmentDoc = gql`
  fragment SingleRaceBetResultField on SingleRaceBetResult {
    count
    orderedDaysCount
    orderedRaceCount
    hittedRaceCount
    hitRate
    totalAmount
    totalRefundAmount
    balance
    retrieveRate
    maxTotalRefund
    maxHitOdds
    over10000YenRefundCount
  }
`;
export const SingleRaceBetResultItemFieldFragmentDoc = gql`
  fragment SingleRaceBetResultItemField on SingleRaceBetResultItem {
    targetDate
    count
    orderedDaysCount
    orderedRaceCount
    hittedRaceCount
    hitRate
    totalAmount
    totalRefundAmount
    balance
    retrieveRate
    maxTotalRefund
    maxHitOdds
    over10000YenRefundCount
  }
`;
export const AddressFieldFragmentDoc = gql`
  fragment AddressField on Address {
    addressId
    addressLabel
    countryCode
    postalCode
    region
    prefectureCode
    locality
    streetAddress
    building
    addressee
    givenName
    familyName
    givenNameRuby
    familyNameRuby
    middleName
    formatted
    mailingEmail
    mailingPhoneNumber
    isDeleted
    createdAt
  }
`;
export const CreateUserDocument = gql`
  mutation CreateUser(
    $phoneNo: String!
    $isMailSubscribe: Boolean!
    $inviter: String
  ) {
    createUser(
      input: {
        phoneNo: $phoneNo
        isMailSubscribe: $isMailSubscribe
        inviter: $inviter
      }
    ) {
      userId
      charilotoUserId
      verificationStatus
      verificator
      verified
      personalInfo {
        lastName
        firstName
        lastnameRuby
        firstnameRuby
        birthDate
      }
      status
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      phoneNo: // value for 'phoneNo'
 *      isMailSubscribe: // value for 'isMailSubscribe'
 *      inviter: // value for 'inviter'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const OrderSingleRaceDocument = gql`
  mutation OrderSingleRace($raceId: String!, $items: [ItemInput!]!) {
    orderSingleRace(input: { raceId: $raceId, items: $items }) {
      id
      userId
      raceId
      tpOrderId
      status
      failureReason
      totalAmount
      emoneyTransactionId
      details {
        orderSingleRaceId
        betType
        numbers
        count
        amount
      }
    }
  }
`;
export type OrderSingleRaceMutationFn = Apollo.MutationFunction<
  OrderSingleRaceMutation,
  OrderSingleRaceMutationVariables
>;

/**
 * __useOrderSingleRaceMutation__
 *
 * To run a mutation, you first call `useOrderSingleRaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderSingleRaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderSingleRaceMutation, { data, loading, error }] = useOrderSingleRaceMutation({
 *   variables: {
 *      raceId: // value for 'raceId'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useOrderSingleRaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderSingleRaceMutation,
    OrderSingleRaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderSingleRaceMutation,
    OrderSingleRaceMutationVariables
  >(OrderSingleRaceDocument, options);
}
export type OrderSingleRaceMutationHookResult = ReturnType<
  typeof useOrderSingleRaceMutation
>;
export type OrderSingleRaceMutationResult =
  Apollo.MutationResult<OrderSingleRaceMutation>;
export type OrderSingleRaceMutationOptions = Apollo.BaseMutationOptions<
  OrderSingleRaceMutation,
  OrderSingleRaceMutationVariables
>;
export const OrderMultiraceRandomDocument = gql`
  mutation OrderMultiraceRandom($input: OrderMultiraceRandomInput!) {
    orderMultiraceRandom(input: $input) {
      id
      totalAmount
      details {
        orderMultiraceId
        numbers
        count
        amount
      }
      status
      emoneyTransactionId
    }
  }
`;
export type OrderMultiraceRandomMutationFn = Apollo.MutationFunction<
  OrderMultiraceRandomMutation,
  OrderMultiraceRandomMutationVariables
>;

/**
 * __useOrderMultiraceRandomMutation__
 *
 * To run a mutation, you first call `useOrderMultiraceRandomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderMultiraceRandomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderMultiraceRandomMutation, { data, loading, error }] = useOrderMultiraceRandomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderMultiraceRandomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderMultiraceRandomMutation,
    OrderMultiraceRandomMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderMultiraceRandomMutation,
    OrderMultiraceRandomMutationVariables
  >(OrderMultiraceRandomDocument, options);
}
export type OrderMultiraceRandomMutationHookResult = ReturnType<
  typeof useOrderMultiraceRandomMutation
>;
export type OrderMultiraceRandomMutationResult =
  Apollo.MutationResult<OrderMultiraceRandomMutation>;
export type OrderMultiraceRandomMutationOptions = Apollo.BaseMutationOptions<
  OrderMultiraceRandomMutation,
  OrderMultiraceRandomMutationVariables
>;
export const OrderMultiraceSelectDocument = gql`
  mutation OrderMultiraceSelect($input: OrderMultiraceSelectInput!) {
    orderMultiraceSelect(input: $input) {
      id
      totalAmount
      details {
        orderMultiraceId
        numbers
        count
        amount
      }
      status
      emoneyTransactionId
    }
  }
`;
export type OrderMultiraceSelectMutationFn = Apollo.MutationFunction<
  OrderMultiraceSelectMutation,
  OrderMultiraceSelectMutationVariables
>;

/**
 * __useOrderMultiraceSelectMutation__
 *
 * To run a mutation, you first call `useOrderMultiraceSelectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderMultiraceSelectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderMultiraceSelectMutation, { data, loading, error }] = useOrderMultiraceSelectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderMultiraceSelectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderMultiraceSelectMutation,
    OrderMultiraceSelectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderMultiraceSelectMutation,
    OrderMultiraceSelectMutationVariables
  >(OrderMultiraceSelectDocument, options);
}
export type OrderMultiraceSelectMutationHookResult = ReturnType<
  typeof useOrderMultiraceSelectMutation
>;
export type OrderMultiraceSelectMutationResult =
  Apollo.MutationResult<OrderMultiraceSelectMutation>;
export type OrderMultiraceSelectMutationOptions = Apollo.BaseMutationOptions<
  OrderMultiraceSelectMutation,
  OrderMultiraceSelectMutationVariables
>;
export const IssueMultiraceRandomTicketsDocument = gql`
  mutation IssueMultiraceRandomTickets(
    $multiraceProgramId: Int!
    $betCount: Int!
  ) {
    issueMultiraceRandomTickets(
      input: { multiraceProgramId: $multiraceProgramId, betCount: $betCount }
    ) {
      remainingPreviewsCount
      betCount
      items
    }
  }
`;
export type IssueMultiraceRandomTicketsMutationFn = Apollo.MutationFunction<
  IssueMultiraceRandomTicketsMutation,
  IssueMultiraceRandomTicketsMutationVariables
>;

/**
 * __useIssueMultiraceRandomTicketsMutation__
 *
 * To run a mutation, you first call `useIssueMultiraceRandomTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueMultiraceRandomTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueMultiraceRandomTicketsMutation, { data, loading, error }] = useIssueMultiraceRandomTicketsMutation({
 *   variables: {
 *      multiraceProgramId: // value for 'multiraceProgramId'
 *      betCount: // value for 'betCount'
 *   },
 * });
 */
export function useIssueMultiraceRandomTicketsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IssueMultiraceRandomTicketsMutation,
    IssueMultiraceRandomTicketsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IssueMultiraceRandomTicketsMutation,
    IssueMultiraceRandomTicketsMutationVariables
  >(IssueMultiraceRandomTicketsDocument, options);
}
export type IssueMultiraceRandomTicketsMutationHookResult = ReturnType<
  typeof useIssueMultiraceRandomTicketsMutation
>;
export type IssueMultiraceRandomTicketsMutationResult =
  Apollo.MutationResult<IssueMultiraceRandomTicketsMutation>;
export type IssueMultiraceRandomTicketsMutationOptions =
  Apollo.BaseMutationOptions<
    IssueMultiraceRandomTicketsMutation,
    IssueMultiraceRandomTicketsMutationVariables
  >;
export const RequestPayeasySettlementDocument = gql`
  mutation RequestPayeasySettlement(
    $deviceType: TransferDeviceType!
    $bankCode: String!
    $amount: Int!
  ) {
    requestPayeasySettlement(
      input: { deviceType: $deviceType, bankCode: $bankCode, amount: $amount }
    ) {
      walletStatementId
      settlementType
      bankCode
      bankURL
      receivePaymentCode
      patternCode
      encryptInfo
      encryptedAccessCode
      customerCode
      dueDate
      created
    }
  }
`;
export type RequestPayeasySettlementMutationFn = Apollo.MutationFunction<
  RequestPayeasySettlementMutation,
  RequestPayeasySettlementMutationVariables
>;

/**
 * __useRequestPayeasySettlementMutation__
 *
 * To run a mutation, you first call `useRequestPayeasySettlementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPayeasySettlementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPayeasySettlementMutation, { data, loading, error }] = useRequestPayeasySettlementMutation({
 *   variables: {
 *      deviceType: // value for 'deviceType'
 *      bankCode: // value for 'bankCode'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useRequestPayeasySettlementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPayeasySettlementMutation,
    RequestPayeasySettlementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPayeasySettlementMutation,
    RequestPayeasySettlementMutationVariables
  >(RequestPayeasySettlementDocument, options);
}
export type RequestPayeasySettlementMutationHookResult = ReturnType<
  typeof useRequestPayeasySettlementMutation
>;
export type RequestPayeasySettlementMutationResult =
  Apollo.MutationResult<RequestPayeasySettlementMutation>;
export type RequestPayeasySettlementMutationOptions =
  Apollo.BaseMutationOptions<
    RequestPayeasySettlementMutation,
    RequestPayeasySettlementMutationVariables
  >;
export const SaveBankAccountDocument = gql`
  mutation SaveBankAccount(
    $bankID: String!
    $bankBranchID: String!
    $accountType: BankAccountType!
    $accountNumber: String!
  ) {
    saveBankAccount(
      input: {
        bankID: $bankID
        bankBranchID: $bankBranchID
        accountType: $accountType
        accountNumber: $accountNumber
      }
    ) {
      id
      accountHolder
      accountHolderRuby
      bankId
      bankName
      bankBranchId
      bankBranchName
      accountType
      accountNumber
    }
  }
`;
export type SaveBankAccountMutationFn = Apollo.MutationFunction<
  SaveBankAccountMutation,
  SaveBankAccountMutationVariables
>;

/**
 * __useSaveBankAccountMutation__
 *
 * To run a mutation, you first call `useSaveBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBankAccountMutation, { data, loading, error }] = useSaveBankAccountMutation({
 *   variables: {
 *      bankID: // value for 'bankID'
 *      bankBranchID: // value for 'bankBranchID'
 *      accountType: // value for 'accountType'
 *      accountNumber: // value for 'accountNumber'
 *   },
 * });
 */
export function useSaveBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveBankAccountMutation,
    SaveBankAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveBankAccountMutation,
    SaveBankAccountMutationVariables
  >(SaveBankAccountDocument, options);
}
export type SaveBankAccountMutationHookResult = ReturnType<
  typeof useSaveBankAccountMutation
>;
export type SaveBankAccountMutationResult =
  Apollo.MutationResult<SaveBankAccountMutation>;
export type SaveBankAccountMutationOptions = Apollo.BaseMutationOptions<
  SaveBankAccountMutation,
  SaveBankAccountMutationVariables
>;
export const ChargeToretaFromCreditDocument = gql`
  mutation ChargeToretaFromCredit($amount: Int!) {
    chargeToretaFromCredit(amount: $amount) {
      result
      acsURL
      paReq
      md
      termUrl
      cc3dLogId
    }
  }
`;
export type ChargeToretaFromCreditMutationFn = Apollo.MutationFunction<
  ChargeToretaFromCreditMutation,
  ChargeToretaFromCreditMutationVariables
>;

/**
 * __useChargeToretaFromCreditMutation__
 *
 * To run a mutation, you first call `useChargeToretaFromCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeToretaFromCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeToretaFromCreditMutation, { data, loading, error }] = useChargeToretaFromCreditMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useChargeToretaFromCreditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChargeToretaFromCreditMutation,
    ChargeToretaFromCreditMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChargeToretaFromCreditMutation,
    ChargeToretaFromCreditMutationVariables
  >(ChargeToretaFromCreditDocument, options);
}
export type ChargeToretaFromCreditMutationHookResult = ReturnType<
  typeof useChargeToretaFromCreditMutation
>;
export type ChargeToretaFromCreditMutationResult =
  Apollo.MutationResult<ChargeToretaFromCreditMutation>;
export type ChargeToretaFromCreditMutationOptions = Apollo.BaseMutationOptions<
  ChargeToretaFromCreditMutation,
  ChargeToretaFromCreditMutationVariables
>;
export const ChargeToretaFromCreditWithValidateOrderDocument = gql`
  mutation ChargeToretaFromCreditWithValidateOrder(
    $amount: Int!
    $orderAmount: Int!
  ) {
    chargeToretaFromCreditWithValidateOrder(
      amount: $amount
      orderAmount: $orderAmount
    ) {
      result
      acsURL
      paReq
      md
      termUrl
      cc3dLogId
    }
  }
`;
export type ChargeToretaFromCreditWithValidateOrderMutationFn =
  Apollo.MutationFunction<
    ChargeToretaFromCreditWithValidateOrderMutation,
    ChargeToretaFromCreditWithValidateOrderMutationVariables
  >;

/**
 * __useChargeToretaFromCreditWithValidateOrderMutation__
 *
 * To run a mutation, you first call `useChargeToretaFromCreditWithValidateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeToretaFromCreditWithValidateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeToretaFromCreditWithValidateOrderMutation, { data, loading, error }] = useChargeToretaFromCreditWithValidateOrderMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      orderAmount: // value for 'orderAmount'
 *   },
 * });
 */
export function useChargeToretaFromCreditWithValidateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChargeToretaFromCreditWithValidateOrderMutation,
    ChargeToretaFromCreditWithValidateOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChargeToretaFromCreditWithValidateOrderMutation,
    ChargeToretaFromCreditWithValidateOrderMutationVariables
  >(ChargeToretaFromCreditWithValidateOrderDocument, options);
}
export type ChargeToretaFromCreditWithValidateOrderMutationHookResult =
  ReturnType<typeof useChargeToretaFromCreditWithValidateOrderMutation>;
export type ChargeToretaFromCreditWithValidateOrderMutationResult =
  Apollo.MutationResult<ChargeToretaFromCreditWithValidateOrderMutation>;
export type ChargeToretaFromCreditWithValidateOrderMutationOptions =
  Apollo.BaseMutationOptions<
    ChargeToretaFromCreditWithValidateOrderMutation,
    ChargeToretaFromCreditWithValidateOrderMutationVariables
  >;
export const ChargeToretaFromCreditV2Document = gql`
  mutation ChargeToretaFromCreditV2($amount: Int!) {
    chargeToretaFromCreditV2(amount: $amount) {
      iframeUrl
      md
      paReq
      result
      threeDSMethod
      threeDSOrderID
      merchant
    }
  }
`;
export type ChargeToretaFromCreditV2MutationFn = Apollo.MutationFunction<
  ChargeToretaFromCreditV2Mutation,
  ChargeToretaFromCreditV2MutationVariables
>;

/**
 * __useChargeToretaFromCreditV2Mutation__
 *
 * To run a mutation, you first call `useChargeToretaFromCreditV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeToretaFromCreditV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeToretaFromCreditV2Mutation, { data, loading, error }] = useChargeToretaFromCreditV2Mutation({
 *   variables: {
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useChargeToretaFromCreditV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChargeToretaFromCreditV2Mutation,
    ChargeToretaFromCreditV2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChargeToretaFromCreditV2Mutation,
    ChargeToretaFromCreditV2MutationVariables
  >(ChargeToretaFromCreditV2Document, options);
}
export type ChargeToretaFromCreditV2MutationHookResult = ReturnType<
  typeof useChargeToretaFromCreditV2Mutation
>;
export type ChargeToretaFromCreditV2MutationResult =
  Apollo.MutationResult<ChargeToretaFromCreditV2Mutation>;
export type ChargeToretaFromCreditV2MutationOptions =
  Apollo.BaseMutationOptions<
    ChargeToretaFromCreditV2Mutation,
    ChargeToretaFromCreditV2MutationVariables
  >;
export const ChargeToretaFromCreditV2WithValidateOrderDocument = gql`
  mutation ChargeToretaFromCreditV2WithValidateOrder(
    $amount: Int!
    $orderAmount: Int!
  ) {
    chargeToretaFromCreditV2WithValidateOrder(
      amount: $amount
      orderAmount: $orderAmount
    ) {
      iframeUrl
      md
      paReq
      result
      threeDSMethod
      threeDSOrderID
      merchant
    }
  }
`;
export type ChargeToretaFromCreditV2WithValidateOrderMutationFn =
  Apollo.MutationFunction<
    ChargeToretaFromCreditV2WithValidateOrderMutation,
    ChargeToretaFromCreditV2WithValidateOrderMutationVariables
  >;

/**
 * __useChargeToretaFromCreditV2WithValidateOrderMutation__
 *
 * To run a mutation, you first call `useChargeToretaFromCreditV2WithValidateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeToretaFromCreditV2WithValidateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeToretaFromCreditV2WithValidateOrderMutation, { data, loading, error }] = useChargeToretaFromCreditV2WithValidateOrderMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      orderAmount: // value for 'orderAmount'
 *   },
 * });
 */
export function useChargeToretaFromCreditV2WithValidateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChargeToretaFromCreditV2WithValidateOrderMutation,
    ChargeToretaFromCreditV2WithValidateOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChargeToretaFromCreditV2WithValidateOrderMutation,
    ChargeToretaFromCreditV2WithValidateOrderMutationVariables
  >(ChargeToretaFromCreditV2WithValidateOrderDocument, options);
}
export type ChargeToretaFromCreditV2WithValidateOrderMutationHookResult =
  ReturnType<typeof useChargeToretaFromCreditV2WithValidateOrderMutation>;
export type ChargeToretaFromCreditV2WithValidateOrderMutationResult =
  Apollo.MutationResult<ChargeToretaFromCreditV2WithValidateOrderMutation>;
export type ChargeToretaFromCreditV2WithValidateOrderMutationOptions =
  Apollo.BaseMutationOptions<
    ChargeToretaFromCreditV2WithValidateOrderMutation,
    ChargeToretaFromCreditV2WithValidateOrderMutationVariables
  >;
export const ChargeToretaFromWalletDocument = gql`
  mutation ChargeToretaFromWallet($amount: Int!) {
    chargeToretaFromWallet(amount: $amount) {
      status
    }
  }
`;
export type ChargeToretaFromWalletMutationFn = Apollo.MutationFunction<
  ChargeToretaFromWalletMutation,
  ChargeToretaFromWalletMutationVariables
>;

/**
 * __useChargeToretaFromWalletMutation__
 *
 * To run a mutation, you first call `useChargeToretaFromWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeToretaFromWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeToretaFromWalletMutation, { data, loading, error }] = useChargeToretaFromWalletMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useChargeToretaFromWalletMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChargeToretaFromWalletMutation,
    ChargeToretaFromWalletMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChargeToretaFromWalletMutation,
    ChargeToretaFromWalletMutationVariables
  >(ChargeToretaFromWalletDocument, options);
}
export type ChargeToretaFromWalletMutationHookResult = ReturnType<
  typeof useChargeToretaFromWalletMutation
>;
export type ChargeToretaFromWalletMutationResult =
  Apollo.MutationResult<ChargeToretaFromWalletMutation>;
export type ChargeToretaFromWalletMutationOptions = Apollo.BaseMutationOptions<
  ChargeToretaFromWalletMutation,
  ChargeToretaFromWalletMutationVariables
>;
export const ChargeToretaFromWalletWithValidateOrderDocument = gql`
  mutation ChargeToretaFromWalletWithValidateOrder(
    $amount: Int!
    $orderAmount: Int!
  ) {
    chargeToretaFromWalletWithValidateOrder(
      amount: $amount
      orderAmount: $orderAmount
    ) {
      status
    }
  }
`;
export type ChargeToretaFromWalletWithValidateOrderMutationFn =
  Apollo.MutationFunction<
    ChargeToretaFromWalletWithValidateOrderMutation,
    ChargeToretaFromWalletWithValidateOrderMutationVariables
  >;

/**
 * __useChargeToretaFromWalletWithValidateOrderMutation__
 *
 * To run a mutation, you first call `useChargeToretaFromWalletWithValidateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeToretaFromWalletWithValidateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeToretaFromWalletWithValidateOrderMutation, { data, loading, error }] = useChargeToretaFromWalletWithValidateOrderMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      orderAmount: // value for 'orderAmount'
 *   },
 * });
 */
export function useChargeToretaFromWalletWithValidateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChargeToretaFromWalletWithValidateOrderMutation,
    ChargeToretaFromWalletWithValidateOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChargeToretaFromWalletWithValidateOrderMutation,
    ChargeToretaFromWalletWithValidateOrderMutationVariables
  >(ChargeToretaFromWalletWithValidateOrderDocument, options);
}
export type ChargeToretaFromWalletWithValidateOrderMutationHookResult =
  ReturnType<typeof useChargeToretaFromWalletWithValidateOrderMutation>;
export type ChargeToretaFromWalletWithValidateOrderMutationResult =
  Apollo.MutationResult<ChargeToretaFromWalletWithValidateOrderMutation>;
export type ChargeToretaFromWalletWithValidateOrderMutationOptions =
  Apollo.BaseMutationOptions<
    ChargeToretaFromWalletWithValidateOrderMutation,
    ChargeToretaFromWalletWithValidateOrderMutationVariables
  >;
export const RequestPayoutDocument = gql`
  mutation RequestPayout($amount: Int!) {
    requestPayout(amount: $amount) {
      status
    }
  }
`;
export type RequestPayoutMutationFn = Apollo.MutationFunction<
  RequestPayoutMutation,
  RequestPayoutMutationVariables
>;

/**
 * __useRequestPayoutMutation__
 *
 * To run a mutation, you first call `useRequestPayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPayoutMutation, { data, loading, error }] = useRequestPayoutMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useRequestPayoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPayoutMutation,
    RequestPayoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPayoutMutation,
    RequestPayoutMutationVariables
  >(RequestPayoutDocument, options);
}
export type RequestPayoutMutationHookResult = ReturnType<
  typeof useRequestPayoutMutation
>;
export type RequestPayoutMutationResult =
  Apollo.MutationResult<RequestPayoutMutation>;
export type RequestPayoutMutationOptions = Apollo.BaseMutationOptions<
  RequestPayoutMutation,
  RequestPayoutMutationVariables
>;
export const SaveEntryRacerNoteDocument = gql`
  mutation SaveEntryRacerNote(
    $raceID: String!
    $registrationNum: Int!
    $body: String!
  ) {
    saveEntryRacerNote(
      input: { raceID: $raceID, registrationNum: $registrationNum, body: $body }
    ) {
      registrationNum
      raceId
      userId
      velodromeCode
      raceNum
      heldDate
      body
      race {
        raceClassName
        raceEventName
      }
    }
  }
`;
export type SaveEntryRacerNoteMutationFn = Apollo.MutationFunction<
  SaveEntryRacerNoteMutation,
  SaveEntryRacerNoteMutationVariables
>;

/**
 * __useSaveEntryRacerNoteMutation__
 *
 * To run a mutation, you first call `useSaveEntryRacerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEntryRacerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEntryRacerNoteMutation, { data, loading, error }] = useSaveEntryRacerNoteMutation({
 *   variables: {
 *      raceID: // value for 'raceID'
 *      registrationNum: // value for 'registrationNum'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSaveEntryRacerNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEntryRacerNoteMutation,
    SaveEntryRacerNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveEntryRacerNoteMutation,
    SaveEntryRacerNoteMutationVariables
  >(SaveEntryRacerNoteDocument, options);
}
export type SaveEntryRacerNoteMutationHookResult = ReturnType<
  typeof useSaveEntryRacerNoteMutation
>;
export type SaveEntryRacerNoteMutationResult =
  Apollo.MutationResult<SaveEntryRacerNoteMutation>;
export type SaveEntryRacerNoteMutationOptions = Apollo.BaseMutationOptions<
  SaveEntryRacerNoteMutation,
  SaveEntryRacerNoteMutationVariables
>;
export const DeleteEntryRacerNoteDocument = gql`
  mutation DeleteEntryRacerNote($raceID: String!, $registrationNum: Int!) {
    deleteEntryRacerNote(
      input: { raceID: $raceID, registrationNum: $registrationNum }
    ) {
      registrationNum
      raceId
      userId
      velodromeCode
      raceNum
      heldDate
      body
    }
  }
`;
export type DeleteEntryRacerNoteMutationFn = Apollo.MutationFunction<
  DeleteEntryRacerNoteMutation,
  DeleteEntryRacerNoteMutationVariables
>;

/**
 * __useDeleteEntryRacerNoteMutation__
 *
 * To run a mutation, you first call `useDeleteEntryRacerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEntryRacerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEntryRacerNoteMutation, { data, loading, error }] = useDeleteEntryRacerNoteMutation({
 *   variables: {
 *      raceID: // value for 'raceID'
 *      registrationNum: // value for 'registrationNum'
 *   },
 * });
 */
export function useDeleteEntryRacerNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEntryRacerNoteMutation,
    DeleteEntryRacerNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEntryRacerNoteMutation,
    DeleteEntryRacerNoteMutationVariables
  >(DeleteEntryRacerNoteDocument, options);
}
export type DeleteEntryRacerNoteMutationHookResult = ReturnType<
  typeof useDeleteEntryRacerNoteMutation
>;
export type DeleteEntryRacerNoteMutationResult =
  Apollo.MutationResult<DeleteEntryRacerNoteMutation>;
export type DeleteEntryRacerNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteEntryRacerNoteMutation,
  DeleteEntryRacerNoteMutationVariables
>;
export const UpdateMailReceiveOptionDocument = gql`
  mutation UpdateMailReceiveOption($input: UpdateMailReceiveOptionInput!) {
    updateMailReceiveOption(input: $input) {
      atVoteCompleted
      atVotedRaceStarted
      atVotedRaceResultConfirmed
    }
  }
`;
export type UpdateMailReceiveOptionMutationFn = Apollo.MutationFunction<
  UpdateMailReceiveOptionMutation,
  UpdateMailReceiveOptionMutationVariables
>;

/**
 * __useUpdateMailReceiveOptionMutation__
 *
 * To run a mutation, you first call `useUpdateMailReceiveOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMailReceiveOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMailReceiveOptionMutation, { data, loading, error }] = useUpdateMailReceiveOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMailReceiveOptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMailReceiveOptionMutation,
    UpdateMailReceiveOptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMailReceiveOptionMutation,
    UpdateMailReceiveOptionMutationVariables
  >(UpdateMailReceiveOptionDocument, options);
}
export type UpdateMailReceiveOptionMutationHookResult = ReturnType<
  typeof useUpdateMailReceiveOptionMutation
>;
export type UpdateMailReceiveOptionMutationResult =
  Apollo.MutationResult<UpdateMailReceiveOptionMutation>;
export type UpdateMailReceiveOptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateMailReceiveOptionMutation,
  UpdateMailReceiveOptionMutationVariables
>;
export const RegisterEntryCampaignDocument = gql`
  mutation RegisterEntryCampaign($entryCampaignId: String!) {
    registerEntryCampaign(entryCampaignId: $entryCampaignId) {
      entryCampaignId
    }
  }
`;
export type RegisterEntryCampaignMutationFn = Apollo.MutationFunction<
  RegisterEntryCampaignMutation,
  RegisterEntryCampaignMutationVariables
>;

/**
 * __useRegisterEntryCampaignMutation__
 *
 * To run a mutation, you first call `useRegisterEntryCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterEntryCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerEntryCampaignMutation, { data, loading, error }] = useRegisterEntryCampaignMutation({
 *   variables: {
 *      entryCampaignId: // value for 'entryCampaignId'
 *   },
 * });
 */
export function useRegisterEntryCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterEntryCampaignMutation,
    RegisterEntryCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterEntryCampaignMutation,
    RegisterEntryCampaignMutationVariables
  >(RegisterEntryCampaignDocument, options);
}
export type RegisterEntryCampaignMutationHookResult = ReturnType<
  typeof useRegisterEntryCampaignMutation
>;
export type RegisterEntryCampaignMutationResult =
  Apollo.MutationResult<RegisterEntryCampaignMutation>;
export type RegisterEntryCampaignMutationOptions = Apollo.BaseMutationOptions<
  RegisterEntryCampaignMutation,
  RegisterEntryCampaignMutationVariables
>;
export const SaveAddressDocument = gql`
  mutation SaveAddress($input: SaveAddressInput!) {
    saveAddress(input: $input)
  }
`;
export type SaveAddressMutationFn = Apollo.MutationFunction<
  SaveAddressMutation,
  SaveAddressMutationVariables
>;

/**
 * __useSaveAddressMutation__
 *
 * To run a mutation, you first call `useSaveAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAddressMutation, { data, loading, error }] = useSaveAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveAddressMutation,
    SaveAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveAddressMutation, SaveAddressMutationVariables>(
    SaveAddressDocument,
    options,
  );
}
export type SaveAddressMutationHookResult = ReturnType<
  typeof useSaveAddressMutation
>;
export type SaveAddressMutationResult =
  Apollo.MutationResult<SaveAddressMutation>;
export type SaveAddressMutationOptions = Apollo.BaseMutationOptions<
  SaveAddressMutation,
  SaveAddressMutationVariables
>;
export const SaveDailyOrderLimitDocument = gql`
  mutation SaveDailyOrderLimit($amount: Int!) {
    saveDailyOrderLimit(amount: $amount) {
      userId
      amount
      updated
    }
  }
`;
export type SaveDailyOrderLimitMutationFn = Apollo.MutationFunction<
  SaveDailyOrderLimitMutation,
  SaveDailyOrderLimitMutationVariables
>;

/**
 * __useSaveDailyOrderLimitMutation__
 *
 * To run a mutation, you first call `useSaveDailyOrderLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDailyOrderLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDailyOrderLimitMutation, { data, loading, error }] = useSaveDailyOrderLimitMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useSaveDailyOrderLimitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveDailyOrderLimitMutation,
    SaveDailyOrderLimitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveDailyOrderLimitMutation,
    SaveDailyOrderLimitMutationVariables
  >(SaveDailyOrderLimitDocument, options);
}
export type SaveDailyOrderLimitMutationHookResult = ReturnType<
  typeof useSaveDailyOrderLimitMutation
>;
export type SaveDailyOrderLimitMutationResult =
  Apollo.MutationResult<SaveDailyOrderLimitMutation>;
export type SaveDailyOrderLimitMutationOptions = Apollo.BaseMutationOptions<
  SaveDailyOrderLimitMutation,
  SaveDailyOrderLimitMutationVariables
>;
export const DeleteDailyOrderLimitDocument = gql`
  mutation DeleteDailyOrderLimit {
    deleteDailyOrderLimit {
      userId
    }
  }
`;
export type DeleteDailyOrderLimitMutationFn = Apollo.MutationFunction<
  DeleteDailyOrderLimitMutation,
  DeleteDailyOrderLimitMutationVariables
>;

/**
 * __useDeleteDailyOrderLimitMutation__
 *
 * To run a mutation, you first call `useDeleteDailyOrderLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDailyOrderLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDailyOrderLimitMutation, { data, loading, error }] = useDeleteDailyOrderLimitMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteDailyOrderLimitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDailyOrderLimitMutation,
    DeleteDailyOrderLimitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDailyOrderLimitMutation,
    DeleteDailyOrderLimitMutationVariables
  >(DeleteDailyOrderLimitDocument, options);
}
export type DeleteDailyOrderLimitMutationHookResult = ReturnType<
  typeof useDeleteDailyOrderLimitMutation
>;
export type DeleteDailyOrderLimitMutationResult =
  Apollo.MutationResult<DeleteDailyOrderLimitMutation>;
export type DeleteDailyOrderLimitMutationOptions = Apollo.BaseMutationOptions<
  DeleteDailyOrderLimitMutation,
  DeleteDailyOrderLimitMutationVariables
>;
export const CheckOneTimePassDocument = gql`
  mutation CheckOneTimePass($inputMode: OtpAddress!, $inputCode: String!) {
    checkOneTimePass(mode: $inputMode, code: $inputCode) {
      isPermission
    }
  }
`;
export type CheckOneTimePassMutationFn = Apollo.MutationFunction<
  CheckOneTimePassMutation,
  CheckOneTimePassMutationVariables
>;

/**
 * __useCheckOneTimePassMutation__
 *
 * To run a mutation, you first call `useCheckOneTimePassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOneTimePassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOneTimePassMutation, { data, loading, error }] = useCheckOneTimePassMutation({
 *   variables: {
 *      inputMode: // value for 'inputMode'
 *      inputCode: // value for 'inputCode'
 *   },
 * });
 */
export function useCheckOneTimePassMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckOneTimePassMutation,
    CheckOneTimePassMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CheckOneTimePassMutation,
    CheckOneTimePassMutationVariables
  >(CheckOneTimePassDocument, options);
}
export type CheckOneTimePassMutationHookResult = ReturnType<
  typeof useCheckOneTimePassMutation
>;
export type CheckOneTimePassMutationResult =
  Apollo.MutationResult<CheckOneTimePassMutation>;
export type CheckOneTimePassMutationOptions = Apollo.BaseMutationOptions<
  CheckOneTimePassMutation,
  CheckOneTimePassMutationVariables
>;
export const SavePaypayAccountDocument = gql`
  mutation SavePaypayAccount(
    $bankID: String!
    $bankBranchID: String!
    $accountType: BankAccountType!
    $accountNumber: String!
  ) {
    savePaypayAccount(
      input: {
        bankID: $bankID
        bankBranchID: $bankBranchID
        accountType: $accountType
        accountNumber: $accountNumber
      }
    ) {
      id
      accountHolder
      accountHolderRuby
      bankId
      bankName
      bankBranchId
      bankBranchName
      accountType
      accountNumber
    }
  }
`;
export type SavePaypayAccountMutationFn = Apollo.MutationFunction<
  SavePaypayAccountMutation,
  SavePaypayAccountMutationVariables
>;

/**
 * __useSavePaypayAccountMutation__
 *
 * To run a mutation, you first call `useSavePaypayAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePaypayAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePaypayAccountMutation, { data, loading, error }] = useSavePaypayAccountMutation({
 *   variables: {
 *      bankID: // value for 'bankID'
 *      bankBranchID: // value for 'bankBranchID'
 *      accountType: // value for 'accountType'
 *      accountNumber: // value for 'accountNumber'
 *   },
 * });
 */
export function useSavePaypayAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavePaypayAccountMutation,
    SavePaypayAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SavePaypayAccountMutation,
    SavePaypayAccountMutationVariables
  >(SavePaypayAccountDocument, options);
}
export type SavePaypayAccountMutationHookResult = ReturnType<
  typeof useSavePaypayAccountMutation
>;
export type SavePaypayAccountMutationResult =
  Apollo.MutationResult<SavePaypayAccountMutation>;
export type SavePaypayAccountMutationOptions = Apollo.BaseMutationOptions<
  SavePaypayAccountMutation,
  SavePaypayAccountMutationVariables
>;
export const DeleteTransferAccountDocument = gql`
  mutation DeleteTransferAccount {
    deleteTransferAccount {
      result
    }
  }
`;
export type DeleteTransferAccountMutationFn = Apollo.MutationFunction<
  DeleteTransferAccountMutation,
  DeleteTransferAccountMutationVariables
>;

/**
 * __useDeleteTransferAccountMutation__
 *
 * To run a mutation, you first call `useDeleteTransferAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransferAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransferAccountMutation, { data, loading, error }] = useDeleteTransferAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteTransferAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTransferAccountMutation,
    DeleteTransferAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTransferAccountMutation,
    DeleteTransferAccountMutationVariables
  >(DeleteTransferAccountDocument, options);
}
export type DeleteTransferAccountMutationHookResult = ReturnType<
  typeof useDeleteTransferAccountMutation
>;
export type DeleteTransferAccountMutationResult =
  Apollo.MutationResult<DeleteTransferAccountMutation>;
export type DeleteTransferAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteTransferAccountMutation,
  DeleteTransferAccountMutationVariables
>;
export const RequestTransferDocument = gql`
  mutation RequestTransfer($amount: Int!) {
    requestTransfer(amount: $amount) {
      status
    }
  }
`;
export type RequestTransferMutationFn = Apollo.MutationFunction<
  RequestTransferMutation,
  RequestTransferMutationVariables
>;

/**
 * __useRequestTransferMutation__
 *
 * To run a mutation, you first call `useRequestTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTransferMutation, { data, loading, error }] = useRequestTransferMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useRequestTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestTransferMutation,
    RequestTransferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestTransferMutation,
    RequestTransferMutationVariables
  >(RequestTransferDocument, options);
}
export type RequestTransferMutationHookResult = ReturnType<
  typeof useRequestTransferMutation
>;
export type RequestTransferMutationResult =
  Apollo.MutationResult<RequestTransferMutation>;
export type RequestTransferMutationOptions = Apollo.BaseMutationOptions<
  RequestTransferMutation,
  RequestTransferMutationVariables
>;
export const PreGmoAccountDocument = gql`
  mutation PreGmoAccount($bankCode: String!) {
    preGmoAccount(bankCode: $bankCode) {
      tranID
      token
      startUrl
      bankName
    }
  }
`;
export type PreGmoAccountMutationFn = Apollo.MutationFunction<
  PreGmoAccountMutation,
  PreGmoAccountMutationVariables
>;

/**
 * __usePreGmoAccountMutation__
 *
 * To run a mutation, you first call `usePreGmoAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreGmoAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preGmoAccountMutation, { data, loading, error }] = usePreGmoAccountMutation({
 *   variables: {
 *      bankCode: // value for 'bankCode'
 *   },
 * });
 */
export function usePreGmoAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PreGmoAccountMutation,
    PreGmoAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PreGmoAccountMutation,
    PreGmoAccountMutationVariables
  >(PreGmoAccountDocument, options);
}
export type PreGmoAccountMutationHookResult = ReturnType<
  typeof usePreGmoAccountMutation
>;
export type PreGmoAccountMutationResult =
  Apollo.MutationResult<PreGmoAccountMutation>;
export type PreGmoAccountMutationOptions = Apollo.BaseMutationOptions<
  PreGmoAccountMutation,
  PreGmoAccountMutationVariables
>;
export const GetCallbackUrlDocument = gql`
  query GetCallbackUrl($callbackUrl: String!) {
    callbackUrl(url: $callbackUrl)
  }
`;

/**
 * __useGetCallbackUrlQuery__
 *
 * To run a query within a React component, call `useGetCallbackUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCallbackUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCallbackUrlQuery({
 *   variables: {
 *      callbackUrl: // value for 'callbackUrl'
 *   },
 * });
 */
export function useGetCallbackUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCallbackUrlQuery,
    GetCallbackUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCallbackUrlQuery, GetCallbackUrlQueryVariables>(
    GetCallbackUrlDocument,
    options,
  );
}
export function useGetCallbackUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCallbackUrlQuery,
    GetCallbackUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCallbackUrlQuery, GetCallbackUrlQueryVariables>(
    GetCallbackUrlDocument,
    options,
  );
}
export type GetCallbackUrlQueryHookResult = ReturnType<
  typeof useGetCallbackUrlQuery
>;
export type GetCallbackUrlLazyQueryHookResult = ReturnType<
  typeof useGetCallbackUrlLazyQuery
>;
export type GetCallbackUrlQueryResult = Apollo.QueryResult<
  GetCallbackUrlQuery,
  GetCallbackUrlQueryVariables
>;
export const GetPaypayAccountIdDocument = gql`
  query GetPaypayAccountID {
    user {
      userId
      paypayAccountID
      paypayAccountSetting
    }
  }
`;

/**
 * __useGetPaypayAccountIdQuery__
 *
 * To run a query within a React component, call `useGetPaypayAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaypayAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaypayAccountIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaypayAccountIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaypayAccountIdQuery,
    GetPaypayAccountIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaypayAccountIdQuery,
    GetPaypayAccountIdQueryVariables
  >(GetPaypayAccountIdDocument, options);
}
export function useGetPaypayAccountIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaypayAccountIdQuery,
    GetPaypayAccountIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaypayAccountIdQuery,
    GetPaypayAccountIdQueryVariables
  >(GetPaypayAccountIdDocument, options);
}
export type GetPaypayAccountIdQueryHookResult = ReturnType<
  typeof useGetPaypayAccountIdQuery
>;
export type GetPaypayAccountIdLazyQueryHookResult = ReturnType<
  typeof useGetPaypayAccountIdLazyQuery
>;
export type GetPaypayAccountIdQueryResult = Apollo.QueryResult<
  GetPaypayAccountIdQuery,
  GetPaypayAccountIdQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser {
    user {
      userId
      charilotoUserId
      verificationStatus
      verificator
      verified
      status
      paypayAccountID
      paypayAccountSetting
      dailyOrderLimit {
        amount
        updated
      }
      address {
        ...AddressField
      }
      wallet {
        toreta {
          balance
          limitedBalance
        }
        balance
        pendingBalance
        genericCodeWs
      }
      mailReceiveOption: mailReceiveOption {
        atVoteCompleted
        atVotedRaceResultConfirmed
        atVotedRaceStarted
      }
      gmoAccountInfo {
        bankCode
        bankName
        status
        updated
      }
      chargebackAppleal {
        campaignID
        isEntried
        endTime
      }
    }
  }
  ${AddressFieldFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserQuery,
    GetUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetAddressDocument = gql`
  query GetAddress {
    user {
      userId
      address {
        ...AddressField
      }
    }
  }
  ${AddressFieldFragmentDoc}
`;

/**
 * __useGetAddressQuery__
 *
 * To run a query within a React component, call `useGetAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAddressQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAddressQuery,
    GetAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAddressQuery, GetAddressQueryVariables>(
    GetAddressDocument,
    options,
  );
}
export function useGetAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddressQuery,
    GetAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAddressQuery, GetAddressQueryVariables>(
    GetAddressDocument,
    options,
  );
}
export type GetAddressQueryHookResult = ReturnType<typeof useGetAddressQuery>;
export type GetAddressLazyQueryHookResult = ReturnType<
  typeof useGetAddressLazyQuery
>;
export type GetAddressQueryResult = Apollo.QueryResult<
  GetAddressQuery,
  GetAddressQueryVariables
>;
export const GetUserWalletDocument = gql`
  query GetUserWallet {
    user {
      userId
      wallet {
        toreta {
          balance
          limitedBalance
        }
        balance
        pendingBalance
        updated
      }
    }
  }
`;

/**
 * __useGetUserWalletQuery__
 *
 * To run a query within a React component, call `useGetUserWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWalletQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserWalletQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserWalletQuery,
    GetUserWalletQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserWalletQuery, GetUserWalletQueryVariables>(
    GetUserWalletDocument,
    options,
  );
}
export function useGetUserWalletLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserWalletQuery,
    GetUserWalletQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserWalletQuery, GetUserWalletQueryVariables>(
    GetUserWalletDocument,
    options,
  );
}
export type GetUserWalletQueryHookResult = ReturnType<
  typeof useGetUserWalletQuery
>;
export type GetUserWalletLazyQueryHookResult = ReturnType<
  typeof useGetUserWalletLazyQuery
>;
export type GetUserWalletQueryResult = Apollo.QueryResult<
  GetUserWalletQuery,
  GetUserWalletQueryVariables
>;
export const GetUserPersonalInfoDocument = gql`
  query GetUserPersonalInfo {
    user {
      userId
      personalInfo {
        lastName
        firstName
        lastnameRuby
        firstnameRuby
        birthDate
        phoneNo
      }
    }
  }
`;

/**
 * __useGetUserPersonalInfoQuery__
 *
 * To run a query within a React component, call `useGetUserPersonalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPersonalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPersonalInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPersonalInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserPersonalInfoQuery,
    GetUserPersonalInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserPersonalInfoQuery,
    GetUserPersonalInfoQueryVariables
  >(GetUserPersonalInfoDocument, options);
}
export function useGetUserPersonalInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPersonalInfoQuery,
    GetUserPersonalInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserPersonalInfoQuery,
    GetUserPersonalInfoQueryVariables
  >(GetUserPersonalInfoDocument, options);
}
export type GetUserPersonalInfoQueryHookResult = ReturnType<
  typeof useGetUserPersonalInfoQuery
>;
export type GetUserPersonalInfoLazyQueryHookResult = ReturnType<
  typeof useGetUserPersonalInfoLazyQuery
>;
export type GetUserPersonalInfoQueryResult = Apollo.QueryResult<
  GetUserPersonalInfoQuery,
  GetUserPersonalInfoQueryVariables
>;
export const GetUserBankAccountDocument = gql`
  query GetUserBankAccount {
    user {
      userId
      bankAccount {
        id
        accountHolder
        accountHolderRuby
        bankId
        bankName
        bankBranchId
        bankBranchName
        accountType
        accountNumber
      }
    }
  }
`;

/**
 * __useGetUserBankAccountQuery__
 *
 * To run a query within a React component, call `useGetUserBankAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBankAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBankAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserBankAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserBankAccountQuery,
    GetUserBankAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserBankAccountQuery,
    GetUserBankAccountQueryVariables
  >(GetUserBankAccountDocument, options);
}
export function useGetUserBankAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserBankAccountQuery,
    GetUserBankAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserBankAccountQuery,
    GetUserBankAccountQueryVariables
  >(GetUserBankAccountDocument, options);
}
export type GetUserBankAccountQueryHookResult = ReturnType<
  typeof useGetUserBankAccountQuery
>;
export type GetUserBankAccountLazyQueryHookResult = ReturnType<
  typeof useGetUserBankAccountLazyQuery
>;
export type GetUserBankAccountQueryResult = Apollo.QueryResult<
  GetUserBankAccountQuery,
  GetUserBankAccountQueryVariables
>;
export const GetRecentRaceProgramsDocument = gql`
  query GetRecentRacePrograms(
    $prev: raceProgramsInput!
    $today: raceProgramsInput!
    $next: raceProgramsInput!
  ) {
    previousDay: racePrograms(input: $prev) {
      ...RaceProgramField
      races {
        ...RaceField
      }
    }
    today: racePrograms(input: $today) {
      ...RaceProgramField
      races {
        ...RaceField
      }
    }
    nextDay: racePrograms(input: $next) {
      ...RaceProgramField
      races {
        ...RaceField
      }
    }
  }
  ${RaceProgramFieldFragmentDoc}
  ${RaceFieldFragmentDoc}
`;

/**
 * __useGetRecentRaceProgramsQuery__
 *
 * To run a query within a React component, call `useGetRecentRaceProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentRaceProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentRaceProgramsQuery({
 *   variables: {
 *      prev: // value for 'prev'
 *      today: // value for 'today'
 *      next: // value for 'next'
 *   },
 * });
 */
export function useGetRecentRaceProgramsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRecentRaceProgramsQuery,
    GetRecentRaceProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRecentRaceProgramsQuery,
    GetRecentRaceProgramsQueryVariables
  >(GetRecentRaceProgramsDocument, options);
}
export function useGetRecentRaceProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecentRaceProgramsQuery,
    GetRecentRaceProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRecentRaceProgramsQuery,
    GetRecentRaceProgramsQueryVariables
  >(GetRecentRaceProgramsDocument, options);
}
export type GetRecentRaceProgramsQueryHookResult = ReturnType<
  typeof useGetRecentRaceProgramsQuery
>;
export type GetRecentRaceProgramsLazyQueryHookResult = ReturnType<
  typeof useGetRecentRaceProgramsLazyQuery
>;
export type GetRecentRaceProgramsQueryResult = Apollo.QueryResult<
  GetRecentRaceProgramsQuery,
  GetRecentRaceProgramsQueryVariables
>;
export const GetRaceProgramsDocument = gql`
  query GetRacePrograms($input: raceProgramsInput!) {
    racePrograms(input: $input) {
      ...RaceProgramField
      races {
        ...RaceField
        singleRaceRefunds {
          ...SingleRaceRefundField
        }
      }
    }
  }
  ${RaceProgramFieldFragmentDoc}
  ${RaceFieldFragmentDoc}
  ${SingleRaceRefundFieldFragmentDoc}
`;

/**
 * __useGetRaceProgramsQuery__
 *
 * To run a query within a React component, call `useGetRaceProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRaceProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRaceProgramsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRaceProgramsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRaceProgramsQuery,
    GetRaceProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRaceProgramsQuery, GetRaceProgramsQueryVariables>(
    GetRaceProgramsDocument,
    options,
  );
}
export function useGetRaceProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRaceProgramsQuery,
    GetRaceProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRaceProgramsQuery,
    GetRaceProgramsQueryVariables
  >(GetRaceProgramsDocument, options);
}
export type GetRaceProgramsQueryHookResult = ReturnType<
  typeof useGetRaceProgramsQuery
>;
export type GetRaceProgramsLazyQueryHookResult = ReturnType<
  typeof useGetRaceProgramsLazyQuery
>;
export type GetRaceProgramsQueryResult = Apollo.QueryResult<
  GetRaceProgramsQuery,
  GetRaceProgramsQueryVariables
>;
export const GetRaceProgramDocument = gql`
  query GetRaceProgram($input: raceProgramInput!) {
    raceProgram: raceProgramByCondition(input: $input) {
      ...RaceProgramField
      races {
        ...RaceField
        entryRacers {
          ...EntryRacerField
          entryRacerScore {
            ...EntryRacerScoreField
            entryRacerScoreDetails {
              ...EntryRacerScoreDetail
            }
          }
        }
        singleRaceRefunds {
          ...SingleRaceRefundField
        }
      }
    }
  }
  ${RaceProgramFieldFragmentDoc}
  ${RaceFieldFragmentDoc}
  ${EntryRacerFieldFragmentDoc}
  ${EntryRacerScoreFieldFragmentDoc}
  ${EntryRacerScoreDetailFragmentDoc}
  ${SingleRaceRefundFieldFragmentDoc}
`;

/**
 * __useGetRaceProgramQuery__
 *
 * To run a query within a React component, call `useGetRaceProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRaceProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRaceProgramQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRaceProgramQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRaceProgramQuery,
    GetRaceProgramQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRaceProgramQuery, GetRaceProgramQueryVariables>(
    GetRaceProgramDocument,
    options,
  );
}
export function useGetRaceProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRaceProgramQuery,
    GetRaceProgramQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRaceProgramQuery, GetRaceProgramQueryVariables>(
    GetRaceProgramDocument,
    options,
  );
}
export type GetRaceProgramQueryHookResult = ReturnType<
  typeof useGetRaceProgramQuery
>;
export type GetRaceProgramLazyQueryHookResult = ReturnType<
  typeof useGetRaceProgramLazyQuery
>;
export type GetRaceProgramQueryResult = Apollo.QueryResult<
  GetRaceProgramQuery,
  GetRaceProgramQueryVariables
>;
export const GetSingleraceResultDocument = gql`
  query GetSingleraceResult($input: raceProgramInput!) {
    raceProgram: raceProgramByCondition(input: $input) {
      ...RaceProgramField
      races {
        ...RaceField
        entryRacers {
          ...EntryRacerField
        }
        singleRaceRefunds {
          ...SingleRaceRefundField
        }
        odds {
          oddsInfo
        }
      }
    }
  }
  ${RaceProgramFieldFragmentDoc}
  ${RaceFieldFragmentDoc}
  ${EntryRacerFieldFragmentDoc}
  ${SingleRaceRefundFieldFragmentDoc}
`;

/**
 * __useGetSingleraceResultQuery__
 *
 * To run a query within a React component, call `useGetSingleraceResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleraceResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleraceResultQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSingleraceResultQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSingleraceResultQuery,
    GetSingleraceResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSingleraceResultQuery,
    GetSingleraceResultQueryVariables
  >(GetSingleraceResultDocument, options);
}
export function useGetSingleraceResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSingleraceResultQuery,
    GetSingleraceResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSingleraceResultQuery,
    GetSingleraceResultQueryVariables
  >(GetSingleraceResultDocument, options);
}
export type GetSingleraceResultQueryHookResult = ReturnType<
  typeof useGetSingleraceResultQuery
>;
export type GetSingleraceResultLazyQueryHookResult = ReturnType<
  typeof useGetSingleraceResultLazyQuery
>;
export type GetSingleraceResultQueryResult = Apollo.QueryResult<
  GetSingleraceResultQuery,
  GetSingleraceResultQueryVariables
>;
export const GetMultiraceResultDocument = gql`
  query GetMultiraceResult(
    $raceProgramInput: raceProgramInput!
    $multiraceProgramsInput: multiraceProgramsInput!
  ) {
    raceProgram: raceProgramByCondition(input: $raceProgramInput) {
      ...RaceProgramField
    }
    multiracePrograms: multiracePrograms(input: $multiraceProgramsInput) {
      ...MultiraceProgramField
      multiraceRefunds {
        amount
        betStatus
        hitNumbers
        hitCount
      }
      singleRaceRefundsByRaceId {
        raceId
        singleRaceRefunds {
          ...SingleRaceRefundField
        }
      }
      raceProgram {
        races {
          id
          raceNum
          isCanceled
          raceEntryStatus
          entryRacers {
            id
            bicycleNum
            arrivalOrder
            name
            updateNotificationNum
          }
        }
      }
    }
  }
  ${RaceProgramFieldFragmentDoc}
  ${MultiraceProgramFieldFragmentDoc}
  ${SingleRaceRefundFieldFragmentDoc}
`;

/**
 * __useGetMultiraceResultQuery__
 *
 * To run a query within a React component, call `useGetMultiraceResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultiraceResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultiraceResultQuery({
 *   variables: {
 *      raceProgramInput: // value for 'raceProgramInput'
 *      multiraceProgramsInput: // value for 'multiraceProgramsInput'
 *   },
 * });
 */
export function useGetMultiraceResultQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMultiraceResultQuery,
    GetMultiraceResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMultiraceResultQuery,
    GetMultiraceResultQueryVariables
  >(GetMultiraceResultDocument, options);
}
export function useGetMultiraceResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMultiraceResultQuery,
    GetMultiraceResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMultiraceResultQuery,
    GetMultiraceResultQueryVariables
  >(GetMultiraceResultDocument, options);
}
export type GetMultiraceResultQueryHookResult = ReturnType<
  typeof useGetMultiraceResultQuery
>;
export type GetMultiraceResultLazyQueryHookResult = ReturnType<
  typeof useGetMultiraceResultLazyQuery
>;
export type GetMultiraceResultQueryResult = Apollo.QueryResult<
  GetMultiraceResultQuery,
  GetMultiraceResultQueryVariables
>;
export const GetRaceDocument = gql`
  query GetRace($raceInput: raceInput!, $raceProgramInput: raceProgramInput!) {
    raceProgram: raceProgramByCondition(input: $raceProgramInput) {
      ...RaceProgramField
      races {
        ...RaceField
      }
    }
    race: raceByCondition(input: $raceInput) {
      ...RaceField
      singleRaceRefunds {
        ...SingleRaceRefundField
      }
      singleRaceReleaseInfo {
        ...SingleRaceReleaseInfo
      }
      entryRacers {
        ...EntryRacerField
        entryRacerScore {
          ...EntryRacerScoreField
          entryRacerScoreDetails {
            ...EntryRacerScoreDetail
          }
        }
      }
    }
  }
  ${RaceProgramFieldFragmentDoc}
  ${RaceFieldFragmentDoc}
  ${SingleRaceRefundFieldFragmentDoc}
  ${SingleRaceReleaseInfoFragmentDoc}
  ${EntryRacerFieldFragmentDoc}
  ${EntryRacerScoreFieldFragmentDoc}
  ${EntryRacerScoreDetailFragmentDoc}
`;

/**
 * __useGetRaceQuery__
 *
 * To run a query within a React component, call `useGetRaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRaceQuery({
 *   variables: {
 *      raceInput: // value for 'raceInput'
 *      raceProgramInput: // value for 'raceProgramInput'
 *   },
 * });
 */
export function useGetRaceQuery(
  baseOptions: Apollo.QueryHookOptions<GetRaceQuery, GetRaceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRaceQuery, GetRaceQueryVariables>(
    GetRaceDocument,
    options,
  );
}
export function useGetRaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRaceQuery,
    GetRaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRaceQuery, GetRaceQueryVariables>(
    GetRaceDocument,
    options,
  );
}
export type GetRaceQueryHookResult = ReturnType<typeof useGetRaceQuery>;
export type GetRaceLazyQueryHookResult = ReturnType<typeof useGetRaceLazyQuery>;
export type GetRaceQueryResult = Apollo.QueryResult<
  GetRaceQuery,
  GetRaceQueryVariables
>;
export const GetOddsByConditionDocument = gql`
  query GetOddsByCondition($raceInput: raceInput!) {
    odds: oddsByCondition(input: $raceInput) {
      oddsInfo
      updated
    }
  }
`;

/**
 * __useGetOddsByConditionQuery__
 *
 * To run a query within a React component, call `useGetOddsByConditionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOddsByConditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOddsByConditionQuery({
 *   variables: {
 *      raceInput: // value for 'raceInput'
 *   },
 * });
 */
export function useGetOddsByConditionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOddsByConditionQuery,
    GetOddsByConditionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOddsByConditionQuery,
    GetOddsByConditionQueryVariables
  >(GetOddsByConditionDocument, options);
}
export function useGetOddsByConditionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOddsByConditionQuery,
    GetOddsByConditionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOddsByConditionQuery,
    GetOddsByConditionQueryVariables
  >(GetOddsByConditionDocument, options);
}
export type GetOddsByConditionQueryHookResult = ReturnType<
  typeof useGetOddsByConditionQuery
>;
export type GetOddsByConditionLazyQueryHookResult = ReturnType<
  typeof useGetOddsByConditionLazyQuery
>;
export type GetOddsByConditionQueryResult = Apollo.QueryResult<
  GetOddsByConditionQuery,
  GetOddsByConditionQueryVariables
>;
export const GetPayeasyBanksDocument = gql`
  query GetPayeasyBanks($input: bankInput!) {
    banks: payeasyBanks(input: $input) {
      id
      name
      nameRuby
    }
  }
`;

/**
 * __useGetPayeasyBanksQuery__
 *
 * To run a query within a React component, call `useGetPayeasyBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayeasyBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayeasyBanksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPayeasyBanksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayeasyBanksQuery,
    GetPayeasyBanksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPayeasyBanksQuery, GetPayeasyBanksQueryVariables>(
    GetPayeasyBanksDocument,
    options,
  );
}
export function useGetPayeasyBanksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayeasyBanksQuery,
    GetPayeasyBanksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayeasyBanksQuery,
    GetPayeasyBanksQueryVariables
  >(GetPayeasyBanksDocument, options);
}
export type GetPayeasyBanksQueryHookResult = ReturnType<
  typeof useGetPayeasyBanksQuery
>;
export type GetPayeasyBanksLazyQueryHookResult = ReturnType<
  typeof useGetPayeasyBanksLazyQuery
>;
export type GetPayeasyBanksQueryResult = Apollo.QueryResult<
  GetPayeasyBanksQuery,
  GetPayeasyBanksQueryVariables
>;
export const GetGmoBanksDocument = gql`
  query GetGmoBanks {
    banks: gmoBanks {
      id
      name
      nameRuby
      major
    }
  }
`;

/**
 * __useGetGmoBanksQuery__
 *
 * To run a query within a React component, call `useGetGmoBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGmoBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGmoBanksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGmoBanksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGmoBanksQuery,
    GetGmoBanksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGmoBanksQuery, GetGmoBanksQueryVariables>(
    GetGmoBanksDocument,
    options,
  );
}
export function useGetGmoBanksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGmoBanksQuery,
    GetGmoBanksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGmoBanksQuery, GetGmoBanksQueryVariables>(
    GetGmoBanksDocument,
    options,
  );
}
export type GetGmoBanksQueryHookResult = ReturnType<typeof useGetGmoBanksQuery>;
export type GetGmoBanksLazyQueryHookResult = ReturnType<
  typeof useGetGmoBanksLazyQuery
>;
export type GetGmoBanksQueryResult = Apollo.QueryResult<
  GetGmoBanksQuery,
  GetGmoBanksQueryVariables
>;
export const GetBanksDocument = gql`
  query GetBanks($major: Boolean!) {
    banks(major: $major) {
      id
      name
      nameRuby
    }
  }
`;

/**
 * __useGetBanksQuery__
 *
 * To run a query within a React component, call `useGetBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBanksQuery({
 *   variables: {
 *      major: // value for 'major'
 *   },
 * });
 */
export function useGetBanksQuery(
  baseOptions: Apollo.QueryHookOptions<GetBanksQuery, GetBanksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBanksQuery, GetBanksQueryVariables>(
    GetBanksDocument,
    options,
  );
}
export function useGetBanksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBanksQuery,
    GetBanksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBanksQuery, GetBanksQueryVariables>(
    GetBanksDocument,
    options,
  );
}
export type GetBanksQueryHookResult = ReturnType<typeof useGetBanksQuery>;
export type GetBanksLazyQueryHookResult = ReturnType<
  typeof useGetBanksLazyQuery
>;
export type GetBanksQueryResult = Apollo.QueryResult<
  GetBanksQuery,
  GetBanksQueryVariables
>;
export const GetMultiraceProgramDocument = gql`
  query GetMultiraceProgram($multiraceProgramInput: multiraceProgramInput!) {
    multiraceProgram: multiraceProgram(input: $multiraceProgramInput) {
      ...MultiraceProgramField
      raceProgram {
        ...RaceProgramField
        races {
          ...RaceField
          entryRacers {
            ...EntryRacerField
            multiracePercentageOfBets {
              multiraceType
              percentageOfBets
            }
            singleRacePercentageOfBets
            entryRacerScore {
              ...EntryRacerScoreField
              entryRacerScoreDetails {
                ...EntryRacerScoreDetail
              }
            }
          }
        }
      }
    }
  }
  ${MultiraceProgramFieldFragmentDoc}
  ${RaceProgramFieldFragmentDoc}
  ${RaceFieldFragmentDoc}
  ${EntryRacerFieldFragmentDoc}
  ${EntryRacerScoreFieldFragmentDoc}
  ${EntryRacerScoreDetailFragmentDoc}
`;

/**
 * __useGetMultiraceProgramQuery__
 *
 * To run a query within a React component, call `useGetMultiraceProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultiraceProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultiraceProgramQuery({
 *   variables: {
 *      multiraceProgramInput: // value for 'multiraceProgramInput'
 *   },
 * });
 */
export function useGetMultiraceProgramQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMultiraceProgramQuery,
    GetMultiraceProgramQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMultiraceProgramQuery,
    GetMultiraceProgramQueryVariables
  >(GetMultiraceProgramDocument, options);
}
export function useGetMultiraceProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMultiraceProgramQuery,
    GetMultiraceProgramQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMultiraceProgramQuery,
    GetMultiraceProgramQueryVariables
  >(GetMultiraceProgramDocument, options);
}
export type GetMultiraceProgramQueryHookResult = ReturnType<
  typeof useGetMultiraceProgramQuery
>;
export type GetMultiraceProgramLazyQueryHookResult = ReturnType<
  typeof useGetMultiraceProgramLazyQuery
>;
export type GetMultiraceProgramQueryResult = Apollo.QueryResult<
  GetMultiraceProgramQuery,
  GetMultiraceProgramQueryVariables
>;
export const GetCurrentMultiraceProgramsDocument = gql`
  query GetCurrentMultiracePrograms($input: multiraceProgramsInput!) {
    multiracePrograms(input: $input) {
      ...MultiraceProgramField
      raceProgram {
        isCanceled
        isSalesSuspended
        races {
          isCanceled
          raceEntryStatus
        }
      }
    }
  }
  ${MultiraceProgramFieldFragmentDoc}
`;

/**
 * __useGetCurrentMultiraceProgramsQuery__
 *
 * To run a query within a React component, call `useGetCurrentMultiraceProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentMultiraceProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentMultiraceProgramsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCurrentMultiraceProgramsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentMultiraceProgramsQuery,
    GetCurrentMultiraceProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentMultiraceProgramsQuery,
    GetCurrentMultiraceProgramsQueryVariables
  >(GetCurrentMultiraceProgramsDocument, options);
}
export function useGetCurrentMultiraceProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentMultiraceProgramsQuery,
    GetCurrentMultiraceProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentMultiraceProgramsQuery,
    GetCurrentMultiraceProgramsQueryVariables
  >(GetCurrentMultiraceProgramsDocument, options);
}
export type GetCurrentMultiraceProgramsQueryHookResult = ReturnType<
  typeof useGetCurrentMultiraceProgramsQuery
>;
export type GetCurrentMultiraceProgramsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentMultiraceProgramsLazyQuery
>;
export type GetCurrentMultiraceProgramsQueryResult = Apollo.QueryResult<
  GetCurrentMultiraceProgramsQuery,
  GetCurrentMultiraceProgramsQueryVariables
>;
export const GetMultiraceProgramsDocument = gql`
  query GetMultiracePrograms($input: multiraceProgramsInput!) {
    multiracePrograms: multiracePrograms(input: $input) {
      ...MultiraceProgramField
      raceProgram {
        gradeName
        timeFrame
        isGirls
        velodromeCode
        dailyNum
        isLastDay
        heldDate
        isCanceled
        isSalesSuspended
        races {
          isCanceled
          raceEntryStatus
        }
      }
    }
  }
  ${MultiraceProgramFieldFragmentDoc}
`;

/**
 * __useGetMultiraceProgramsQuery__
 *
 * To run a query within a React component, call `useGetMultiraceProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultiraceProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultiraceProgramsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMultiraceProgramsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMultiraceProgramsQuery,
    GetMultiraceProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMultiraceProgramsQuery,
    GetMultiraceProgramsQueryVariables
  >(GetMultiraceProgramsDocument, options);
}
export function useGetMultiraceProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMultiraceProgramsQuery,
    GetMultiraceProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMultiraceProgramsQuery,
    GetMultiraceProgramsQueryVariables
  >(GetMultiraceProgramsDocument, options);
}
export type GetMultiraceProgramsQueryHookResult = ReturnType<
  typeof useGetMultiraceProgramsQuery
>;
export type GetMultiraceProgramsLazyQueryHookResult = ReturnType<
  typeof useGetMultiraceProgramsLazyQuery
>;
export type GetMultiraceProgramsQueryResult = Apollo.QueryResult<
  GetMultiraceProgramsQuery,
  GetMultiraceProgramsQueryVariables
>;
export const GetOrderSingleRacesDocument = gql`
  query GetOrderSingleRaces($input: ordersInput!) {
    orders: orderSingleRaces(input: $input) {
      ...OrderSingleRaceField
      details {
        ...OrderSingleRaceDetailField
      }
      race {
        raceNum
        startTime
        isCanceled
        odds {
          oddsInfo
        }
        singleRaceRefunds {
          ...SingleRaceRefundField
        }
      }
      raceProgram {
        velodromeCode
        heldDate
        isCanceled
      }
    }
  }
  ${OrderSingleRaceFieldFragmentDoc}
  ${OrderSingleRaceDetailFieldFragmentDoc}
  ${SingleRaceRefundFieldFragmentDoc}
`;

/**
 * __useGetOrderSingleRacesQuery__
 *
 * To run a query within a React component, call `useGetOrderSingleRacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderSingleRacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderSingleRacesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrderSingleRacesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderSingleRacesQuery,
    GetOrderSingleRacesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrderSingleRacesQuery,
    GetOrderSingleRacesQueryVariables
  >(GetOrderSingleRacesDocument, options);
}
export function useGetOrderSingleRacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderSingleRacesQuery,
    GetOrderSingleRacesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrderSingleRacesQuery,
    GetOrderSingleRacesQueryVariables
  >(GetOrderSingleRacesDocument, options);
}
export type GetOrderSingleRacesQueryHookResult = ReturnType<
  typeof useGetOrderSingleRacesQuery
>;
export type GetOrderSingleRacesLazyQueryHookResult = ReturnType<
  typeof useGetOrderSingleRacesLazyQuery
>;
export type GetOrderSingleRacesQueryResult = Apollo.QueryResult<
  GetOrderSingleRacesQuery,
  GetOrderSingleRacesQueryVariables
>;
export const GetOrderSingleRaceDocument = gql`
  query GetOrderSingleRace($orderSingleRaceId: String!) {
    order: orderSingleRace(orderSingleRaceId: $orderSingleRaceId) {
      ...OrderSingleRaceField
      details {
        ...OrderSingleRaceDetailField
      }
      race {
        raceNum
        isCanceled
        odds {
          oddsInfo
        }
        entryRacers {
          id
          bicycleNum
          arrivalOrder
          name
        }
        singleRaceRefunds {
          ...SingleRaceRefundField
        }
      }
      raceProgram {
        velodromeCode
        heldDate
        gradeName
        timeFrame
        isGirls
        isCanceled
      }
    }
  }
  ${OrderSingleRaceFieldFragmentDoc}
  ${OrderSingleRaceDetailFieldFragmentDoc}
  ${SingleRaceRefundFieldFragmentDoc}
`;

/**
 * __useGetOrderSingleRaceQuery__
 *
 * To run a query within a React component, call `useGetOrderSingleRaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderSingleRaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderSingleRaceQuery({
 *   variables: {
 *      orderSingleRaceId: // value for 'orderSingleRaceId'
 *   },
 * });
 */
export function useGetOrderSingleRaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderSingleRaceQuery,
    GetOrderSingleRaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrderSingleRaceQuery,
    GetOrderSingleRaceQueryVariables
  >(GetOrderSingleRaceDocument, options);
}
export function useGetOrderSingleRaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderSingleRaceQuery,
    GetOrderSingleRaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrderSingleRaceQuery,
    GetOrderSingleRaceQueryVariables
  >(GetOrderSingleRaceDocument, options);
}
export type GetOrderSingleRaceQueryHookResult = ReturnType<
  typeof useGetOrderSingleRaceQuery
>;
export type GetOrderSingleRaceLazyQueryHookResult = ReturnType<
  typeof useGetOrderSingleRaceLazyQuery
>;
export type GetOrderSingleRaceQueryResult = Apollo.QueryResult<
  GetOrderSingleRaceQuery,
  GetOrderSingleRaceQueryVariables
>;
export const GetOrderMultiracesDocument = gql`
  query GetOrderMultiraces($input: ordersInput!) {
    order: orderMultiraces(input: $input) {
      ...OrderMultiracesResultField
      items {
        ...OrderMultiraceField
        details {
          ...OrderMultiraceDetailField
        }
        multiraceProgram {
          velodromeCode
          heldDate
          programName
          salesDeadline
          multiraceType
          isCanceled
          multiraceRefunds {
            amount
            betStatus
            hitNumbers
            hitCount
          }
        }
      }
    }
  }
  ${OrderMultiracesResultFieldFragmentDoc}
  ${OrderMultiraceFieldFragmentDoc}
  ${OrderMultiraceDetailFieldFragmentDoc}
`;

/**
 * __useGetOrderMultiracesQuery__
 *
 * To run a query within a React component, call `useGetOrderMultiracesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderMultiracesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderMultiracesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrderMultiracesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderMultiracesQuery,
    GetOrderMultiracesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrderMultiracesQuery,
    GetOrderMultiracesQueryVariables
  >(GetOrderMultiracesDocument, options);
}
export function useGetOrderMultiracesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderMultiracesQuery,
    GetOrderMultiracesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrderMultiracesQuery,
    GetOrderMultiracesQueryVariables
  >(GetOrderMultiracesDocument, options);
}
export type GetOrderMultiracesQueryHookResult = ReturnType<
  typeof useGetOrderMultiracesQuery
>;
export type GetOrderMultiracesLazyQueryHookResult = ReturnType<
  typeof useGetOrderMultiracesLazyQuery
>;
export type GetOrderMultiracesQueryResult = Apollo.QueryResult<
  GetOrderMultiracesQuery,
  GetOrderMultiracesQueryVariables
>;
export const GetOrderMultiraceDocument = gql`
  query GetOrderMultirace($orderMultiraceId: String!) {
    order: orderMultirace(orderMultiraceId: $orderMultiraceId) {
      ...OrderMultiraceField
      details {
        ...OrderMultiraceDetailField
      }
      multiraceProgram {
        ...MultiraceProgramField
        multiraceRefunds {
          amount
          betStatus
          hitNumbers
          hitCount
        }
        raceProgram {
          gradeName
          timeFrame
          isGirls
          races {
            id
            raceNum
            isCanceled
            raceEntryStatus
            entryRacers {
              id
              bicycleNum
              arrivalOrder
              name
              updateNotificationNum
            }
          }
        }
        singleRaceRefundsByRaceId: singleRaceRefundsByRaceId {
          raceId
          singleRaceRefunds: singleRaceRefunds {
            ...SingleRaceRefundField
          }
        }
      }
    }
  }
  ${OrderMultiraceFieldFragmentDoc}
  ${OrderMultiraceDetailFieldFragmentDoc}
  ${MultiraceProgramFieldFragmentDoc}
  ${SingleRaceRefundFieldFragmentDoc}
`;

/**
 * __useGetOrderMultiraceQuery__
 *
 * To run a query within a React component, call `useGetOrderMultiraceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderMultiraceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderMultiraceQuery({
 *   variables: {
 *      orderMultiraceId: // value for 'orderMultiraceId'
 *   },
 * });
 */
export function useGetOrderMultiraceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderMultiraceQuery,
    GetOrderMultiraceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrderMultiraceQuery,
    GetOrderMultiraceQueryVariables
  >(GetOrderMultiraceDocument, options);
}
export function useGetOrderMultiraceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderMultiraceQuery,
    GetOrderMultiraceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrderMultiraceQuery,
    GetOrderMultiraceQueryVariables
  >(GetOrderMultiraceDocument, options);
}
export type GetOrderMultiraceQueryHookResult = ReturnType<
  typeof useGetOrderMultiraceQuery
>;
export type GetOrderMultiraceLazyQueryHookResult = ReturnType<
  typeof useGetOrderMultiraceLazyQuery
>;
export type GetOrderMultiraceQueryResult = Apollo.QueryResult<
  GetOrderMultiraceQuery,
  GetOrderMultiraceQueryVariables
>;
export const GetCc3DAuthDocument = gql`
  query GetCc3DAuth($md: String!) {
    cc3DAuth(md: $md) {
      md
      userId
      pfChargeId
      amount
      j
      logId
      paRes
      eci
      result
    }
  }
`;

/**
 * __useGetCc3DAuthQuery__
 *
 * To run a query within a React component, call `useGetCc3DAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCc3DAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCc3DAuthQuery({
 *   variables: {
 *      md: // value for 'md'
 *   },
 * });
 */
export function useGetCc3DAuthQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCc3DAuthQuery,
    GetCc3DAuthQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCc3DAuthQuery, GetCc3DAuthQueryVariables>(
    GetCc3DAuthDocument,
    options,
  );
}
export function useGetCc3DAuthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCc3DAuthQuery,
    GetCc3DAuthQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCc3DAuthQuery, GetCc3DAuthQueryVariables>(
    GetCc3DAuthDocument,
    options,
  );
}
export type GetCc3DAuthQueryHookResult = ReturnType<typeof useGetCc3DAuthQuery>;
export type GetCc3DAuthLazyQueryHookResult = ReturnType<
  typeof useGetCc3DAuthLazyQuery
>;
export type GetCc3DAuthQueryResult = Apollo.QueryResult<
  GetCc3DAuthQuery,
  GetCc3DAuthQueryVariables
>;
export const GetBankBranchesDocument = gql`
  query GetBankBranches($id: ID!) {
    bankBranches(id: $id) {
      id
      bankId
      bankName
      bankNameRuby
      name
      nameRuby
    }
  }
`;

/**
 * __useGetBankBranchesQuery__
 *
 * To run a query within a React component, call `useGetBankBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankBranchesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBankBranchesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBankBranchesQuery,
    GetBankBranchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBankBranchesQuery, GetBankBranchesQueryVariables>(
    GetBankBranchesDocument,
    options,
  );
}
export function useGetBankBranchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBankBranchesQuery,
    GetBankBranchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBankBranchesQuery,
    GetBankBranchesQueryVariables
  >(GetBankBranchesDocument, options);
}
export type GetBankBranchesQueryHookResult = ReturnType<
  typeof useGetBankBranchesQuery
>;
export type GetBankBranchesLazyQueryHookResult = ReturnType<
  typeof useGetBankBranchesLazyQuery
>;
export type GetBankBranchesQueryResult = Apollo.QueryResult<
  GetBankBranchesQuery,
  GetBankBranchesQueryVariables
>;
export const GetEntryRacerScoreDetailOwnsDocument = gql`
  query GetEntryRacerScoreDetailOwns(
    $raceId: String!
    $heldDate: String!
    $detailCnt: Int!
  ) {
    entryRacerScoreDetailOwns(
      raceId: $raceId
      heldDate: $heldDate
      detailCnt: $detailCnt
    ) {
      ...EntryRacerScoreDetailOwnField
    }
  }
  ${EntryRacerScoreDetailOwnFieldFragmentDoc}
`;

/**
 * __useGetEntryRacerScoreDetailOwnsQuery__
 *
 * To run a query within a React component, call `useGetEntryRacerScoreDetailOwnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntryRacerScoreDetailOwnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntryRacerScoreDetailOwnsQuery({
 *   variables: {
 *      raceId: // value for 'raceId'
 *      heldDate: // value for 'heldDate'
 *      detailCnt: // value for 'detailCnt'
 *   },
 * });
 */
export function useGetEntryRacerScoreDetailOwnsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEntryRacerScoreDetailOwnsQuery,
    GetEntryRacerScoreDetailOwnsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEntryRacerScoreDetailOwnsQuery,
    GetEntryRacerScoreDetailOwnsQueryVariables
  >(GetEntryRacerScoreDetailOwnsDocument, options);
}
export function useGetEntryRacerScoreDetailOwnsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEntryRacerScoreDetailOwnsQuery,
    GetEntryRacerScoreDetailOwnsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEntryRacerScoreDetailOwnsQuery,
    GetEntryRacerScoreDetailOwnsQueryVariables
  >(GetEntryRacerScoreDetailOwnsDocument, options);
}
export type GetEntryRacerScoreDetailOwnsQueryHookResult = ReturnType<
  typeof useGetEntryRacerScoreDetailOwnsQuery
>;
export type GetEntryRacerScoreDetailOwnsLazyQueryHookResult = ReturnType<
  typeof useGetEntryRacerScoreDetailOwnsLazyQuery
>;
export type GetEntryRacerScoreDetailOwnsQueryResult = Apollo.QueryResult<
  GetEntryRacerScoreDetailOwnsQuery,
  GetEntryRacerScoreDetailOwnsQueryVariables
>;
export const GetEntryRacerScoreDetailOwnsConditionDocument = gql`
  query GetEntryRacerScoreDetailOwnsCondition(
    $input: GetScoreDetailOwnsInputParam!
  ) {
    entryRacerScoreDetailOwnsCondition(input: $input) {
      ...EntryRacerScoreDetailOwnField
    }
  }
  ${EntryRacerScoreDetailOwnFieldFragmentDoc}
`;

/**
 * __useGetEntryRacerScoreDetailOwnsConditionQuery__
 *
 * To run a query within a React component, call `useGetEntryRacerScoreDetailOwnsConditionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntryRacerScoreDetailOwnsConditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntryRacerScoreDetailOwnsConditionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEntryRacerScoreDetailOwnsConditionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEntryRacerScoreDetailOwnsConditionQuery,
    GetEntryRacerScoreDetailOwnsConditionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEntryRacerScoreDetailOwnsConditionQuery,
    GetEntryRacerScoreDetailOwnsConditionQueryVariables
  >(GetEntryRacerScoreDetailOwnsConditionDocument, options);
}
export function useGetEntryRacerScoreDetailOwnsConditionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEntryRacerScoreDetailOwnsConditionQuery,
    GetEntryRacerScoreDetailOwnsConditionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEntryRacerScoreDetailOwnsConditionQuery,
    GetEntryRacerScoreDetailOwnsConditionQueryVariables
  >(GetEntryRacerScoreDetailOwnsConditionDocument, options);
}
export type GetEntryRacerScoreDetailOwnsConditionQueryHookResult = ReturnType<
  typeof useGetEntryRacerScoreDetailOwnsConditionQuery
>;
export type GetEntryRacerScoreDetailOwnsConditionLazyQueryHookResult =
  ReturnType<typeof useGetEntryRacerScoreDetailOwnsConditionLazyQuery>;
export type GetEntryRacerScoreDetailOwnsConditionQueryResult =
  Apollo.QueryResult<
    GetEntryRacerScoreDetailOwnsConditionQuery,
    GetEntryRacerScoreDetailOwnsConditionQueryVariables
  >;
export const GetEntryRacerNotesDocument = gql`
  query GetEntryRacerNotes($raceId: String!) {
    entryRacerNotes(raceId: $raceId) {
      ...EntryRacerNoteField
      race {
        raceClassName
        raceEventName
      }
    }
  }
  ${EntryRacerNoteFieldFragmentDoc}
`;

/**
 * __useGetEntryRacerNotesQuery__
 *
 * To run a query within a React component, call `useGetEntryRacerNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntryRacerNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntryRacerNotesQuery({
 *   variables: {
 *      raceId: // value for 'raceId'
 *   },
 * });
 */
export function useGetEntryRacerNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEntryRacerNotesQuery,
    GetEntryRacerNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEntryRacerNotesQuery,
    GetEntryRacerNotesQueryVariables
  >(GetEntryRacerNotesDocument, options);
}
export function useGetEntryRacerNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEntryRacerNotesQuery,
    GetEntryRacerNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEntryRacerNotesQuery,
    GetEntryRacerNotesQueryVariables
  >(GetEntryRacerNotesDocument, options);
}
export type GetEntryRacerNotesQueryHookResult = ReturnType<
  typeof useGetEntryRacerNotesQuery
>;
export type GetEntryRacerNotesLazyQueryHookResult = ReturnType<
  typeof useGetEntryRacerNotesLazyQuery
>;
export type GetEntryRacerNotesQueryResult = Apollo.QueryResult<
  GetEntryRacerNotesQuery,
  GetEntryRacerNotesQueryVariables
>;
export const GetEntryRacerNoteCountsDocument = gql`
  query GetEntryRacerNoteCounts($heldDate: Date!) {
    entryRacerNoteCounts(heldDate: $heldDate) {
      ...EntryRacerNoteCountField
    }
  }
  ${EntryRacerNoteCountFieldFragmentDoc}
`;

/**
 * __useGetEntryRacerNoteCountsQuery__
 *
 * To run a query within a React component, call `useGetEntryRacerNoteCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntryRacerNoteCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntryRacerNoteCountsQuery({
 *   variables: {
 *      heldDate: // value for 'heldDate'
 *   },
 * });
 */
export function useGetEntryRacerNoteCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEntryRacerNoteCountsQuery,
    GetEntryRacerNoteCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEntryRacerNoteCountsQuery,
    GetEntryRacerNoteCountsQueryVariables
  >(GetEntryRacerNoteCountsDocument, options);
}
export function useGetEntryRacerNoteCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEntryRacerNoteCountsQuery,
    GetEntryRacerNoteCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEntryRacerNoteCountsQuery,
    GetEntryRacerNoteCountsQueryVariables
  >(GetEntryRacerNoteCountsDocument, options);
}
export type GetEntryRacerNoteCountsQueryHookResult = ReturnType<
  typeof useGetEntryRacerNoteCountsQuery
>;
export type GetEntryRacerNoteCountsLazyQueryHookResult = ReturnType<
  typeof useGetEntryRacerNoteCountsLazyQuery
>;
export type GetEntryRacerNoteCountsQueryResult = Apollo.QueryResult<
  GetEntryRacerNoteCountsQuery,
  GetEntryRacerNoteCountsQueryVariables
>;
export const GetSingleRaceBetResultDocument = gql`
  query GetSingleRaceBetResult($input: betResultsInput!) {
    singleRaceBetResult(input: $input) {
      ...SingleRaceBetResultField
      items {
        ...SingleRaceBetResultItemField
      }
    }
  }
  ${SingleRaceBetResultFieldFragmentDoc}
  ${SingleRaceBetResultItemFieldFragmentDoc}
`;

/**
 * __useGetSingleRaceBetResultQuery__
 *
 * To run a query within a React component, call `useGetSingleRaceBetResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleRaceBetResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleRaceBetResultQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSingleRaceBetResultQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSingleRaceBetResultQuery,
    GetSingleRaceBetResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSingleRaceBetResultQuery,
    GetSingleRaceBetResultQueryVariables
  >(GetSingleRaceBetResultDocument, options);
}
export function useGetSingleRaceBetResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSingleRaceBetResultQuery,
    GetSingleRaceBetResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSingleRaceBetResultQuery,
    GetSingleRaceBetResultQueryVariables
  >(GetSingleRaceBetResultDocument, options);
}
export type GetSingleRaceBetResultQueryHookResult = ReturnType<
  typeof useGetSingleRaceBetResultQuery
>;
export type GetSingleRaceBetResultLazyQueryHookResult = ReturnType<
  typeof useGetSingleRaceBetResultLazyQuery
>;
export type GetSingleRaceBetResultQueryResult = Apollo.QueryResult<
  GetSingleRaceBetResultQuery,
  GetSingleRaceBetResultQueryVariables
>;
export const GetWalletStatementsDocument = gql`
  query GetWalletStatements($input: walletStatementsInput!) {
    walletStatements(input: $input) {
      id
      userId
      type
      target
      amount
      status
      created
    }
  }
`;

/**
 * __useGetWalletStatementsQuery__
 *
 * To run a query within a React component, call `useGetWalletStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletStatementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetWalletStatementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWalletStatementsQuery,
    GetWalletStatementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWalletStatementsQuery,
    GetWalletStatementsQueryVariables
  >(GetWalletStatementsDocument, options);
}
export function useGetWalletStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletStatementsQuery,
    GetWalletStatementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWalletStatementsQuery,
    GetWalletStatementsQueryVariables
  >(GetWalletStatementsDocument, options);
}
export type GetWalletStatementsQueryHookResult = ReturnType<
  typeof useGetWalletStatementsQuery
>;
export type GetWalletStatementsLazyQueryHookResult = ReturnType<
  typeof useGetWalletStatementsLazyQuery
>;
export type GetWalletStatementsQueryResult = Apollo.QueryResult<
  GetWalletStatementsQuery,
  GetWalletStatementsQueryVariables
>;
export const GetWalletStatementProcessingCountDocument = gql`
  query GetWalletStatementProcessingCount {
    processingCount: walletStatementProcessingCount {
      count
    }
  }
`;

/**
 * __useGetWalletStatementProcessingCountQuery__
 *
 * To run a query within a React component, call `useGetWalletStatementProcessingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletStatementProcessingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletStatementProcessingCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWalletStatementProcessingCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWalletStatementProcessingCountQuery,
    GetWalletStatementProcessingCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWalletStatementProcessingCountQuery,
    GetWalletStatementProcessingCountQueryVariables
  >(GetWalletStatementProcessingCountDocument, options);
}
export function useGetWalletStatementProcessingCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletStatementProcessingCountQuery,
    GetWalletStatementProcessingCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWalletStatementProcessingCountQuery,
    GetWalletStatementProcessingCountQueryVariables
  >(GetWalletStatementProcessingCountDocument, options);
}
export type GetWalletStatementProcessingCountQueryHookResult = ReturnType<
  typeof useGetWalletStatementProcessingCountQuery
>;
export type GetWalletStatementProcessingCountLazyQueryHookResult = ReturnType<
  typeof useGetWalletStatementProcessingCountLazyQuery
>;
export type GetWalletStatementProcessingCountQueryResult = Apollo.QueryResult<
  GetWalletStatementProcessingCountQuery,
  GetWalletStatementProcessingCountQueryVariables
>;
export const GetCalendarDocument = gql`
  query GetCalendar(
    $raceProgramsInput: raceProgramsInput!
    $multiraceProgramsInput: multiraceProgramsInput!
  ) {
    racePrograms: racePrograms(input: $raceProgramsInput) {
      ...RaceProgramField
    }
    multiracePrograms: multiracePrograms(input: $multiraceProgramsInput) {
      ...MultiraceProgramField
      raceProgram {
        gradeName
        timeFrame
        isGirls
        velodromeCode
        dailyNum
        isLastDay
        heldDate
      }
    }
  }
  ${RaceProgramFieldFragmentDoc}
  ${MultiraceProgramFieldFragmentDoc}
`;

/**
 * __useGetCalendarQuery__
 *
 * To run a query within a React component, call `useGetCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarQuery({
 *   variables: {
 *      raceProgramsInput: // value for 'raceProgramsInput'
 *      multiraceProgramsInput: // value for 'multiraceProgramsInput'
 *   },
 * });
 */
export function useGetCalendarQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCalendarQuery,
    GetCalendarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCalendarQuery, GetCalendarQueryVariables>(
    GetCalendarDocument,
    options,
  );
}
export function useGetCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarQuery,
    GetCalendarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCalendarQuery, GetCalendarQueryVariables>(
    GetCalendarDocument,
    options,
  );
}
export type GetCalendarQueryHookResult = ReturnType<typeof useGetCalendarQuery>;
export type GetCalendarLazyQueryHookResult = ReturnType<
  typeof useGetCalendarLazyQuery
>;
export type GetCalendarQueryResult = Apollo.QueryResult<
  GetCalendarQuery,
  GetCalendarQueryVariables
>;
export const GetAnnouncementDocument = gql`
  query GetAnnouncement {
    announcement {
      subject
      body
    }
  }
`;

/**
 * __useGetAnnouncementQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnnouncementQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAnnouncementQuery,
    GetAnnouncementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnnouncementQuery, GetAnnouncementQueryVariables>(
    GetAnnouncementDocument,
    options,
  );
}
export function useGetAnnouncementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnnouncementQuery,
    GetAnnouncementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAnnouncementQuery,
    GetAnnouncementQueryVariables
  >(GetAnnouncementDocument, options);
}
export type GetAnnouncementQueryHookResult = ReturnType<
  typeof useGetAnnouncementQuery
>;
export type GetAnnouncementLazyQueryHookResult = ReturnType<
  typeof useGetAnnouncementLazyQuery
>;
export type GetAnnouncementQueryResult = Apollo.QueryResult<
  GetAnnouncementQuery,
  GetAnnouncementQueryVariables
>;
export const GetIsEnteredEntryCampaignDocument = gql`
  query GetIsEnteredEntryCampaign($entryCampaignId: String!) {
    isEnteredEntryCampaign(entryCampaignId: $entryCampaignId)
  }
`;

/**
 * __useGetIsEnteredEntryCampaignQuery__
 *
 * To run a query within a React component, call `useGetIsEnteredEntryCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsEnteredEntryCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsEnteredEntryCampaignQuery({
 *   variables: {
 *      entryCampaignId: // value for 'entryCampaignId'
 *   },
 * });
 */
export function useGetIsEnteredEntryCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIsEnteredEntryCampaignQuery,
    GetIsEnteredEntryCampaignQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIsEnteredEntryCampaignQuery,
    GetIsEnteredEntryCampaignQueryVariables
  >(GetIsEnteredEntryCampaignDocument, options);
}
export function useGetIsEnteredEntryCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIsEnteredEntryCampaignQuery,
    GetIsEnteredEntryCampaignQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIsEnteredEntryCampaignQuery,
    GetIsEnteredEntryCampaignQueryVariables
  >(GetIsEnteredEntryCampaignDocument, options);
}
export type GetIsEnteredEntryCampaignQueryHookResult = ReturnType<
  typeof useGetIsEnteredEntryCampaignQuery
>;
export type GetIsEnteredEntryCampaignLazyQueryHookResult = ReturnType<
  typeof useGetIsEnteredEntryCampaignLazyQuery
>;
export type GetIsEnteredEntryCampaignQueryResult = Apollo.QueryResult<
  GetIsEnteredEntryCampaignQuery,
  GetIsEnteredEntryCampaignQueryVariables
>;
export const GetChargebackCampaignProgressDocument = gql`
  query GetChargebackCampaignProgress {
    chargebackCampaignProgress {
      userId
      votedAmount
      details {
        amount
        grantDate
        isFixed
      }
    }
  }
`;

/**
 * __useGetChargebackCampaignProgressQuery__
 *
 * To run a query within a React component, call `useGetChargebackCampaignProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargebackCampaignProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargebackCampaignProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChargebackCampaignProgressQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChargebackCampaignProgressQuery,
    GetChargebackCampaignProgressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetChargebackCampaignProgressQuery,
    GetChargebackCampaignProgressQueryVariables
  >(GetChargebackCampaignProgressDocument, options);
}
export function useGetChargebackCampaignProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChargebackCampaignProgressQuery,
    GetChargebackCampaignProgressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChargebackCampaignProgressQuery,
    GetChargebackCampaignProgressQueryVariables
  >(GetChargebackCampaignProgressDocument, options);
}
export type GetChargebackCampaignProgressQueryHookResult = ReturnType<
  typeof useGetChargebackCampaignProgressQuery
>;
export type GetChargebackCampaignProgressLazyQueryHookResult = ReturnType<
  typeof useGetChargebackCampaignProgressLazyQuery
>;
export type GetChargebackCampaignProgressQueryResult = Apollo.QueryResult<
  GetChargebackCampaignProgressQuery,
  GetChargebackCampaignProgressQueryVariables
>;
export const GetEnteringCampaignIdsDocument = gql`
  query GetEnteringCampaignIds {
    enteringCampaignIDs
  }
`;

/**
 * __useGetEnteringCampaignIdsQuery__
 *
 * To run a query within a React component, call `useGetEnteringCampaignIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnteringCampaignIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnteringCampaignIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnteringCampaignIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEnteringCampaignIdsQuery,
    GetEnteringCampaignIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnteringCampaignIdsQuery,
    GetEnteringCampaignIdsQueryVariables
  >(GetEnteringCampaignIdsDocument, options);
}
export function useGetEnteringCampaignIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnteringCampaignIdsQuery,
    GetEnteringCampaignIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnteringCampaignIdsQuery,
    GetEnteringCampaignIdsQueryVariables
  >(GetEnteringCampaignIdsDocument, options);
}
export type GetEnteringCampaignIdsQueryHookResult = ReturnType<
  typeof useGetEnteringCampaignIdsQuery
>;
export type GetEnteringCampaignIdsLazyQueryHookResult = ReturnType<
  typeof useGetEnteringCampaignIdsLazyQuery
>;
export type GetEnteringCampaignIdsQueryResult = Apollo.QueryResult<
  GetEnteringCampaignIdsQuery,
  GetEnteringCampaignIdsQueryVariables
>;
export const GetSingleraceBetResultByVelodromeDocument = gql`
  query GetSingleraceBetResultByVelodrome($targetYear: Int!) {
    singleRaceBetResultByVelodrome(targetYear: $targetYear) {
      velodromeCode
      hitRaceNums
      totalAmount
      totalRefundAmount
      retrieveRate
    }
  }
`;

/**
 * __useGetSingleraceBetResultByVelodromeQuery__
 *
 * To run a query within a React component, call `useGetSingleraceBetResultByVelodromeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleraceBetResultByVelodromeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleraceBetResultByVelodromeQuery({
 *   variables: {
 *      targetYear: // value for 'targetYear'
 *   },
 * });
 */
export function useGetSingleraceBetResultByVelodromeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSingleraceBetResultByVelodromeQuery,
    GetSingleraceBetResultByVelodromeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSingleraceBetResultByVelodromeQuery,
    GetSingleraceBetResultByVelodromeQueryVariables
  >(GetSingleraceBetResultByVelodromeDocument, options);
}
export function useGetSingleraceBetResultByVelodromeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSingleraceBetResultByVelodromeQuery,
    GetSingleraceBetResultByVelodromeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSingleraceBetResultByVelodromeQuery,
    GetSingleraceBetResultByVelodromeQueryVariables
  >(GetSingleraceBetResultByVelodromeDocument, options);
}
export type GetSingleraceBetResultByVelodromeQueryHookResult = ReturnType<
  typeof useGetSingleraceBetResultByVelodromeQuery
>;
export type GetSingleraceBetResultByVelodromeLazyQueryHookResult = ReturnType<
  typeof useGetSingleraceBetResultByVelodromeLazyQuery
>;
export type GetSingleraceBetResultByVelodromeQueryResult = Apollo.QueryResult<
  GetSingleraceBetResultByVelodromeQuery,
  GetSingleraceBetResultByVelodromeQueryVariables
>;
export const GetSingleRaceBetResultByBetTypeDocument = gql`
  query GetSingleRaceBetResultByBetType($input: betResultsInputByBetType!) {
    singleRaceBetResultByBetType(input: $input) {
      timeFrame
      totalAmount
      totalRefundAmount
      retrieveRate
    }
  }
`;

/**
 * __useGetSingleRaceBetResultByBetTypeQuery__
 *
 * To run a query within a React component, call `useGetSingleRaceBetResultByBetTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleRaceBetResultByBetTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleRaceBetResultByBetTypeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSingleRaceBetResultByBetTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSingleRaceBetResultByBetTypeQuery,
    GetSingleRaceBetResultByBetTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSingleRaceBetResultByBetTypeQuery,
    GetSingleRaceBetResultByBetTypeQueryVariables
  >(GetSingleRaceBetResultByBetTypeDocument, options);
}
export function useGetSingleRaceBetResultByBetTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSingleRaceBetResultByBetTypeQuery,
    GetSingleRaceBetResultByBetTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSingleRaceBetResultByBetTypeQuery,
    GetSingleRaceBetResultByBetTypeQueryVariables
  >(GetSingleRaceBetResultByBetTypeDocument, options);
}
export type GetSingleRaceBetResultByBetTypeQueryHookResult = ReturnType<
  typeof useGetSingleRaceBetResultByBetTypeQuery
>;
export type GetSingleRaceBetResultByBetTypeLazyQueryHookResult = ReturnType<
  typeof useGetSingleRaceBetResultByBetTypeLazyQuery
>;
export type GetSingleRaceBetResultByBetTypeQueryResult = Apollo.QueryResult<
  GetSingleRaceBetResultByBetTypeQuery,
  GetSingleRaceBetResultByBetTypeQueryVariables
>;
export const GetSingleraceBetResultByVelodromeV2Document = gql`
  query GetSingleraceBetResultByVelodromeV2($input: betResultsInputByBetType!) {
    singleRaceBetResultByVelodromeV2(input: $input) {
      velodromeCode
      hitRaceNums
      buyRaceNums
      totalAmount
      totalRefundAmount
      retrieveRate
    }
  }
`;

/**
 * __useGetSingleraceBetResultByVelodromeV2Query__
 *
 * To run a query within a React component, call `useGetSingleraceBetResultByVelodromeV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleraceBetResultByVelodromeV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleraceBetResultByVelodromeV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSingleraceBetResultByVelodromeV2Query(
  baseOptions: Apollo.QueryHookOptions<
    GetSingleraceBetResultByVelodromeV2Query,
    GetSingleraceBetResultByVelodromeV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSingleraceBetResultByVelodromeV2Query,
    GetSingleraceBetResultByVelodromeV2QueryVariables
  >(GetSingleraceBetResultByVelodromeV2Document, options);
}
export function useGetSingleraceBetResultByVelodromeV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSingleraceBetResultByVelodromeV2Query,
    GetSingleraceBetResultByVelodromeV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSingleraceBetResultByVelodromeV2Query,
    GetSingleraceBetResultByVelodromeV2QueryVariables
  >(GetSingleraceBetResultByVelodromeV2Document, options);
}
export type GetSingleraceBetResultByVelodromeV2QueryHookResult = ReturnType<
  typeof useGetSingleraceBetResultByVelodromeV2Query
>;
export type GetSingleraceBetResultByVelodromeV2LazyQueryHookResult = ReturnType<
  typeof useGetSingleraceBetResultByVelodromeV2LazyQuery
>;
export type GetSingleraceBetResultByVelodromeV2QueryResult = Apollo.QueryResult<
  GetSingleraceBetResultByVelodromeV2Query,
  GetSingleraceBetResultByVelodromeV2QueryVariables
>;
export const GetSingleraceBetResultByGradeDocument = gql`
  query GetSingleraceBetResultByGrade($input: betResultsInputByBetType!) {
    singleRaceBetResultByGrade(input: $input) {
      gradeName
      totalAmount
      totalRefundAmount
      retrieveRate
    }
  }
`;

/**
 * __useGetSingleraceBetResultByGradeQuery__
 *
 * To run a query within a React component, call `useGetSingleraceBetResultByGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleraceBetResultByGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleraceBetResultByGradeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSingleraceBetResultByGradeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSingleraceBetResultByGradeQuery,
    GetSingleraceBetResultByGradeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSingleraceBetResultByGradeQuery,
    GetSingleraceBetResultByGradeQueryVariables
  >(GetSingleraceBetResultByGradeDocument, options);
}
export function useGetSingleraceBetResultByGradeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSingleraceBetResultByGradeQuery,
    GetSingleraceBetResultByGradeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSingleraceBetResultByGradeQuery,
    GetSingleraceBetResultByGradeQueryVariables
  >(GetSingleraceBetResultByGradeDocument, options);
}
export type GetSingleraceBetResultByGradeQueryHookResult = ReturnType<
  typeof useGetSingleraceBetResultByGradeQuery
>;
export type GetSingleraceBetResultByGradeLazyQueryHookResult = ReturnType<
  typeof useGetSingleraceBetResultByGradeLazyQuery
>;
export type GetSingleraceBetResultByGradeQueryResult = Apollo.QueryResult<
  GetSingleraceBetResultByGradeQuery,
  GetSingleraceBetResultByGradeQueryVariables
>;
export const GetOrderDateLargeDividendDocument = gql`
  query GetOrderDateLargeDividend($period: Int!) {
    orderDateLargeDividend(period: $period) {
      refundAmount
      updated
    }
  }
`;

/**
 * __useGetOrderDateLargeDividendQuery__
 *
 * To run a query within a React component, call `useGetOrderDateLargeDividendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderDateLargeDividendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderDateLargeDividendQuery({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetOrderDateLargeDividendQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderDateLargeDividendQuery,
    GetOrderDateLargeDividendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrderDateLargeDividendQuery,
    GetOrderDateLargeDividendQueryVariables
  >(GetOrderDateLargeDividendDocument, options);
}
export function useGetOrderDateLargeDividendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderDateLargeDividendQuery,
    GetOrderDateLargeDividendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrderDateLargeDividendQuery,
    GetOrderDateLargeDividendQueryVariables
  >(GetOrderDateLargeDividendDocument, options);
}
export type GetOrderDateLargeDividendQueryHookResult = ReturnType<
  typeof useGetOrderDateLargeDividendQuery
>;
export type GetOrderDateLargeDividendLazyQueryHookResult = ReturnType<
  typeof useGetOrderDateLargeDividendLazyQuery
>;
export type GetOrderDateLargeDividendQueryResult = Apollo.QueryResult<
  GetOrderDateLargeDividendQuery,
  GetOrderDateLargeDividendQueryVariables
>;
export const GetOrderSingleRacesByRaceIdDocument = gql`
  query GetOrderSingleRacesByRaceId($raceId: String!) {
    orderSingleRaces: orderSingleRacesByRaceId(raceId: $raceId) {
      ...OrderSingleRaceField
      details {
        ...OrderSingleRaceDetailField
      }
      race {
        raceNum
        isCanceled
        odds {
          oddsInfo
        }
        entryRacers {
          id
          bicycleNum
          arrivalOrder
          name
        }
        singleRaceRefunds {
          ...SingleRaceRefundField
        }
      }
      raceProgram {
        velodromeCode
        heldDate
        gradeName
        timeFrame
        isGirls
        isCanceled
      }
    }
  }
  ${OrderSingleRaceFieldFragmentDoc}
  ${OrderSingleRaceDetailFieldFragmentDoc}
  ${SingleRaceRefundFieldFragmentDoc}
`;

/**
 * __useGetOrderSingleRacesByRaceIdQuery__
 *
 * To run a query within a React component, call `useGetOrderSingleRacesByRaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderSingleRacesByRaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderSingleRacesByRaceIdQuery({
 *   variables: {
 *      raceId: // value for 'raceId'
 *   },
 * });
 */
export function useGetOrderSingleRacesByRaceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderSingleRacesByRaceIdQuery,
    GetOrderSingleRacesByRaceIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrderSingleRacesByRaceIdQuery,
    GetOrderSingleRacesByRaceIdQueryVariables
  >(GetOrderSingleRacesByRaceIdDocument, options);
}
export function useGetOrderSingleRacesByRaceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderSingleRacesByRaceIdQuery,
    GetOrderSingleRacesByRaceIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrderSingleRacesByRaceIdQuery,
    GetOrderSingleRacesByRaceIdQueryVariables
  >(GetOrderSingleRacesByRaceIdDocument, options);
}
export type GetOrderSingleRacesByRaceIdQueryHookResult = ReturnType<
  typeof useGetOrderSingleRacesByRaceIdQuery
>;
export type GetOrderSingleRacesByRaceIdLazyQueryHookResult = ReturnType<
  typeof useGetOrderSingleRacesByRaceIdLazyQuery
>;
export type GetOrderSingleRacesByRaceIdQueryResult = Apollo.QueryResult<
  GetOrderSingleRacesByRaceIdQuery,
  GetOrderSingleRacesByRaceIdQueryVariables
>;
export const GetCountryCodeDocument = gql`
  query GetCountryCode {
    countryCode {
      isSearchEngine
      isSns
      isOgp
      code
    }
  }
`;

/**
 * __useGetCountryCodeQuery__
 *
 * To run a query within a React component, call `useGetCountryCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountryCodeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCountryCodeQuery,
    GetCountryCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCountryCodeQuery, GetCountryCodeQueryVariables>(
    GetCountryCodeDocument,
    options,
  );
}
export function useGetCountryCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryCodeQuery,
    GetCountryCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCountryCodeQuery, GetCountryCodeQueryVariables>(
    GetCountryCodeDocument,
    options,
  );
}
export type GetCountryCodeQueryHookResult = ReturnType<
  typeof useGetCountryCodeQuery
>;
export type GetCountryCodeLazyQueryHookResult = ReturnType<
  typeof useGetCountryCodeLazyQuery
>;
export type GetCountryCodeQueryResult = Apollo.QueryResult<
  GetCountryCodeQuery,
  GetCountryCodeQueryVariables
>;
export const GetIssueOneTimePassDocument = gql`
  query GetIssueOneTimePass($mode: OtpAddress!) {
    issueOneTimePass(mode: $mode) {
      otpIssueCode
      issuedTime
      expiryPeriod
    }
  }
`;

/**
 * __useGetIssueOneTimePassQuery__
 *
 * To run a query within a React component, call `useGetIssueOneTimePassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssueOneTimePassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssueOneTimePassQuery({
 *   variables: {
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useGetIssueOneTimePassQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIssueOneTimePassQuery,
    GetIssueOneTimePassQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIssueOneTimePassQuery,
    GetIssueOneTimePassQueryVariables
  >(GetIssueOneTimePassDocument, options);
}
export function useGetIssueOneTimePassLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIssueOneTimePassQuery,
    GetIssueOneTimePassQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIssueOneTimePassQuery,
    GetIssueOneTimePassQueryVariables
  >(GetIssueOneTimePassDocument, options);
}
export type GetIssueOneTimePassQueryHookResult = ReturnType<
  typeof useGetIssueOneTimePassQuery
>;
export type GetIssueOneTimePassLazyQueryHookResult = ReturnType<
  typeof useGetIssueOneTimePassLazyQuery
>;
export type GetIssueOneTimePassQueryResult = Apollo.QueryResult<
  GetIssueOneTimePassQuery,
  GetIssueOneTimePassQueryVariables
>;
export const GetTransferRegistrationDocument = gql`
  query GetTransferRegistration {
    isTransferRegistration {
      hasPersonalHash
      hasSameHashUser
    }
  }
`;

/**
 * __useGetTransferRegistrationQuery__
 *
 * To run a query within a React component, call `useGetTransferRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferRegistrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransferRegistrationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTransferRegistrationQuery,
    GetTransferRegistrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTransferRegistrationQuery,
    GetTransferRegistrationQueryVariables
  >(GetTransferRegistrationDocument, options);
}
export function useGetTransferRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransferRegistrationQuery,
    GetTransferRegistrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTransferRegistrationQuery,
    GetTransferRegistrationQueryVariables
  >(GetTransferRegistrationDocument, options);
}
export type GetTransferRegistrationQueryHookResult = ReturnType<
  typeof useGetTransferRegistrationQuery
>;
export type GetTransferRegistrationLazyQueryHookResult = ReturnType<
  typeof useGetTransferRegistrationLazyQuery
>;
export type GetTransferRegistrationQueryResult = Apollo.QueryResult<
  GetTransferRegistrationQuery,
  GetTransferRegistrationQueryVariables
>;
export const GetTransferStatementProcessingCountDocument = gql`
  query GetTransferStatementProcessingCount {
    processingCount: transferStatementProcessingCount {
      count
    }
  }
`;

/**
 * __useGetTransferStatementProcessingCountQuery__
 *
 * To run a query within a React component, call `useGetTransferStatementProcessingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferStatementProcessingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferStatementProcessingCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransferStatementProcessingCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTransferStatementProcessingCountQuery,
    GetTransferStatementProcessingCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTransferStatementProcessingCountQuery,
    GetTransferStatementProcessingCountQueryVariables
  >(GetTransferStatementProcessingCountDocument, options);
}
export function useGetTransferStatementProcessingCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransferStatementProcessingCountQuery,
    GetTransferStatementProcessingCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTransferStatementProcessingCountQuery,
    GetTransferStatementProcessingCountQueryVariables
  >(GetTransferStatementProcessingCountDocument, options);
}
export type GetTransferStatementProcessingCountQueryHookResult = ReturnType<
  typeof useGetTransferStatementProcessingCountQuery
>;
export type GetTransferStatementProcessingCountLazyQueryHookResult = ReturnType<
  typeof useGetTransferStatementProcessingCountLazyQuery
>;
export type GetTransferStatementProcessingCountQueryResult = Apollo.QueryResult<
  GetTransferStatementProcessingCountQuery,
  GetTransferStatementProcessingCountQueryVariables
>;
